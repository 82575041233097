/* eslint-disable no-unused-vars */
import React,  {useState,useEffect} from 'react'
import {Edit, LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish,AccountBalanceWallet,Storefront} from '@material-ui/icons';
import {ShoppingBagOutlined,SellOutlined,LocalShippingOutlined,Visibility,VisibilityOff,PaymentOutlined,CheckOutlined} from '@mui/icons-material';
import {Grid,Button,Typography} from '@mui/material';
import './user.css'
import {useHistory} from 'react-router-dom';
//import QueryParams from '../../QueryParams';
import {axiosClient,config}from '../../services/axiosClient';
import Avatar from '../../assets/icons/user_96px.png';
import useStyles from './styles';
import AlertDialog from './alertdialog/AlertDialog'
import Verifyalert from '../productManager/widraw/alert/verifyalert/Verifylert'
import {truncateString,checkAttendance} from '../../utils/Utils'
const Account = ({stores,user,setUser,setStore,setOrder,setCart,appcredits,setMessage,setOpenSnackBar,deviceType,authenticateToken,hanldeLogOut,emailverified,handleSendConfirmationCode,handleConfirmedUser,setEmailVerified,showprogress,setShowProgress,visited,setVisited,claimedReward,updateAppcredits}) => {
  console.log(appcredits)
   const classes = useStyles()
  //const query =QueryParams(); 
  const[userObj,setUserObj]=useState(JSON.parse(localStorage.getItem('user')))
  //const [user,setUser]=useState({}) 
  const[username,setUsername]=useState(user.username);
  const[firstname,setFirstname]=useState(user.firstname);
  const[lastname,setLastname]=useState(user.lastname);
  const[walletAddress,setWalletAddress]=useState(user.walletAddress);
  const[walletAddress1,setWalletAddress1]=useState('');
  const[email,setEmail]=useState(user.email);
  const[phone,setPhone]=useState(user.phone);
  const[merchantId]=useState(user.merchantId)
  const[address,setAddress]=useState(user.addresses);
  const[country,setCountry]=useState('');
  const[state,setState]=useState('');
  const[city,setCity]=useState('');
  const[street,setStreet]=useState('');
  const[aprt_suit_num,setApt_suit_num]=useState('');
  const[image,setImage]=useState('')
  const [imagename,setImageName]=useState('');
  const [userImage,setUserImage]=useState('')
  const[onuserUpdated,setonuserUpdated]=useState(false);
  const[onImageChanged,setOnImageChanged]=useState(false)
  const[showEdit,setShowEdit]=useState(false);
  const[isAccessTokenRefreshed,setAccessTokenRefresh]=useState(false)
  const[open,setOpen]=useState(false)
  const [openconfirmation,setOpenconfirmation]=useState(false);
  const[visibility,setVisiblity]=useState(false)
  //
  const history=useHistory();
  const imgonLoadError=(e)=>{
    e.target.onerror = ''; e.target.src = Avatar
}
const dialogRef = React.createRef()

  const handleClickOpen = () => {
      
    setOpen(true)

 };
 const handleClose = (option) => {
      
  setOpen(false);
  setOpenconfirmation(false)
};

const Rewards = ({appcredits,visited})=>{
  return(
   
     <Grid sx={{border:'0px solid',backgroundImage:'linear-gradient(to bottom right, var(--app-primary), snow)',borderRadius:'4px'
    }} container justifyContent='space-between' padding={2} margin={1}>
                     <Grid item xs={5}>
                       <Grid container jsutifyContent='space-between'color='snow' direction='column' alignItems='center'> 
                     
                       <Grid item onClick={()=>{}} >
                       <Typography variant='h5'>{appcredits.recievedOrders}</Typography>
                        </Grid>
                         <Grid item alignContent={'center'} padding={0}>
                           <Typography variant='body2'>Confirm Recieveds</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid>
                     
                   <Grid item xs={3}>
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center' color='snow'> 
                       <Grid item onClick={()=>{}}>
                       <Typography variant='h5'>{appcredits.comments}</Typography>
                         
                         </Grid>
                         <Grid item>
                           <Typography variant='body2'>Comments</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid> 
                     <Grid item xs={4}>
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center' color='snow'> 
                       <Grid item>
                       <Typography variant='h5'>{appcredits.visits}</Typography>
                         </Grid>
                         <Grid item onClick={()=>{}}>
                           <Typography variant='body2'>Daily rewards</Typography>
                         </Grid>
                         <Grid item >
                        {!claimedReward ? <Button className='client-action-Button' type="submit" variant="contained" color="Completed" onClick={()=>{updateAppcredits(2,'visits')}}>Claim</Button>:''}
                         </Grid>
                       </Grid>
                     </Grid>

                  
                  </Grid>
  )
  }

function handleSubmit (e){
   e.preventDefault();
  if (walletAddress===walletAddress1){
   handleUpdateWallet(user._id,walletAddress)
  }
}
function onFileInputChange(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function (e) {
        document.getElementById('avatar').src = e.target.result;
         document.getElementById('user-image').src = e.target.result;
       
        setOnImageChanged(true);
     console.log(file)
     setImage(file);
     //use user to name uer image 
     var filename=username+'.'+file.name.split('.').pop();
     console.log(filename);
     setImageName(filename);
    };

    try {
        reader.readAsDataURL(file)

    } catch (error) {
        console.log({ readAsDataURLError: error })
    }
}

 /* const handleLogOut =()=>{
     localStorage.setItem('user', '');
    localStorage.setItem('loggedin',false); 
    localStorage.clear();
    setCart([])
    setOrder([])
    setStores([])   
    history.push('/');

    //window.location.reload()
    
  }*/
  const handleNavigatePage = (path)=>{
    //navigate to product page
   history.push(path);

}
const navigateNewStorepage=()=>{
  if (user.address){
    history.push('/new-store')
  }else{
    setMessage({body:'Update your profile first and try again ',severity:'success'})
    setOpenSnackBar(true)
    setVisiblity(!visibility)
  }
}
const handleVerifyEmail = (email)=>{
  setOpenconfirmation(true)
   /* console.log(email)
    const url = `${process.env.REACT_APP_SERVER_URL}/email`;

  axiosClient.post(url,{email:email}).then((response)=>{
      console.log(response)
      setMessage({body:'confirmation email is sent to your inbox ',severity:'info'})
      setOpenSnackBar(true)
  }) */
}
const handleUpdateWallet = async (_id,walletAddress)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/walletaddress/update`;

  const config = {
    headers: {
        'Content-Type':'application/json',
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 
    axiosClient.patch(url,{userId:_id,walletAddress:walletAddress},config).then((response)=>{
      if (response.data.status===200){
        setOpen(false)
      }else{
        setWalletAddress('')
      }
    });
 } 
const handleUpdate=(e)=>{
    e.preventDefault();
    editUser().then((response)=>{
        if(response.status===200){
             setUserObj(response.data)
                setUser(response.data.user)
             /* setUsername(response.data.name);
             setEmail(response.data.email);
             setFirstname(response.data.firstname);
             setLastname(response.data.lastname);
             setPhone(response.data.phone);
             setAddress(response.data.address); */
             localStorage.setItem('user',JSON.stringify(response.data));
           
            setMessage({body:'user updated successfully',severity:'success'})
            setVisiblity(!visibility)

            setOpenSnackBar(true)             
            setonuserUpdated(!onuserUpdated);
            if (onImageChanged){
                UploadStoreImage(image).then((response) => {
                    if (response.data.status===200){
               setMessage({body:'user image updated',severity:'success'})
               setOpenSnackBar(true) 
                    
              }
                    //addToast(exampleToast(response.data.message));
                  })
            }
}else{
  setMessage({body:'oops something happens',severity:'success'})
            setOpenSnackBar(true) 
}
       

    });
}

const handleOpenStorePage = ()=>{
  setStore(stores[0]);
  history.push('/my-store')

}
const UploadStoreImage = (file) => {
//const url = process.env.STORE_URL;

const formData = new FormData();
  formData.append('imagename',imagename);
  formData.append('image', file);

  const config = {
    headers: {
        'Content-Type':'application/json',
        'auth-token': user.auth_token,
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 

return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/user/updateImage/${user._id}`, formData, config)
}

const editUser =()=>{
 
const url = `${process.env.REACT_APP_SERVER_URL}/user/${user._id}`;
     let address={
       country:country,
       state:state,
       city:city,
       street:street,
       aprt_suit_num:aprt_suit_num
     }
const body={
         userId:user._id,
         username:username.toLowerCase(),
         email:email,
         firstname:firstname,
         lastname:lastname,
         phone:phone,
         address:address,
         imagename:imagename
}
const config = {
    headers: {
        'auth-token':
          user.auth_token,
      },
}
return axiosClient.patch(url, body,config)

};
    useEffect(()=>{
     setUsername(user.username);
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setWalletAddress(user.walletAddress);
    setWalletAddress1('');
    setEmail(user.email);
    setPhone(user.phone);
    setAddress(user.addresses);
        try{
          if (userObj.user!==null){
            setUser(userObj.user)
          }
            

        }catch(err){
          console.log(err)
        }
      

        if (!isAccessTokenRefreshed){
          authenticateToken(user)
          setVisited(checkAttendance(false))
          console.log(visited)
        }
        try{
            setAddress(user.address);
            setCountry(address.country !== '' ? address.country :'');
            setState(address.state!== '' ? address.state :'');
            setCity(address.city!== '' ? address.city :'');
            setStreet(address.street!== ''? address.street :'');
            setApt_suit_num(address.aprt_suit_num!== ''? address.aprt_suit_num :'');
            setUserImage(`${user.image[0].secure_url}`)
        }catch(err){
            console.log()
        }
        return ()=>{
          setAccessTokenRefresh(true)
        }
    },[address, authenticateToken, isAccessTokenRefreshed, setUser, setVisited, user, userObj.user, visited])

  return (
    <div className={classes.root}>
     
       <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title="Update Wallet Address " textContent={`Please Login to continue  `} positiveButtonText={'Update'}negativeButtonText={'Cancel'} handleSubmit={handleSubmit} walletAddress={walletAddress} setWalletAddress={setWalletAddress} setWalletAddress1={setWalletAddress1} />
       {
      user ?  <Verifyalert open={openconfirmation} user={user} handleClose={handleClose} title="Verification needed to continue "  showprogress={showprogress} setShowProgress={setShowProgress} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified}/> :''
     }
     
     <form  className="userUpdateForm" onSubmit={handleUpdate}>
     <Grid container justifyContent='space-around' spacing={0}> 

<Grid item xs={12} sm={4} md={4} lg={4}>
        <div className="userShow">
            <div className="userShowTop">
               
                <Grid container justifyContent='space-between'>
                    <Grid item>
                      <Grid container justifyContent={'flex-start'} alignItems='center' >
                        <Grid item marginBottom={2}>
                        <img src={userImage}  id="avatar"  onError={imgonLoadError} alt="" className="userShowImg" />
                        </Grid>
                     
                     
                        <Grid item >
                        <span className="userShowUsername">{`@${username}`}</span> 
                        </Grid>

                          
                        </Grid>  
                      {/* <div className="userShowTopTitle">
                   <span className="userShowUsername"></span> 
                    <span className="active">{`@${merchantId}`}</span>
                   <span className="userShowUserTitle"></span>
               </div> */}
                    </Grid>
                    <Grid item>
                   {/* <span className="userShowTitle">Rewards</span>
                     appcredits ?  <Rewards appcredits={appcredits} visited={visited}/>:'' */}
                    </Grid>
                   {/*  <Grid item>
                    {
                      deviceType==='mobile' ?  <Edit onClick={()=>{setShowEdit(!showEdit)}}/>:''
                    }
                    </Grid> */}
                </Grid>

             
            
            </div>
            {
                !showEdit ?     <div className="userShowBottom">
                <span className="userShowTitle">Account Details</span>
                <div className="userShowInfo">
                     <PermIdentity className="userShowIcon"/>
                <span className="userShowInfoTitle">{`${firstname !==undefined ?firstname:''} ${lastname !==undefined ?lastname:''}`}</span>
                </div>
               { merchantId !==undefined ?<div className="userShowInfo">
                     <span>Merchant Id</span>
                <span className="userShowInfoTitle"> <Typography variant='body1' color={'primary'} onClick={()=>{handleNavigatePage(`/product-list`)}}>{merchantId} </Typography></span>
                </div>:''}
              {/*   <div className="userShowInfo">
                     <AccountBalanceWallet className="userShowIcon"/>
                <span className="userShowInfoTitle" onClick={()=>{setOpen(!open)}}>{`  ${walletAddress === ''?'Update Wallet Address':truncateString(walletAddress,28)}`}</span>
                </div> */}
                <div className="userShowInfo">
                     {/* <CalendarToday className="userShowIcon"/>
                <span className="userShowInfoTitle">12.43.1996</span> */}
                </div>
                <span className="userShowTitle">Contact Details</span>
                <div className="userShowInfo">
                     <PhoneAndroid className="userShowIcon"/>
                <span className="userShowInfoTitle">{phone}</span>
                </div>
                <div className="userShowInfo">
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={1}> 
                      <MailOutline className="userShowIcon"/>
                    </Grid>
                    <Grid item xs={11}> 
                    <Grid container  alignItems={'center'}>
                  <Grid item xs={9}>
                  <Typography fontSize={'0.9rem'} color={'GrayText'}>{truncateString(email,28)}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                  {emailverified ?< CheckOutlined style={{marginLeft:10}} color="success"/> :   <> <Button style={{marginLeft:10}}  variant='outlined' size="small" color='primary'  onClick={()=>{handleVerifyEmail(user.email)}} className='client-action-Button'>verify</Button></> }
                  </Grid>
                 </Grid>
                      </Grid>
                  </Grid>
                
                
                 <div className="emailverify">
                 
               
                  </div>  
                </div>
                <div className="userShowInfo">
                 <LocationSearching className="userShowIcon"/>
                <span className="userShowInfoTitle useraddress">{`${country !==undefined ? `${country}`:''} ${state !==undefined ?`,${state}`:''} ${city !==undefined ? `,${city}` :''} ${street !==undefined ?`,${street}`:''} ${aprt_suit_num !==undefined ?`,${aprt_suit_num}`:''}`}</span>
                </div>
                <div className="userShowInfo">
                 <Grid container justifyContent={'center'}>
                    <Grid item >
                     {
                        stores.length > 0 ? ''
                   :<Button variant='outlined' color='secondary' onClick={()=>{navigateNewStorepage()}}>Apply as a Merchant</Button>
                     }
                    </Grid>
                 </Grid>
                </div>
               {
                stores.length >0?<> 
                 {
                   <div className={classes.storedetails}>
                  <span className="userShowTitle">Store Details</span>
 
                <div className="userShowInfo">
                   <Storefront className="userShowIcon"/>
                  <span onClick={()=>{handleOpenStorePage()}} className="userShowInfoTitle">{stores[0].name}</span>
                  </div>
                  <Grid container marginBottom={2} marginTop={1}>
                    <Grid item >
                    <span className="userShowInfoTitle">{stores[0].description}</span> 
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='space-between'>
                     <Grid item >
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center'> 
                     
                       <Grid item onClick={()=>{handleNavigatePage(`/product-list`)}}>
                         <SellOutlined className={classes.storetapIcon}/>
                         
                         </Grid>
                         <Grid item>
                           <Typography variant='body2'>My Products</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid>
                     <Grid item >
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center'> 
                       <Grid item>
                         <ShoppingBagOutlined className={classes.storetapIcon}/>
                         
                         </Grid>
                         <Grid item onClick={()=>{handleNavigatePage(`/transactions`)}}>
                           <Typography variant='body2'>Orders</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid>

                   <Grid item >
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center'> 
                       <Grid item onClick={()=>{handleNavigatePage(`/withdraw`)}}>
                         <PaymentOutlined className={classes.storetapIcon}/>
                         
                         </Grid>
                         <Grid item>
                           <Typography variant='body2'>Withdraw</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid> 
                     <Grid item >
                       <Grid container jsutifyContent='space-between' direction='column' alignItems='center'> 
                       <Grid item /* onClick={()=>{handleNavigatePage(`/new-store`)}} */>
                         <LocalShippingOutlined className={classes.storetapIcon}/>
                         
                         </Grid>
                         <Grid item>
                           <Typography variant='body2'>Shipping</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid>
                  </Grid>
                  <br/><br/> 
                 </div>
                 }
                 </>: ''
               }
                <hr/>
                <Grid container justifyContent={'flex-start'} spacing={2}>
                    <Grid item>
                    <Typography variant='body2' color='primary' onClick={()=>{hanldeLogOut(user._id)}}>Log Out</Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant='body2' color='primary' onClick={()=>{handleNavigatePage(`/privacy`)}}>privacy policy</Typography>
                    </Grid>
                </Grid>
               
               
               </div>:''
            }
        </div>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={4} lg={4}>
         
              <div className={classes.userUpdateWrapper}> 
                   {stores.length > 0 ? '' : <Grid container justifyContent='space-between'>
                    <Grid item>
                       <span className="userUpdateTitle">Info</span>
                       </Grid>
                     <Grid className={classes.editbar} item onClick={()=>{
                      setVisiblity(!visibility)
                    }}>{ visibility ? <Visibility/> : <VisibilityOff/> } </Grid>
                    
                    </Grid>
                   }
                  
                  {
                    visibility ? <div className="userUpdateLeft">
                    {/* <div className="userUpdateItem">
                        <label>UserName</label>
                        <input type="text" required placeholder="mardiaabu33" className="userUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                    </div> */}
                    <div className="userUpdateItem">
                        <label>First Name</label> 
                        <input type="text" required  placeholder="Mardia" className="userUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname!=='null'?firstname:''}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Last Name</label> 
                        <input type="text" required placeholder=" Abubakari" className="userUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname!=='null' ?lastname:''}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Email</label>
                        <input type="email" required placeholder="mardiaabu33@gmail.com" className="userUpdateInput" onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Phone</label>
                        <input type="text" required placeholder="+23358473829" className="userUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Country</label>
                        <input type="text" required placeholder="Ghana" className="userUpdateInput" onChange={(e)=>{setCountry(e.target.value)}} value={country}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>State</label>
                        <input type="text" required placeholder="Northern" className="userUpdateInput" onChange={(e)=>{setState(e.target.value)}} value={state}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>City</label>
                        <input type="text" required placeholder="Tamale" className="userUpdateInput" onChange={(e)=>{setCity(e.target.value)}} value={city}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Street</label>
                        <input type="text" required placeholder="Nsung naa st" className="userUpdateInput" onChange={(e)=>{setStreet(e.target.value)}} value={street}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>Apartment / Suit / House No</label>
                        <input type="text"  placeholder="apertment No,Hno eg E343" className="userUpdateInput" onChange={(e)=>{setApt_suit_num(e.target.value)}} value={aprt_suit_num}/>
                       </div>
                      </div>:''
                  }
                          
                    {
                      visibility ?  <div className="userUpdateUploadWrapper">
                      <div className="userUpdateUpload">
                          <img src={userImage} onError={imgonLoadError} alt=""  id="user-image"className="userUpdateImg" />
                          <label htmlFor="file"> <Publish className="userUpdateIcon"/> </label>
                              <input type="file" onChange={onFileInputChange} id="file" style={{display:"none"}}/>
                     </div>
                     <Button type='submit' variant='outlined' color='primary'>Update</Button>
                  </div> :''
                    }
                        </div>   {/* {
                    showEdit ?:''
                } */}
        </Grid>
       <Grid item={true} xs={12} sm={4} md={4} lg={2}>
          
          </Grid>
      </Grid>
      
      </form>
              
    </div>
    
  )
}

export default Account

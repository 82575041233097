import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    padding:0,
    margin:0,
  },
  media: {
    margin:'5px 5px',
    height: 100,
    border:'0px solid',
    borderRadius:'4px',
    width:'100%',
    padding:0,
  },
  productname:{
   fontWeight:'bold'
  },
  cardContent: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    border:'0px solid',
    width:'100%',
    padding:0.5,
    margin:0,
  },
  cardActions: {
    border:'0px solid',
    height:'auto',
    display:'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
  },
 
  buttons: {
    width:'100%',
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
  },
  quantityUpdateWrapper:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
  },
  selectCheckBox:{
      color:"#6d1b7b",
        '&.Mui-checked': {
           color: "#6d1b7b",
        },
      
  },
  price:{
     color:'red',
     fontWeight:600,
     [theme.breakpoints.down(600)]: {
      fontWeight:500,
      fontSize: '18px',
       margin: '5px',

    },
  },
  quantity:{
    width:'3vw',textAlign:'center',
    border:' 1px #afb4b0',

    [theme.breakpoints.down(600)]: {
      width:'10vw',textAlign:'center',
      border:' 1px #afb4b0'

    },
   
  },
  cost:{
      height:'auto',    
      color:'#afb4b0',
      border:'1px solid',
     
  },
  costTypo:{
    fontSize:'14px',
    color:'darkgrey',
    [theme.breakpoints.down(600)]: {
      fontSize:'13px'

   },
  },
  specifications:{
   height:'auto',
   display:'flex',
   flexDirection:'column',
   border:'0px solid',
   width:'100%',
   marginBottom:'5px'
  },
  specsListWrapper:{
    display:'flex',
    flexDirection:'column',
    padding:'0px 14px',
  },
  gridSpecsList:{
    border:'0px solid #afb4b0',
    width:'100%',
    margin:1,
  },
  specsGrid:{
    border:'0px solid #afb4b0',
    width:'100%',
    padding:'5px 0px',
    display:'flex',
    justifyContent:'space-around',
  },
  gridSpecsItem:{
    cursor:'pointer',
    fontSize:12
  },
  disSelect:{
    padding:'2px 4px',
    border:'1px solid #afb4b0',
    borderRadius:'30px',
    fontSize:'10px'
  }
 ,select:{
    padding:'2px 4px',
    border:'1px solid var(--app-primary)',
    borderRadius:'30px',
    backgroundColor:'var(--app-primary)',
    color:'white',
    fontSize:'10px'

  }
  ,measurementCard:{
    height:'auto',
  },
  measurementFormWrapper:{
    border:'0px solid  #afb4b0',
    padding:'5px',
    marginBottom:'5px'

  },
  measurementForm:{
    border:'1px solid',
    borderRadius:'3px',
    padding:'10px 10px',
  },
  measurementInputWrapper:{
    display:'flex',
    flexDirection:'column'
  },
  measurementInput:{
    width:'100%',height:'25%' ,textAlign:'center'
  }
 ,
  measuregridContainer:{
    border:'1px solid  #afb4b0',
    borderRadius:'4px',
    padding:'10px 10px',
    margin:'0px 0px 5px 0px'
    
  }
}));

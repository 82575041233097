import React from 'react';
import {Grid,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material'
function AlertDialog({open,handleClickOpen,handleClose,title,textContent,DeleteOutline}) {

  return (
    <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
           <DialogContentText id="alert-dialog-description">
            {textContent}
          </DialogContentText>
            </Grid>
            <Grid item>
            <DeleteOutline/> 
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{handleClose(true)}} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog

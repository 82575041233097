import {useEffect,useState} from 'react';
import {useHistory} from 'react-router-dom';
import { Chart } from "../../components/charts/Chart";
import { FeaturedInfo } from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import {Grid,Button,Typography} from '@mui/material'
import Widgetsm from "../../components/widgetSm/widgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
function Home({user,stores,storeindex,transactions,handlegetStores,isStoresLoaded,setIsStoreLoaded,isProductsLoaded,setIsProductsLoaded,handlegetProducts , completedAggregate, inCompletedAggregate, alltimeAggregate, monthlySales,productxs}) {
const  history = useHistory()
const [storeStatus,setStoreStatus]=useState('');
const handleNavigate =(location)=>{
    history.push(location)
} 
  useEffect(() => {
    if (user===undefined){
      //loadUser()
    }
    try{
      stores.length > 0 ?setStoreStatus(stores[storeindex].status):setStoreStatus('none')
    }catch(err){
      console.log(err)
    }
     if(user){
        if (!isStoresLoaded){
         handlegetStores(user); 
        //  handlegetProducts();
        }

        return ()=>{
          setIsStoreLoaded(true)
        }
    }
      
    },[handlegetProducts, handlegetStores, isStoresLoaded, setIsProductsLoaded, setIsStoreLoaded, storeindex, stores, user]);
    return (
        <div className="home">
         
        {stores.length > 0 ? <>
        {storeStatus!=='Verified' ?<Typography>Your store is not verifed ,please contact support for assistance</Typography>:
        <div className="widgetwrapper">
        {alltimeAggregate.length >0 ? <FeaturedInfo completedAggregate={completedAggregate} inCompletedAggregate={inCompletedAggregate} alltimeAggregate={alltimeAggregate} monthlySales={monthlySales}/>:''}
        {alltimeAggregate.length > 0 ?  <Chart data={monthlySales} title="Sales Analytics" grid datakey={"Monthly Sales"}/>:''}
  <div className="homeWidgets"> 
  <Grid container>
         <Grid item xs={12} sm={12} md={4} lg={4}>
         {productxs.length > 0 ? <Widgetsm products={productxs}/>:''}
         </Grid> 
         <Grid item xs={12} sm={12} md={8} lg={8}>
           { transactions.length > 0 ? <WidgetLg transactions={transactions} />:''}
         </Grid>
   </Grid>
  
  </div>
 </div>
        }
        </> : <Grid container marginLeft={'30%'} marginTop={'20%'} >
                 <Grid item  alignItems='center' justifyContent={'center'}>
                   <Grid container direction='column' rowSpacing={4} justifyContent={'space-around'} alignItems='center'>
                   <Grid item > <Typography variant="body2">You don't have store in  your account create and start selling</Typography> </Grid>
                   <Grid item ><Button variant='outlined' size='small' onClick={()=>{handleNavigate('/dashboard/newStore')}}>Create Store</Button>
                 </Grid>
                  
                   </Grid>
                 </Grid> 
                 <Grid item xs={12} sm={12} md={8} lg={8}>
                 
                 </Grid>
           </Grid> }
        </div> 
    )
}

export default  Home

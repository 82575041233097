import React,{useState,useEffect} from 'react'
import './index.css';
import axios from 'axios';

const Category = () => {
 
    const [groupCategories,setGroupCategories]=useState([])
    const [group,setGroup] =useState('');
    const [category,setCategory] =useState('');
    const [categories,setCategories] =useState([]);
    const [filteredCategories,setFilteredCategories] =useState([]);
    const [isCategoriesLoaded,setIsCategoriesLoaded]=useState(false);
    const [isGroupLoaded,setIsGroupLoaded]=useState(false);
    const [selectedgroup,setSelectedGroup]=useState({})
    
    const ongroupInputChange =(e)=>{
        console.log(e.target.value)
        setGroup(e.target.value)
    }
    const handleSubmit=(e)=>{
         e.preventDefault();
         console.log(group)
     if (group!==undefined) { handleAddGroup(group)}
    }

    const oncategoryInputChange =(e)=>{
        console.log(e.target.value)
        setCategory(e.target.value)
    }
    const handleCategorySubmit=(e)=>{
         e.preventDefault();
         console.log(group)
     if (category!==undefined) { handleAddCategory(selectedgroup)}
    }

    const handleonGroupClick =(group)=>{
        console.log(group._id)
        setSelectedGroup(group);
        const filtered=categories.filter((item) =>item.categoryGroupId === group._id)
        
        setFilteredCategories(filtered)
    }
    const removeCategory = (_id) =>{
        const url =`${process.env.REACT_APP_SERVER_URL}/category/${_id}`;
        axios.delete(url).then((response)=>{
             setFilteredCategories(filteredCategories.filter((item)=>item._id !== _id))
        })
 }
    const removeGroupCategory = (_id) =>{
           const url =`${process.env.REACT_APP_SERVER_URL}/category/group/${_id}`;
           axios.delete(url).then((response)=>{
                setGroupCategories(groupCategories.filter((item)=>item._id !== _id))
           })
    }
    const handleAddGroup =(group)=>{
        const url=`${process.env.REACT_APP_SERVER_URL}/category/group`

         axios.post(url,{name:group}).then((response)=>{
            console.log(response.data)
            setGroupCategories(response.data);
            setGroup('');
        })  
    }
     const addCategory =(group)=>{
        const url=`${process.env.REACT_APP_SERVER_URL}/category`
       return  axios.post(url,{categoryGroupId:group._id,name:category});
     }
    const handleAddCategory =async (group)=>{
        var savedcat={}
      
     const savedCat = await addCategory(group).then((response)=>{
            setCategory('')
            console.log(response.data)
            savedcat=response.data
            console.log(savedcat)
            categories.push(savedcat);
            
            setFilteredCategories(categories.filter((item) =>item.categoryGroupId === group._id))
    
        })  
        if (savedCat){
            if (savedcat!==null){

               
            }
        }
    }
    useEffect(()=>{
        const handlegetGroup =()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
    
             axios.get(url).then((response)=>{
                //console.log(response.data)
                setGroupCategories(response.data);
            })  
        }
        const handlegetCategories =()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/`
    
             axios.get(url).then((response)=>{
               // console.log(response.data)
                setCategories(response.data);
            })  
        }
        if (!isGroupLoaded){
            handlegetGroup();
        }
        if(!isCategoriesLoaded){
            handlegetCategories()
        }
        return ()=>{
            setIsGroupLoaded(true);
            setIsCategoriesLoaded(true);
        }
    })

    
  return (
    <div className='contactContainer'>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{flex:'1'}}>
                        <form action="" className="cat-group-form" onSubmit={handleSubmit}> <label>Groups</label>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <div className="category-item" style={{flex:'4'}}>
               
            <input type="text" className='category-input' placeholder="" value={group} required onChange={(e)=>{ongroupInputChange(e)}}/>
            </div>
           
          <div className="category-item" style={{flex:'1'}}>
           <button type='submit' className="addgroup" onClick={()=>{}}>Add</button>
           </div> 
            </div>
            <div className='group-list'>
                <ul >
                  {groupCategories.map((item,index)=>{
                  return  (
                     <li   key={`group-li${index}`} > <span onClick={()=>{handleonGroupClick(item)}} className='li-title'>{item.name}</span> <span className='remove' onClick={()=>{removeGroupCategory(item._id)}}>x</span></li>
                  )})
                    } 
                </ul>
            </div>

         </form>
                        </div>
                        <div style={{flex:'1'}}>
                        <form action="" className="addGroup" onSubmit={handleCategorySubmit}>             
           <label>{`${selectedgroup.name !== undefined ? selectedgroup.name:''} Categories`}</label>

            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center' }}>
            <div className="category-item" style={{flex:'4'}}>
                <input type="text" placeholder="" className='category-input' value={category}  required onChange={(e)=>{oncategoryInputChange(e)}}/>
            </div>
           
          <div className="category-item" style={{flex:'1'}}>
           <button type='submit' className="addgroup" onClick={()=>{}}>Add</button>
           </div> 
            </div>
            <div className='group-list'>
                <ul >
                  {filteredCategories.map((item,index)=>{
                  return  (
                    <li   key={`group-li${index}`} > <span onClick={()=>{}} className='li-title'>{item.name}</span> <span className='remove' onClick={()=>{removeCategory(item._id)}}>x</span></li>
                  )})
                    } 
                </ul>
            </div>

         </form>
                        </div>
                      </div>
        
      
    </div>
  )
}

export default Category


const formatWithCurrencySymbol =(amount,currency)=>{
  // Create GH Cedi currency symbol.
var formatter = new Intl.NumberFormat('en-GH', {
    style: 'currency', 
    currency: currency, //   currency: 'GHS',
  });
  return formatter.format(amount)
}

const formarttoCurrency = (amount ,symbol)=>{

  const getDigitsAfterPeriod =(amount)=>{

    var stringvalue=amount.toString().split('');//convert to Array
    var periodIndex=stringvalue.indexOf('.');//get period index number
    var stringval=amount.toString();
     
      if (periodIndex >=1){ //check for NaN values if non exist continue
        if (periodIndex!==-1){ //if period exist in value start extraction from its index
   
         var extract=stringval.slice(stringval.length - (stringval.length-2)) //extract values after period
         // console.log(extract)
          return extract.toString().split('');
      
        }
      }else{
        //console.log(amount)
         return ['0'];
      }

    
  }
  console.log();

   function isDigitContainNonZeroz(arr){
    
    for(var i=0;i<arr.length;i++){
      var bool=false
      if (arr[i]!==0){

        bool= true
      }
    }     
     return bool

   }
if (amount!==undefined){
try {
  
  //if (amount>=1){
    var array_degitsAfterPeriod=getDigitsAfterPeriod(amount);
    var bool=isDigitContainNonZeroz(array_degitsAfterPeriod);//checlk if any digit after period is > 0 
        if (bool===true){
           //  console.log("d length : "+array_degitsAfterPeriod.length);
 
         return symbol + amount.toFixed(8).replace(/\d(?=(\d{3})+\.)/g,"$&,");
        }else if(bool===false){
        // console.log(amount)
         return symbol + amount.toFixed(2);
        }
   /*     
   }else{
     console.log(amount)
 
    return  symbol +amount.toFixed(8).replace(/\d(?=(\d{3})+\.)/g,"$&,");
    } */
} catch (error) {
  console.log(error)
  
}
}
  }

  const convertValueFromExponent = (value)=>{
    if (value >=1){
      return value
    }else{
      return value.toFixed(8)
    }
  }
  
  const formarttoPiCurrency = (amount)=>{
   try {
    var value=0;
    var symbol=`t-π `
  if (amount!==undefined){
    if (amount>=1){
  
      value = symbol +amount.toFixed(3).replace(/\d(?=(\d{3})+\.)/g,"$&,");
    }else{
      value =symbol +amount.toFixed(8).replace(/\d(?=(\d{3})+\.)/g,"$&,");
     }
  }
    return value
    
   } catch (error) {
    console.log(error)
   }
    }
    
    
const truncateString=(str, num) => {
  try{
    if (str!==''){
      if(str.length>num){
        return str.slice(0,num)+"...";
      }else{
        return str;
      }
    }
  }catch(err ){
     console.log(err)
  }
 
}
const preference =(visited,claimed,showAds)=>{
  const date=new Date().setHours(24);
  console.log("new date "+date+"visited "+visited)
 var setobj={
    claimed:claimed,
    visited:visited,
    date:new Date(date),
    showAds:showAds
 }
 localStorage.setItem('pref',JSON.stringify(setobj));
return setobj
}
const checkAttendance =(showAds)=>{

  try{
    const today = new Date().getDate();
 const  jsondata= JSON.parse(localStorage.getItem('pref'))
  if(today  === new Date(jsondata.date).getDate() && jsondata.visited === 'true' && jsondata.claimed==="false"){ 
    console.log("updated = " + jsondata.visited +' its a new day user not claimed points')

     return preference("true","false",showAds)
 
        } else if(today!==new Date(jsondata.date).getDate()){
       
      if (jsondata.visited === 'true'){
         

         console.log("visited = " + jsondata.visited + ' already visited today ,stay with us ')
       return  preference("false","false",showAds)

         
      }}/*else{
          console.log(jsondata)
          console.log("visited = " + jsondata.visited + ' welcome  visited today , stay with us')
          preference("false",showAds)

      } 
      
      return jsondata.visited
  }
  else if(today===new Date(jsondata.date).getDate() && jsondata.visited && jsondata.claimed==="true"){   
      console.log('its a new today yeeey !! ,you are welcome')
      preference("true","false",showAds)

        return  jsondata.visited
  }else if (today===new Date(jsondata.date).getDate() && jsondata.visited==='false'){//its a new day and update occurs,load it ...

      console.log('its a new day and you already visited  ')
      preference("false","false",showAds)

     return  jsondata.visited

  } */
  }catch(error){
    preference("true",false)

    console.log(error)
  }
}
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};
const randNumber= (count)=> {//Unique Identifier
  var result           = '';
 // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var characters       = '0123456789';

  var charactersLength = characters.length;
  for ( var i = 0; i < count; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
 // console.log(result);
  return result;
}

//Fiat Currency Crypto conversion
const convertFiatCurrencyToCrypto = ()=>{
  var localFiatCurrency=1;
  var foreignFiatCurrency=localFiatCurrency/7;
  console.log(foreignFiatCurrency)
  var crypto=2199113;

 return  foreignFiatCurrency/crypto

}
const converCryptoFiatCurrency= ()=>{
  var localFiatCurrency=0;
  //var foreignFiatCurrency=0;
  var crypto=0.00000000;

 return  crypto/localFiatCurrency

}
 class  DateDiff {
    constructor(prevDate,currentDate){
     this.prevDate    = new Date(prevDate)
     this.currentDate = new Date(currentDate)
    }
    
    
/*const prevDate = new Date('7/13/2010');
const currentDate = new Date('12/15/2010');
 console.log(getDifferenceInDays(prevDate, currentDate));
console.log(getDifferenceInHours(prevDate, currentDate));
console.log(getDifferenceInMinutes(prevDate, currentDate));
console.log(getDifferenceInSeconds(prevDate, currentDate)); */

   getDifferenceInDays() {
  const diffInMs = Math.abs(this.currentDate - this.prevDate);
  return diffInMs / (1000 * 60 * 60 * 24)  /**/;
}

 getDifferenceInHours() {
  const diffInMs = Math.abs(this.currentDate - this.prevDate);
  return diffInMs / (1000 * 60 * 60);
}

  getDifferenceInMinutes() {
  const diffInMs = Math.abs(this.currentDate - this.prevDate);
  return diffInMs / (1000 * 60);
}

 getDifferenceInSeconds() {
  const diffInMs = Math.abs(this.currentDate - this.prevDate);
  return diffInMs / 1000;
}
 }
 const imgonLoadError=(e)=>{
  e.target.onerror = null; e.target.src = 'Avatar'
}

const replaceObject =(items,obj)=>{/* 
   if (index !== -1) {
    items[index] = obj;
   } */
   items.forEach((item, i) => { if (item._id === obj._id) {
    items[i] = obj;
    console.log(items[i])

  } });

  return items
}
/* function toDate(date) {
    if (date === void 0) {
      return new Date(0);
    }
    if (isDate(date)) {
      return date;
    } else {
      return new Date(parseFloat(date.toString()));
    }
  }
   //TEST
  //var ms = '1519073776000';
  //var dateFormat = "Y-m-d H:i:s.v";
 // var formatted = FormatDate(ms, dateFormat);

  function isDate(date) {
    return (date instanceof Date);
  }
  
   export  function FormatDate(date, format) {
    var d = toDate(date);
    return format
      .replace(/Y/gm, d.getFullYear().toString())
      .replace(/m/gm, ('0' + (d.getMonth() + 1)).substr(-2))
      .replace(/d/gm, ('0' + (d.getDate() + 1)).substr(-2))
      .replace(/H/gm, ('0' + (d.getHours() + 0)).substr(-2))
      .replace(/i/gm, ('0' + (d.getMinutes() + 0)).substr(-2))
      .replace(/s/gm, ('0' + (d.getSeconds() + 0)).substr(-2))
      .replace(/v/gm, ('0000' + (d.getMilliseconds() % 1000)).substr(-3));
  } */
 
  
  export {formatWithCurrencySymbol,convertFiatCurrencyToCrypto,converCryptoFiatCurrency,formarttoPiCurrency,formarttoCurrency,convertValueFromExponent,truncateString,randNumber,getDeviceType,DateDiff,imgonLoadError,preference,checkAttendance,replaceObject}
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  topbar : {
    width: '100%',
    backgroundImage:'linear-gradient(to bottom left, var(--app-primary), snow)',
    border:'0px solid var(--app-primary)',
    [theme.breakpoints.down('xs')]:{
      height: '60px',
      backgroundColor: 'snow',
      backgroundImage:'none',
 }, position:' sticky',
    zIndex: 999,
  
}, 
logo:{
  width:'90%',
  height:60,
  [theme.breakpoints.down('xs')]:{
    height:60,
    width:'100%'
  }
},logoWraper:{
    width: '100%',
    height: '100%'
  },
  IconBadgeContainer:{
    [theme.breakpoints.down('xs')]:{
         display:'none'
         
    }
  },
  title:{
    [theme.breakpoints.down('xs')]:{
      display:'none'
      
 }
  },
  
 
  searchfield:{
    border:' 1px solid seashell',
    width:'100%',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.8rem',
      height: '28px',
      border:' 1px solid var(--app-primary)',
      width: '100%',
      borderRadius:'30px'
      
 }
  },
  searchInput:{
      padding:' 1px 20px',
      outline: 'none',
     width: '100%',
      fontSize: '18px',
      border:' 1px solid #fff',
      [theme.breakpoints.down('xs')]:{
        fontSize: '11px',
        height: '20px',
        border: '0px solid var(--app-primary)',
       width:' 100%',
       borderRadius: '30px',
       background:'transparent'
   }
      
    },
    menuIcon:{
    [theme.breakpoints.down('xs')]:{
      color: 'var(--app-primary)',
      transform:'scale(1.5)',
      marginLeft:'5px'
     
    },
    searchIcon:{
      paddingRight:'2px',
      paddingTop:'2px',
      border:'1px solid',
      color:'#fff',
      transform:'scale(2)',
      [theme.breakpoints.down('xs')]:{
        color: "#000"
      }
    }
  },
    userIcon:{
      [theme.breakpoints.down('xs')]:{
        color: 'var(--app-primary)',
        transform:'scale(1.3)',
        display:'none'
      }
  }
}))
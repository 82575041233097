/* eslint-disable no-unused-vars */
import React ,{useEffect,useState}from 'react';
import "./topbar.css"
import {NotificationsNone,ShoppingCartOutlined,AccountCircleOutlined,EmailOutlined,Call,HomeOutlined,MenuOutlined} from '@material-ui/icons';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Menu from '@mui/material/Menu';
import {MenuItem,Typography,Grid} from '@mui/material';
import {Link,useHistory} from 'react-router-dom';
import SearchField from './searchfield/SearchField';
import useStyles from './styles';
import logo from '../../assets/logo/Daabia.png'
const ref= React.forwardRef()

 const Topbar = ({totalItems,totalOrders,setIsUserSearching,handlesearchProduct,hanldeonSearchInputChange,handleUserClick,showCategory,setShowCategory,signOut}) => {
   const[loggedin]=useState(false);
   //const [user] = useState(JSON.parse(localStorage.getItem('user')));
   // const [loggedin] = useState(JSON.parse(localStorage.getItem('loggedin')));
    const classes =useStyles();
    const history=useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //eslint-disable-next-line no-unused-vars
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handlenavigate=(location)=>{
      console.log(location)
       history.push(location)
    }
    useEffect(()=>{
      var user =localStorage.getItem('user');
       //console.log("user "+user)
       if (user===null){
        // history.push('/dashboard/login'); 
       }
    })
    const handleOnlogoClick =()=>{
      setIsUserSearching(false)

      history.push('/')
    }
    return (
        <div className={classes.topbar}>
           <Grid container  justifyContent={'space-between'} alignItems='center' padding={1}>
            <Grid item xs={2} lg={2}>
              <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item xs={12} sm={2} md={2} lg={6} className={classes.logoWraper}>
                 
                 <Grid container justifyContent='flex-end'>
                  <Grid item xs={12} lg={12} >
                 
                     <img className={classes.logo} src={logo} alt='logo'onClick={()=>{handleOnlogoClick()}}/>
                    
                  </Grid>
                 </Grid>
                    
                 
                  </Grid>
                 { !logo ? <Grid item className={classes.title}  sx={{display:'none'}} sm={2} md={2} lg={6}>
                 <Grid container justifyContent='flex-start'>
                  <Grid item>

                  <Link to ="/" className="link">
                   <span className="siteName">{process.env.REACT_APP_WEBSITE_NAME}</span>
                   </Link>    
                  </Grid>
                  </Grid>            
                 
                  </Grid>:''}
                 
              </Grid>
            </Grid>
            <Grid item xs={9}  sm={4} md={5} lg={5} justifyContent='center' >
                  {<SearchField handlesearchProduct={handlesearchProduct} hanldeonSearchInputChange={hanldeonSearchInputChange}/>}
                
                   </Grid>
                   <Grid item className={classes.IconBadgeContainer} xs={{display:'none'}} lg={2}>
                   <div className="topRight">
                   {/*  <div className="topbarIonContainer">
                        <NotificationsNone/>
                        <span className="topIconBadge">2</span>
                    </div> */}


                    <div className="topbarIonContainer" onClick={()=>{}}>
                      <Link to ="/orders" className="link">
                      
                       <ShoppingBagOutlinedIcon className="link"/>
                      </Link>
                        <span className="topIconBadge">{totalOrders}</span>
                    </div>
                    <div className="topbarIonContainer"onClick={()=>{}}>
                  <Link to ="/cart" className="link">

                     <ShoppingCartOutlined className="link"/>
                        <span className="topIconBadge" >{totalItems}</span>
                       </Link>
                    </div> 
                   
                   
                </div>
                      </Grid>
                      <Grid item xs={1} alignItems='center'>
                        <Grid container justifyContent='space-around' alignItems='center'>
                          <Grid item >
                          <MenuOutlined  className={classes.menuIcon} onClick={()=>{setShowCategory(!showCategory)}}/>
                          </Grid>

                          <Grid item className={classes.userIcon} >
                            <>
                            { loggedin ?  <img src="https://images.pexels.com/photos/4620866/pexels-photo-4620866.jpeg?cs=srgb&dl=pexels-cottonbro-4620866.jpg&fm=jpg" alt="" className="topAvatar" />: 
                        <AccountCircleOutlined className={classes.userIcon} onClick={()=>{handleUserClick()}}/>}
                            </>
                          </Grid>
                        </Grid>
                     
                      </Grid>
           </Grid>
          {/*   <div className="topbarActions">
                <div className="topLeft">
                 
                </div>
               
                
             
                    <div style={{cursor:'pointer'}} onClick={()=>{signOut()}}>
                          SignOut
                    </div>
            </div> */}
            <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
        </div>
    )
}

export default Topbar;
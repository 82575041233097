import React from 'react'
import {EmailOutlined,Call,HomeOutlined} from '@material-ui/icons';
import {Grid,Typography} from '@mui/material';
import {Link} from 'react-router-dom'
const ContactInfowidget = () => {
  return (
      <Grid container justifyContent={'space-between'} sx={{display:'none'}} >
       
          <Grid item xs={6}>
          <Grid container>
           <Grid item>
           <div className="topbarContactLeft">
             <Link to='/' className='topnavlink'>
         <Typography variant='body2'><HomeOutlined  className='topbarinfoIcon'/></Typography> 
         </Link>
         <Link to='/aboutus' className='topnavlink'>
          <Typography variant='body2' className="topleftTypo" >AboutUs</Typography>
         </Link>
        
         </div>
           </Grid>
         </Grid>
           
             
          </Grid>

          <Grid item xs={6} >
            <Grid container justifyContent='flex-end'>
                <Grid item>
                <Typography variant="body2" className='topnavlink'> <EmailOutlined fontSize='small'className='topbarinfoIcon'/><a className='topnavlink' href='mailto:abdulrazakneate@gmail.com'>abdulrazakneate@gmail.com</a></Typography>
                </Grid>
                <Grid item>
                <Typography className='topnavlink' variant="body2"> <Call fontSize='small' className='topbarinfoIcon'/><a  className='topnavlink' href='tel:+233548496121'>+233548496121</a></Typography>
                </Grid>
            </Grid>
    
          </Grid>
      </Grid>
      
        
   
  )
}

export default ContactInfowidget

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function StoreSelect({stores,handleOnStoreChange,storeindex}) {
  
  //const [mstores, setMstores] = React.useState(stores);
  //const [istorefiltered,setIstoreFiltered]=React.useState(false)
   /*const handleChange = (event) => {
    setAge(event.target.value);
  }; 
    React.useEffect(()=>{
      const checkStatus =(store)=>{
        return store.status==='Verified'
      }
    if (!istorefiltered){
      setMstores(mstores.filter(checkStatus))

    }
      console.log(mstores)
      return ()=>{
        setIstoreFiltered(true)
      }
    }, [istorefiltered, mstores])*/
  return (
    <FormControl sx={{ m: 1,width:160, minWidth: 120}} size="small">
{/**/}        <InputLabel id="demo-select-small">Store</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={storeindex}
/* */        label="Store"
         onChange={handleOnStoreChange}
        style={{fontSize:14}}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
      {
     /*  stores.map((store)=>{
        return store.status==='Verified'?<MenuItem>{store.name}</MenuItem>:''
        }) */

         
      // --> Notice below changes
      stores.length > 0 && 
        stores.map((store, i) => {
          return (<MenuItem  key={i} value={i}>{store.name}</MenuItem>)
        })
      }
      
       
      </Select>
    </FormControl>
  );
}

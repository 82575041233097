import React, { useState } from 'react'
import thumbnail from './thumbnail-wide.png';
import {Grid,Typography,CircularProgress} from '@mui/material'
import './index.css'
import imageCompression from 'browser-image-compression';
const ImageGallery = ({handleImages,productImages,base64EncodedImage}) => {
  
    const[imagediv]=useState(["0","1","2"]);
    const [imageTagIndex, setImageTagIndex] = useState(null);
    const [ImageToLoadId, setImageToLoadId] = useState(null);
    const [shownProgressId,setShownProgressId]  = useState(0);
    const onImageClicked = (e,imgIndex) => {
        console.log(imgIndex)
        setShownProgressId(imgIndex)
        const formfile = document.getElementById("product-file");
        
        formfile.click()
        setImageToLoadId(e.target.id) //sets id of the image

        let character = (e.target.id).toString(); //convert number to string
        //get last character of product-image# which gets cliked
        setImageTagIndex(character.charAt(13));

    }
   /*  async function handleImageUpload(event) {

        const imageFile = event.target.files[0];
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 520,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile ); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      
         // await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(error);
        }
      
      } */
  
 function blobToBase64(blob) {
            return new Promise((resolve, _) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
          } 

   async function  onFileInputChange  (e) {
        var progress = document.getElementById(`image-load-progress${shownProgressId}`)
            progress.style.display ='block'
        var file = e.target.files[0];
        var reader = new FileReader();
        var base64EncImage='';
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 420,
            useWebWorker: true
          }        
          
          const compressedFile = await imageCompression(file, options);
                       blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
                        base64EncImage=base64image
                       })

        reader.onloadend = function (e) {
                
            let indextoRemove = parseInt(imageTagIndex);//gets Index of  clicked image 
                 progress.style.display ='none'

                document.getElementById(ImageToLoadId).src = thumbnail;
          
              try{
            //push image item whiles Array length is 3 
            //else replace existing index with new image  
            productImages.length <= 2 ? productImages.push(file) : productImages.splice(indextoRemove, 1, file);
            base64EncodedImage.length <= 2 ? base64EncodedImage.push(base64EncImage) : base64EncodedImage.splice(indextoRemove, 1,  base64EncImage);
            // console.log("replaced index "+typeof(indextoRemove));
              }catch(err){

                 console.log(err)
              }

            console.log(productImages.length);

            document.getElementById(ImageToLoadId).src = e.target.result

            handleImages(productImages)
        };
        try {
            reader.readAsDataURL(file)

        } catch (error) {
            console.log({ readAsDataURLError: error })
        }
    }
    function resizeBase64Img(base64, newWidth, newHeight) {
        return new Promise((resolve, reject)=>{
            var canvas = document.createElement("canvas");
            canvas.style.width = newWidth.toString()+"px";
            canvas.style.height = newHeight.toString()+"px";
            let context = canvas.getContext("2d");
            let img = document.createElement("img");
            img.src = base64;
            img.onload = function () {
                context.scale(newWidth/img.width,  newHeight/img.height);
                context.drawImage(img, 0, 0); 
                resolve(canvas.toDataURL());               
            }
        });
    }
  return (
   <div>
      <Typography variant='body1' style={{margin:2}}>Gallery</Typography>
     <Grid  justifyContent='space-between' container spacing={1} padding={0 }>
         {
             imagediv.map((img,index)=>{
                 return(<Grid item xs={4} sm={4} md={4} lg={4}>  <>
                   <div  id={`image-load-progress${img}`} className={'image-gallery-circularProgress-wrapper'}>
                 <CircularProgress  variant='indeterminate' color ='secondary' size={29} /> </div>
                 <img className="client-new-productImg" alt={img}key={index} id={`product-image${img}`} src={thumbnail}  onClick={ (e) => { onImageClicked(e,img) }}/>
                 </> 
                 
                 </Grid>)
             })
         }
          <input style={{display:"none"}} type="file" id="product-file" multiple onChange={onFileInputChange} />
    </Grid>
   </div>
  )
}

export default ImageGallery

import React from 'react'
import './style.css';
import {Grid,Typography} from '@mui/material'
import storeThumbnail from '../../assets/icons/online_store_96px.png';
//import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'
import useStyles from './style'
import { myCld } from '../../cloudinaryImage';
const StoreCard = ({store,balance,theme}) => {
    const classes =  useStyles(theme)
    const [storeImage,setStoreImage]=React.useState(storeThumbnail)
    const history =useHistory()
    React.useEffect(()=>{
   try{
    store.image.public_id!==undefined?setStoreImage(myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()):setStoreImage(storeThumbnail)
   }catch(err){
    console.log(err)
   }
    },[store.image])
  return (
   <div className={classes.root}>
 <Grid container justifyContent='space-between' alignItems='center' padding={1} style={{border:'0px solid',height:'24px'}}>
                    <Grid item  onClick={()=>{history.goBack()}}>
                       <ArrowBackIcon htmlColor='snow'/>
                    </Grid>
                    <Grid item >
{/*                      <Typography color='snow'>{` Local currency: ${store.currency}`}</Typography>
 */}                    </Grid>
              </Grid>
  <div className='balance'>
  <Grid container justifyContent='center' alignItems='center' style={{height:'30%',width:'100%',background:'transparent',border:'0px solid darkgrey',top:'1%',position:'relative'}}>
      <Grid item >
            <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                <Grid item >
                  <Typography variant='h4' color='snow'>Balance</Typography>
                </Grid>
                <Grid item >
                  <Grid container justifyContent='space-between'>
                  <Grid item ><Typography variant='h6' color='snow'>π</Typography></Grid>
                 <Grid item ><Typography variant='h6' color='snow'>{balance!==undefined ?balance:'0.00000000'}</Typography></Grid>
                  </Grid>
                </Grid>
               
            </Grid>
      </Grid>
    </Grid>
  </div>
  <div className='store-card-title'>
   <Grid container justifyContent='start' alignItems='center' style={{height:'60%',width:'100%',background:'transparent',border:'0px solid red',top:'11%',position:'relative'}}>
               <Grid item xs={2}  padding='2px 4px'>
                
                  <img className={classes.storeImg}  src={storeImage} alt=""/>
              </Grid>
              <Grid item xs={10} padding='2px 4px'>
{/*                 <Typography variant='h5' color='snow'>  {store.name} </Typography>
 */}              </Grid>
              </Grid>
   

    <div  style={{height:'40%',backgroundColor:'snow'}}>
    <Grid container justifyContent='space-between' alignItems='center' padding={1}>
                    <Grid item >
                    <Typography fontSize={'1.2rem'} variant='h5' color='#000'>  {store.name} </Typography>
                    </Grid>
                    <Grid item >
                     <Typography fontSize={'1rem'} >{`  ${store.currency}`}</Typography>
                    </Grid>
              </Grid>
            
    </div>
   {/*  <div  style={{height:'20%',backgroundColor:'blue'}}>
              
     </div> */}
 </div>
   </div>
  )
}

export default StoreCard

import React from 'react';
import { TextField, Grid} from '@material-ui/core';

const FormInput = ({ name, label,placeholder,size,margin,required,register ,type}) => {
   
return (
   <Grid item xs={12} sm={12}>
       <TextField
           style={{padding:0,margin:0,fontSize:10}}
         {...register(name)}
            fullWidth
            label={label}
            placeholder={placeholder}
            required={required}
            type={type !==null ?type:'text' }
            />
   </Grid>
 );
 }

export default FormInput;
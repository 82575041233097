/* eslint-disable no-unused-vars */
import React,{useEffect} from 'react'
import useStyles from './index.js';
import {useHistory,Link}from 'react-router-dom'
import { Grid,Typography} from '@material-ui/core';
import Product from '../products/product/Product';
import { blue, orange } from '@mui/material/colors';
import {  createTheme } from '@mui/material/styles';
const Search = ({products,onAddToCart,onUpdateLikes,favorites,setOpenModal,setIsUserSearching,showAds}) => {
   const history=useHistory()
   const[results,setResults]=React.useState([""])
    console.log(products)

    const NoResults = ()=>(
      <div className={classes.notice}>
        <Typography variant="subtitle2">No results found matching your search
       <Link onClick={()=>{setIsUserSearching(false)}}to="/" className={classes.link} > Go to home</Link>
       </Typography>
      </div>
    );
    
  useEffect(()=>{
    setResults(products)
   /*  if (results.length<=0){
       history.push('/')
    } */
  },[products])
  const theme = createTheme({
    palette: {
      primary:{
        main:blue[500],
      /*main:"#3f51b5",*/
      },
        secondary: {
            main:orange[500],
            contrastText:'#fff'
        }
      },
});  
  const classes=useStyles();
   //console.log(results.length)
   const NoReRsultsFound =()=>{
    return(
         <Grid container  spacing={1} padding={0} justifyContent='center' alignItems='center'>
        
            <Grid item  >
              <NoReRsultsFound/>
        
         </Grid>
           
        </Grid>
    )
   }
  return (
    <main className={classes.contnt}>
       {
       
       products !==undefined ?  <>{
        products.length > 0 ? <Grid container  spacing={1} padding={0} justifyContent='flex-start'>
          {products.map((result,index) =>(
           <>
           {
             result.active ==='yes' ?
             <Grid item key={`grid-product-${index}`} xs={6} sm={4} md={4} lg={3}>
             <Product prodkey={`product-${result._id}`} product={result} favorites={favorites} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes}setOpenModal={setOpenModal} />
             
           </Grid>:''
           }
           </>
          ))}
         </Grid>:<NoResults/>
        }</> :''
       }
    
    </main>
  )
}

export default Search

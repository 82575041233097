import React,{useState,useEffect} from 'react'
import useStyles from'./styles';
import {Grid,Typography,Card,CardMedia,Button } from '@material-ui/core';
import OrderItem from './order/orderItem';
import {Link} from 'react-router-dom';
import Comments from './comments/Comments';
import AlertDialog from '../../components/alertdialog/AlertDialog'
import CheckOutNavigation from '../../components/checkoutnavigation/CheckOutNavigation'
import Details from './details/Details';
import {axiosClient,config} from '../../services/axiosClient';
import AdsTerraNative from '../ads/AdsterraNative';
import OrderTabs from './orderTaps/OrderTabs';
import { AdsTerra } from '..';



const Orders = ({onStatusUpdate,userid,user,orders,setMyOrders,setShowProgress,ref,authenticateToken,setMessage,setOpenSnackBar,rateStore,updateAppcredits,isOrdersSorted,setisOrdersSorted,selectedOrder,setSelectedOrder,handlerequestRefund,showAds}) => {
  const classes =useStyles();
  const loggedin =localStorage.getItem('loggedin');
  const [openComments,setOpenComments]=useState(false);
  const [showDetails,setShowDetails]=useState(false);
  const [showFilled,setShowFilled]=useState(false);
  const [order,setOrder]=useState([]);
  const [paidOrders,setPaidOrders]= useState([]);
  const [shippedOrders,setShippedOrders]= useState([]);
  const [recievedOrders,setRecieveOrders]= useState([]);
  const [completedOrders,setCompletedOrders]= useState([]);
  //const [selectedOrder,setSelectedOrder]=useState({})
  const [store,setStore]=useState({});
  const [open,setOpen]=useState(false);
  const [selectedTap, setSelectedTap] = React.useState(0);

  const[isAccessTokenRefreshed,setAccessTokenRefreshed]=useState(false)
  const dialogRef = React.createRef()
  var counter=1
  const handleClickOpen = () => {
      
    setOpen(true)

 };
 const handleClose = (option) => {
      
  setOpen(false);
};
function isEven(num,count) {
  counter +=1;
  var bool=false
  if (counter ===count){
    bool = num % 2 === 0 ? true : false
   counter=1
  }

  return bool
}
useEffect(()=>{
 try{
  setShowFilled(orders.length >= 1 ? true :false)
  if(!isAccessTokenRefreshed){
    const userobj =JSON.parse( localStorage.getItem('user'))
    authenticateToken(userobj.user)
   }
   if (!isOrdersSorted && orders.length >0){
    //setallOrders(orders)
    setPaidOrders(orders.filter(order =>      order.status.title==="Paid"))
    setRecieveOrders(orders.filter(order =>   order.status.title==="Recieved"))
    setShippedOrders(orders.filter(order =>   order.status.title==="Shipped"))
    setCompletedOrders(orders.filter(order => order.status.title==="Completed"))

  }
  
  return () => {
  setisOrdersSorted(true)
}
     
 }catch(error){
  console.log(error)
 }

  return ()=>{
   setAccessTokenRefreshed(true)
  }
},[authenticateToken, isAccessTokenRefreshed, isOrdersSorted, orders, setisOrdersSorted])
  const handleonReviewClick =(order)=>{
    setShowDetails(false)
     if (loggedin==='true'){
      setOrder(order)
      setOpenComments(!openComments);
     }else{
  
      setOpen(true)  
    // alert('please login to view and comment')
     }
  }
  const handlegetStore = async (order)=>{
   if(order.status.title!=='null'){

    setSelectedOrder(order)
    setShowProgress(true)
    const url=`${process.env.REACT_APP_SERVER_URL}/stores/${order.storeId}`
   await axiosClient.get(url,{},config).then((response)=>{
    setShowProgress(false);
    setOrder(order)
      if (response.status===200){
         setStore(response.data.store);
         setShowDetails(true);
      }
    })
   }else{
    /*  setMessage({body:`order was not complete`,severity:'info'})
     setOpenSnackBar(true)  */
   }
  }

  const EmptyOrder =()=>(
    <Grid container flexdirection='column' alignItems='center' justifyContent='center' style={{border:'0px solid',margin:'10px 30px'}}>
          <Grid item xs={12}>
          <Typography variant="subtitle1">You have no items in your orders,
    </Typography>
          </Grid>
          <Grid item xs={12} alignItems='center'>
          <Typography variant="subtitle1">
    <Link to="/" className={classes.link}>start making orders</Link>
    </Typography>
          </Grid>
    </Grid>
);

const CommentsContainer = ({userid,user,order})=>(
 <>  <div className={classes.commentsTitle}>
    
   <Typography variant="h5">Comments and Review </Typography>
      <Button  size='small' color='primary' variant='text' onClick={()=>{setOpenComments(!openComments);setShowDetails(!showDetails)}}>Done</Button>
 </div>
 <div style={{display:'flex',justifyContent:'space-between'}}>
   <Card className={classes.ordercard}>
      <CardMedia className={classes.media} image={`${order.productImageUrl}`}></CardMedia>
    </Card> 
 </div>
   
  <Comments userid={userid} user={user} order={order} updateAppcredits={updateAppcredits}/></> 
);
const FilledOrder = ()=>(
      <>
      <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title="Notice !" textContent={`Please Login to continue  `} positiveButtonText={'Ok'}negativeButtonText={'Cancel'} />
      
     {showDetails ? <Details  userid={userid} user={user}  store={store} order={selectedOrder} myOrders={orders} setMyOrders={setMyOrders} setShowDetails={setShowDetails} showDetails={showDetails} onReviewClick={handleonReviewClick} onStatusUpdate={onStatusUpdate} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} rateStore={rateStore} updateAppcredits={updateAppcredits} setisOrdersSorted={setisOrdersSorted} handlerequestRefund={handlerequestRefund}/> :<Grid container justifyContent='space-between' direction='column'>
       {/*  <Grid item>
      {  <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Grid item >
                            <AdsTerra adkey='52f66475ed3ada3af8c5929ec857afd5' height={50} width={320}/> 
                            </Grid>
                          </Grid>}
        </Grid> */}
        <Grid item >
        <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/>
          <OrderTabs orders={orders} handlegetStore={handlegetStore} onReviewClick={handleonReviewClick} isOrdersSorted={isOrdersSorted} setisOrdersSorted={setisOrdersSorted} paidOrders={paidOrders}shippedOrders={shippedOrders}recievedOrders={recievedOrders} completedOrders={completedOrders} selectedTap={selectedTap} setSelectedTap={setSelectedTap}/>
       {/*  <Grid container justifyContent="center" spacing={0}  ref={ref}>
         { <>
           {
             orders.map((order,index)=>(
              <>
               <Grid item key={order._id} xs={12} sm={12} md={12} lg={12}>
               <OrderItem order={order}  onReviewClick={handleonReviewClick} handlegetStore={handlegetStore} selectedItem={index}/>
               </Grid>
             { <>{ showAds ?isEven(index, 2) ?<Grid>
              <AdsTerra adkey='52f66475ed3ada3af8c5929ec857afd5' height={50} width={320}/> 

               </Grid>:'' :''}</> 
               }

              </>

                ))

           }
         </>

         }
       </Grid> */}
        </Grid>
      </Grid>
      }

      </>
) 

  return (
    <div className={classes.root}>
      <CheckOutNavigation title={'Orders'}/>
       {
         openComments ? <CommentsContainer userid={userid} user={user} order={order}/> : <>
         
         <Grid container justifyContent='space-between' padding={10}>  
          <Grid item >
         
          </Grid>
        </Grid>
      {
          !showFilled ? EmptyOrder():FilledOrder()
        }</>
       }
     
     
      
    </div>
  )
}

export default Orders

import React,{useState,useEffect,useRef} from 'react'
import useStyles from './styles';
import ImageView from './imageview/ImageView';
import ProductDetails from './productDetails/ProductDetails';
import QueryParams from '../../QueryParams';
import {axiosClient} from '../../services/axiosClient'
import {Grid,Typography,Box,Button} from '@mui/material';
import StepperSlider from './slider/StepperSlider'
import CommentItem from '../comments/commentitem/CommentItem';
import CheckOutNavigation from '../../components/checkoutnavigation/CheckOutNavigation'
import { useHistory } from 'react-router-dom';
import { myCld } from '../../cloudinaryImage'
import AdsTerra  from '../../components/ads/Adsterra';
import AdsTerraNative from '../ads/AdsterraNative';
import RelatedProducts from '../products/RelatedProducts';
import { Medialinks } from '..';
import AdBanner from '../ads/custom/AdBanner';

const ProceedcheckOut = ({onAddToCart,showAds,onUpdateLikes,favorites,setOpenModal,loadMore,LoadingMore,setLoadingMore,noMoreProducts,setShowProgress,medialinks,adsImages , hoplinks}) => {
    const history =useHistory()
    const query =QueryParams()
    const classes = useStyles();
    const[storeid,setStoreId]=useState('');
    const[productid,setProductId]=useState('');
    const[product,setProduct]=useState();
    const[relatedproducts,setRelatedproducts]=useState([])
    const[store,setStore]=useState();
    const[images,setImages]=useState([]);
    const[comments,setComments]=useState([])
    const isMountedRef =useRef(true)
    const [isproductLoaded,setIsproductLoaded]=useState(false)
    const [isCommentsLoaded,setIsCommentsLoaded]=useState(false)
    const [isStoreLoaded,setIsStoreLoaded]=useState(false)
   
    function useForceUpdate(){
      const [value, setValue] = useState(0); // integer state
      return () => setValue(value => value + 1); // update state to force render
      // A function that increment 👆🏻 the previous state like here 
      // is better than directly setting `setValue(value + 1)`
  }
/* const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
})); */

  const CommentList =()=>{
    return(
           <div className={classes.proceedcheckOutcommentList} >
             {
               comments.map((comment,index)=>{
                
                return (<CommentItem comment={comment} key={index}/>)
               
             })
            }
           </div>
    
           
    )
  }

    useEffect(()=>{ 
     setStoreId(query.get('storeId'));
     setProductId(query.get('productId'));
      const config = {
        headers: {
            'Content-Type':'application/json',
          },timeout: 20000, withCredentials: true,credentials: "include"
         
       }
     

     
     isMountedRef.current=false


        const getProduct = async ()=>{
             fetchProduct().then((response) => {
               if (response.status===200){
                   
                 try{
                  const product=response.data.product
                     
                  if (product){
                   
                    setProduct(response.data.product)
                    setImages(response.data.product.image)
                    console.log()
                  }
                  if (product.category.categoryGroupId!==undefined) {getRelatedProducts(product.category.categoryGroupId)}
                 }catch(err){
                   console.log(err)
                 }
               }
               //addToast(exampleToast(response.data.message));
             })
           
         }

         const getStore = async ()=>{
          console.log(productid)
           fetchStore().then((response) => {
           // console.log(response.data);
             if (response.status===200){
                 
               try{
                
                const stores=response.data.store
                 setStore(stores[0])
               }catch(err){
                 console.log(err)
               }
             }
             //addToast(exampleToast(response.data.message));
           })
         
       }
         
         
           
         
           const fetchProduct =()=>{
         
             const url = `${process.env.REACT_APP_SERVER_URL}/products/${productid}`;
             
             return axiosClient.get(url,{},config)
           
           };
          
           const fetchStore = async ()=>{
         
            const url = `${process.env.REACT_APP_SERVER_URL}/stores/${storeid}`;
            
            return await axiosClient.get(url,{},config)
          
          };
          const fetchRelatedProduct =(categoryGroupid)=>{
         
            const url = `${process.env.REACT_APP_SERVER_URL}/products/category/group/${categoryGroupid}`;
            
            return axiosClient.get(url,{},config)
          
          };
          const getRelatedProducts = async (categoryGroupid)=>{
            fetchRelatedProduct(categoryGroupid).then((response) => {
              setShowProgress(false)
              if (response.status===200){
                window.scrollTo(0, 0)
                try{
                  const products=response.data.products
                  setRelatedproducts(products.filter((p)=>p._id !==productid))
                }catch(err){
                  console.log(err)
                }
              }
              //addToast(exampleToast(response.data.message));
            })
          
        }
     const handlegetComments = async() =>{
      loadCommentsFromServer().then((response)=>{
        if(response.status===200){
          setComments(response.data.comments)
        }
      })
  }
   const loadCommentsFromServer= async ()=>{
     const url=`${process.env.REACT_APP_SERVER_URL}/comments/${storeid}/${productid}`;
    return axiosClient.get(url,{},config)
   }

   
      if (productid){
       
       if (!isproductLoaded){
        // getProductMetatData()
         getProduct();}
       if (!isCommentsLoaded){handlegetComments();}
       if (!isStoreLoaded){
        setStore({})
        getStore()
      }
       }
     return()=>{
       setIsproductLoaded(true)
       setIsCommentsLoaded(true)
       setIsStoreLoaded(true)
     }
       
    },[isCommentsLoaded, isStoreLoaded, isproductLoaded, productid, query, setShowProgress, storeid])
  return (
   <div className={classes.content}> 
     <div style={{position:'absolute' ,marginTop:"-40",zIndex:99999}}>
     <CheckOutNavigation/>
     </div>
   { product ?
    <Grid container justifyContent="center" spacing={1}>
    
    <Grid item={true} xs={12} sm={12} md={6} lg={6}>
    <ImageView images={images} className={classes.imageView}/> 
   {/*  <Slider images={images} /> */}
   <div className={classes.imgbox}>
   {
      images.length > 0 ? <><StepperSlider images={images} /> {/* <Box
      component="img"
      sx={{
        height: 180,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
        objectFit:'cover',      }}
      src={`${images[0].secure_url}`} 
      src={myCld.image(`${images[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} alt={images[0].recource_type}
    /> */}</>  :''
    }
   </div>
    </Grid>
    <Grid item={true} xs={12} sm={12} md={5} lg={4}>
      { product ?  <ProductDetails product={product} store={store} onAddToCart={onAddToCart}/> : ' ...' /* */}
      
    </Grid>
     
     </Grid>
     
     : ''}
       <Grid container justifyContent='space-between'>
              <Grid item xs={0} sm={0} md={4} lg={2}>

                </Grid>
                
                  <Grid item xs={12} sm={12} md={4} lg={6} marginTop={2}>
                    <Grid item >
                       <Grid container >
                      <Grid item  xs={12}>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Grid item >
                         {/*  {showAds ?  <AdBanner src={adsImages[1]} variant='quiz'link={hoplinks[1]}/> : ''} */}
                            </Grid>
                          </Grid>
                        

                        </Grid> { showAds ?"":''}
                           { store ? <Grid item xs={12}>
                                 <Grid container justifyContent={'flex-start'} spacing={1} padding={1} alignItems='center'>
          <Grid item>
          <Typography variant='overline' style={{fontSize:'14px'}} color='GrayText'>  Store :</Typography>
          </Grid>
          <Grid item >
          <Button variant='text' color='primary' onClick={()=>{history.push(`/store?storeId=${storeid}`)}}> {store.name}</Button>
          </Grid>
        </Grid>

        </Grid>:'' }
                       </Grid>
                    </Grid>
                  
          
       
                  </Grid>

                  <Grid item xs={0} sm={0} md={4} lg={4}>

                </Grid>
          </Grid>      
           <Grid container justifyContent={'center'}>
              <Grid item >
              { showAds ? <AdsTerraNative adkey='4bc83656577a327298971c0f5e486ead'/> 
 :''} 
{/*               <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/>
 */}              </Grid>
           </Grid>
         { comments.length >0 ? <Grid container justifyContent='space-between'>
              <Grid item xs={0} sm={0} md={4} lg={2}>

                </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={5} className={classes.commentWrapper}>
                    
                   <CommentList/> 
                  
                  </Grid>

                  <Grid item xs={0} sm={0} md={4} lg={5}>

                </Grid>
          </Grid>   :''} 
       
          { relatedproducts.length >0 ? <Box  sx={{pt:1,pb:1,b:1,bgcolor:'snow',width:'99vw',height:'40vh'}} >
               <Grid container marginTop={5} marginBottom={1} >
          <Grid item padding={1}>
          <Typography variant='body2' color='GrayText'>You may also like the following </Typography>
          </Grid>
        </Grid>


                 
                   <RelatedProducts products={relatedproducts} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} showAds={false} setIsproductLoaded={setIsproductLoaded} setIsCommentsLoaded={setIsCommentsLoaded} setIsStoreLoaded={setIsStoreLoaded} setStoreId={setStoreId} setProductId={setProductId} setShowProgress={setShowProgress} setStore={setStore}/> 

                   <Grid container padding={2}  justifyContent={'center'}>
                      <Grid item marginBottom={10}>
                      <Typography variant='body2' className='recents-products-title' >Follow us on social media for more updates </Typography>
          {  medialinks ?  <Medialinks medialinks={medialinks} />:''}
                      </Grid>
                   </Grid>
                  </Box>   :''}   
      
     </div>
  )
}
export default ProceedcheckOut

/* eslint-disable no-unused-vars */
import React,{useState, useRef,useEffect} from 'react'
import useStyles from './index.js';
import {useHistory}from 'react-router-dom'
import Product from './product/Product';
import { blue, orange } from '@mui/material/colors';
import {  createTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import AdsTerra from '../ads/Adsterra.jsx'
import {CircularProgress,Button,Grid} from '@mui/material';
import AdsTerraNative from '../ads/AdsterraNative.jsx';
import RelatedProduct from './product/RelatedProduct.jsx';

const RelatedProducts = ({products,onAddToCart,onUpdateLikes,favorites,setOpenModal,loadMore,LoadingMore,setLoadingMore,noMoreProducts,showAds,setIsproductLoaded,setIsCommentsLoaded,setIsStoreLoaded,setStoreId,setStore, setProductId,setShowProgress}) => {
   const history=useHistory()
   const bottomRef = useRef(null); // ref for the bottom of the list element
  // function to load more records
  var counter = 0
  const loadMoreRecords = useCallback(()=> {
    loadMore()
    //console.log("load more ... ")
    // implement your logic to load more records here
    // set the new records using setRecords() and update the loading state
  },[loadMore]);

  function isEven(num) {
    counter +=1;
    var bool=false
    if (counter ===10){
      bool = num % 2 === 0 ? true : false
     counter=1
    }

    return bool
}
  useEffect(()=>{
    
    // Intersection Observer callback
   const handleObserver = (entries) => {
    //console.log(entries)
    //console.log(LoadingMore)
    const target = entries[0];
    if (target.isIntersecting && !LoadingMore && !noMoreProducts) {
      // check if the bottom of the list is visible and not already loading
      loadMoreRecords(); // load more records 
    }
  };
    var currentRef= bottomRef.current
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    });
    if (currentRef) {
      observer.observe(currentRef);
    } 
    
    if (products.length<=0){
       history.push('/')
    }
    return () => {
      // cleanup the Intersection Observer when the component unmounts
      if (currentRef) {
        observer.unobserve(currentRef);
        
      }
    };
   
    
  },[history, loadMoreRecords, LoadingMore, products.length, noMoreProducts])

  const theme = createTheme({
    palette: {
      primary:{
        main:blue[500],
      /*main:"#3f51b5",*/
      },
        secondary: {
            main:orange[500],
            contrastText:'#fff'
        }
      },
});  
  const classes=useStyles();
   //console.log(products.length)
   
  return (
    <div style={{overflowX:'auto',border:'0px solid'}} >

      <Grid container  wrap='nowrap' spacing={1} height={'170px'} marginTop={1} padding={0} justifyContent='flex-start' >
       
       {products.map((product,index) =>(  
      
        <Grid item key={`related-product-${product._id}`}>
        { 
          product.active ==='yes' && product.verified === true ?
        
         
          <RelatedProduct key={`rel-product-${product._id}`} product={product} favorites={favorites} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes}setOpenModal={setOpenModal} setIsproductLoaded={setIsproductLoaded} setIsCommentsLoaded={setIsCommentsLoaded} setStore={setStore}setIsStoreLoaded={setIsStoreLoaded} setStoreId={setStoreId} setProductId={setProductId} setShowProgress={setShowProgress} />
          
       
         :''
        }
        </Grid> 
      
       ))}

      </Grid> 
   {/*  { LoadingMore ?<Grid container  justifyContent={'space-between'} marginTop={2}>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4} >
            <Grid container justifyContent={'center'}>
                  <Grid item  alignItems={'center'}><CircularProgress  variant='indeterminate' color ='primary' size={20} /></Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>:''} 
        <div ref={bottomRef}></div>  element to observe */}
    </div>
  )
}

export default RelatedProducts

import React from 'react'
import './searchfield.css'
import useStyles from '../styles';
import {Grid} from '@mui/material'
import {Search} from '@mui/icons-material'

function SearchField({handlesearchProduct,hanldeonSearchInputChange}) {
  const classes=useStyles()
  const[searchString,setSearchString]=React.useState('')

  const onInputChange = (value)=>{
    setSearchString(value)
    hanldeonSearchInputChange(value)
  }
  return (
    <Grid container justifyContent={'space-between'} alignItems='center' className={classes.searchfield} >
     <Grid item xs={8}>
      <Grid container  alignItems={'center'} justifyContent='flex-end' >
      <input   className={classes.searchInput} placeholder='Search products...'  onChange={(e)=>{onInputChange(e.target.value)}}/>
      </Grid>
     </Grid>
   <Grid item >
       <Grid container alignItems={'left'} justifyContent='flex-start' marginRight={0.8}>
       <Search xs={3} onClick={()=>{handlesearchProduct(searchString)}} className={classes.searchIcon} style={{ color:'var(--app-primary)', transform:'scale(1)',}}/>
       </Grid>
   </Grid>

     
    </Grid>
  )
}

export default SearchField

/* eslint-disable no-unused-vars */
import React,  { useRef } from 'react';
import './style.css';
import { TextField,Button,Switch,CircularProgress,Grid,Card,InputAdornment} from '@mui/material';
import {  Edit,Payment } from '@material-ui/icons';
import {useState , useEffect} from "react";
import Alert from './alert/Alert'
import Verifyalert from './alert/verifyalert/Verifylert';
import QueryParams from '../../../QueryParams';
import { formarttoCurrency } from "../../../utils/Utils"
import {StoreCard} from  '../..'
import {axiosClient,axiosPiClient,axiosPiWidrawClient,config} from '../../../services/axiosClient'

const Withdraw = ({store,handlegetTransactions,transactions,balance,handlegetStores,showprogress,setShowProgress,theme ,setMessage,setOpenSnackBar,user,signIn,setBalance,handleSendConfirmationCode,setEmailVerified,handleConfirmedUser, page,perPage}) => {
  const [stores]=useState(JSON.parse(localStorage.getItem('stores')));
  const [open,setOpen]=useState(false);
  const [openconfirmation,setOpenconfirmation]=useState(false);
  const [textvalue,setTextValue]=useState(0)
  const [completedPayment,setCompletedPayment]=useState()
  const [account,setAccount]= useState()
  const[isStoreTransLoaded,setIsStoreTransLoaded]=useState(false)
  const [showBtn,setShowBtn]=React.useState(true)
  const [charges,setCharges]=React.useState(0.01)
  const [minimumWithdrawal,setMinimumWithdral]=useState(parseFloat(charges*2))
  const handleWithdraw = (amount,ourpay,debitAmount,productid,ordernumber,memo)=>{
    /*     var scopes =["username","payments","wallet_address"]
  signIn(scopes)
 } */
     if (user){ 
           if(amount > -0){
          setShowProgress(true)
          const url ='/payments/a2u/withdraw'    
          axiosPiWidrawClient.post(url,{amount:amount,ourpay:ourpay,debitAmount:debitAmount,uid:user.uid,memo:memo,storeId:store._id},config).then((response) =>{
                 setShowProgress(false)
                 console.log(response.data.status)
                if (response.data.status===500){
                  setMessage({body:`you need to allow consent,requesting `,severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(true)
                  var scopes = ["username","payments","wallet_address"]
                  signIn(scopes)
                }else if (response.data.status===501){
                  setMessage({body:`too many payments,tray again later `,severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(false)
                 
                }else if (response.data.status===502){
                  setMessage({body:`too many cancelled payments,tray again later `,severity:'info'})
                  setOpenSnackBar(true)  
                   setShowBtn(true)
                  setOpen(false)
                  setShowProgress(false)
                 
                }else{
                  
                if (response.data.status===200){
                     console.log(response)
                     setCompletedPayment(response.data.completedPayment)
                     setAccount(response.data.account)
                     console.log(parseFloat(response.data.account.balance).toFixed(8))
                     setBalance(parseFloat(response.data.account.balance).toFixed(8))
                  setTextValue(0)
                  setMessage({body:`widraw Completed`,severity:'success'})
                  setOpenSnackBar(true)   
                }else{
                  setMessage({body:`operation Already occured`,severity:'info'})
                  setOpenSnackBar(true)   
                }
                }
            })
         }else{
          setMessage({body:`amount not valid `,severity:'info'})
                 setOpenSnackBar(true)  
         }
      
        
     }
       
   }
  

   const withdraw =()=>{
    const estimate = parseFloat(textvalue) - minimumWithdrawal

          console.log(estimate < 0)
          console.log(estimate)
    console.log(JSON.parse(localStorage.getItem('confirmed')))
    if(JSON.parse(localStorage.getItem('confirmed'))!==true){
      
      setOpenconfirmation(true)

      }else{
        
      if (estimate < 0){
        setMessage({body:`minimum widrawal is ${minimumWithdrawal}  `,severity:'info'})
        setOpenSnackBar(true)  
      }
      else if (parseFloat(textvalue) <=parseFloat(balance-charges).toFixed(8) ){ 
       
       handleOpen()
      }else{
      setMessage({body:`not enough balance `,severity:'info'})
      setOpenSnackBar(true)  
      }
     }
       
   
    //console.log(parseFloat(balance-0.001).toFixed(8))
   
    /*
   if (textvalue > 0.001){
    const amount=textvalue-0.001;
    console.log(amount)
    const memo=`widraw  for ${store.name}`
    handleWithdraw(amount,null,null,memo)
   }else{

   } */
  
  }
  
    const onValueChange =(e)=>{
        console.log(e.target.value)
     setTextValue(e.target.value)
    }
    
    const setMax =()=>{
        setTextValue(parseFloat(balance-charges).toFixed(8))
       }
       

    const handleClose = () => {
        
        setOpen(false);
        setOpenconfirmation(false)
        setCompletedPayment(undefined)
      if (!showprogress){   }
    };
 
  
 
   
 
  useEffect(() => {  
 if (stores.length >0){
    if (!isStoreTransLoaded){
      handlegetTransactions(page,perPage); 
      setShowProgress(true) 
    }
  }
  return () =>{
       

    setIsStoreTransLoaded(true)
  }
  },[handlegetStores, handlegetTransactions, isStoreTransLoaded, page, perPage, setIsStoreTransLoaded, setShowProgress, stores]);
  const getDateNow =(dateNumber)=>{
    var dateString = new Date(parseInt(dateNumber)*1000);
      var newDate= `${dateString.getFullYear()}-${dateString.getMonth()}-${dateString.getDate()} ${dateString.getHours()}:${dateString.getMinutes()}`
      return newDate
   } 
   const handleOpen = () => {
    
      if (textvalue > 0){
        setOpen(true)
       }else{
        setMessage({body:`amount not valid `,severity:'error'})
        setOpenSnackBar(true)  
     }

     
    }
   

  return (
    <div className="client-withdraw">
      
      <Alert open={open} handleOpen={handleOpen} handleClose={handleClose} title="Update transaction" textvalue={textvalue} handleWithdraw={handleWithdraw} storename={store.name} completedPayment={completedPayment} account={account} showprogress={showprogress} showBtn={showBtn}setShowBtn={setShowBtn} charges={charges}/>   

     {
      user ?    <Verifyalert open={openconfirmation} user={user} handleClose={handleClose} title="Verification needed to continue " textvalue={textvalue} storename={store.name}  account={account} showprogress={showprogress} setShowProgress={setShowProgress} showBtn={showBtn}setShowBtn={setShowBtn} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified}/> :''
     }

     <StoreCard store={store} balance={balance} theme={theme}/>

       <div className="pageTitleContainer">
           <h1 className="pageTitle">Withdraw</h1>    
           
          </div>
      

         <Grid container padding={1}>
          <Grid item>
           <Card style={{padding:'10px 2px'}}>
             <Grid container justifyContent={'space-between'} spacing={2} padding={1}>
               <Grid item xs={12}>
               <Grid container justifyContent={'space-between'} paddingLeft={2} paddingRight={2}>
               <Grid item xs={9}>
                   <TextField 
                   label="Amount to Widraw"
                   InputProps={{style: { padding: 5},startAdornment: <InputAdornment position="start">{`t-π`}</InputAdornment>
    }} value={textvalue} variant='standard' fullWidth onChange={(e)=>{onValueChange(e)}}/>
    
               </Grid>
               <Grid item xs={2}>
                   <Button className='client-action-Button'  variant='contained' color='primary' onClick={()=>{setMax()}}>Max</Button>
               </Grid>
             </Grid>
               </Grid>
               <Grid item xs={12}>
               <Grid container alignItems='center' justifyContent={'center'}  >
                    <Grid item xs={4}>
                   
                    </Grid>
                    <Grid item xs={4}>
                    <Button className='client-withdraw-Button' variant='contained' color='primary' onClick={()=>{withdraw()}}>Withdraw</Button>
                    </Grid>
                    <Grid item xs={4}>
                    
                    </Grid>
               </Grid>
               </Grid>
             </Grid>
           </Card>
          </Grid>
         </Grid>
         
      </div> 
   
  )
}

export default Withdraw


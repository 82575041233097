import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    [theme.breakpoints.down('xs')]:{
          /* marginBottom:'10vh', */
         padding:2,
         height:'70vh',
         border:'0px solid',
        
    }
  },
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: '1vh',
    [theme.breakpoints.down('xs')]:{
      marginTop: 0,
    },
  },
  emptyButton: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]:{
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },
  container:{
    padding:'5px',
    marginBottom:'10px'
 },
  price:{
    color:'red',
    fontWeight:500,
 },
  checkoutButton: {
    minWidth: '150px',
  },
  link:{
    textDecoration: 'none',
    color:'var(--app-primary)'
  },
  notice:{
    padding:5
  },
  cardDetails:{
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      flexDirection:'column',
      width:'100%'
    }, /*  marginTop: '1vh', */  nottom: '3vh',
    width: '100%',
    justifyContent:'space-between',
    [theme.breakpoints.up('xs')]:{
      justifyContent:'space-around',

    },
    border:'0px solid'
  },
  checkoutButtons: {
    width:'50%',
    [theme.breakpoints.down('xs')]:{
      width:'98%'
    },
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    border:'0px solid'
  },
  filledcart:{
    border:'0px solid',
    height:'65vh',
    overflow:'hidden',
    marginBottom:'2vh',
    [theme.breakpoints.down('xs')]: {
      height:'66vh',
      overflow:'scroll',
    

    },
  }
}));

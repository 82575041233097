import {Modal,Typography,Button,Box,Grid,CircularProgress} from '@mui/material';
import React,{useState} from 'react';

import {Link} from 'react-router-dom';
import useStyles from './styles'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid cadetblue',
  borderRadius:'4px',
  boxShadow: 24,
  p: 4,
  padding:0,
};


 export const RModal =  React.forwardRef((props,ref) => {
   // eslint-disable-next-line no-unused-vars
   const[isLoggedInForm,setIsLoggedInForm]=useState(true);

   const classes= useStyles();
   const[message,setMessage]=useState('')
   const handleSwitchForm =(option)=>{
     setIsLoggedInForm(option)
   }
   function Copyright(props) {
    return ( 
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://localhost:3000/" to={'https://localhost:3000/'}>
          {`${process.env.REACT_APP_WEBSITE_NAME}.com`}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  } 
  const handleSignIn =(signIn) =>{
/*     var scopes =["username","payments","wallet_address"]
 */   
   var scopes =["username","payments"]
   signIn(scopes)
  }
   React.useEffect(()=>{
      setMessage(props.showprogress === true ? `Signing in please wait ...`:`You need to sign to continue `)
   },[props.showprogress])

  return (
    <div>
      
      <Modal
        open={props.openModal}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className={classes.root} ref={ref}>
          <Grid container justifyConten='space-around' flexDirection={'column'} alignItems='center' spacing={2}>
            <Grid item >
 <Typography variant='subtitle1' className={classes.message} color='GrayText'>{message}</Typography>
            </Grid>
            <Grid item alignItems={'center'}>
            { props.showprogress ? <CircularProgress  variant='indeterminate' color ='primary' size={29} />
         :''} 
            </Grid>
            <Grid item alignItems='center'>
{ !props.showprogress ?<Button color='primary' variant='outlined' className='client-action-Button' onClick={()=>{handleSignIn(props.signIn)}}> Sign In</Button>:''}
            </Grid>
          </Grid>
         
          
        
        </Box>
      </Modal>
    </div>
  );
 })

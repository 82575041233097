import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  royot:{
    [theme.breakpoints.down('xs')]:{
         padding:2,
    }
  },
 
}));

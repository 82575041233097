import './store.css';
import { useEffect,useState,useCallback } from 'react';
import { LocationSearching, MailOutline, PhoneAndroid, Publish,Edit,Money } from '@material-ui/icons';
import CheckboxList from './checkList/CheckboxList'

import { Select,MenuItem,Grid,Button} from '@mui/material'
import axios ,{patch,post}from 'axios';
import {Daabia,store} from '../../daabia/daabia';
import ContentTitleBar from '../../components/contentitlebar/ContentTitleBar'
export default function Store({setMessage,setOpenSnackBar}) {
 
     const [_store,setStore]=useState(JSON.parse(localStorage.getItem('store')));
          new Daabia(_store._id)
              /* analytics.salesById( '617830f1391d5d0cd0c7af41' );*/
     const [name,setName]=useState(_store.name)
     const [email,setEmail]=useState(_store.email)
     const [description,setDescription]=useState(_store.description)
     const [phone,setPhone]=useState(_store.phone)
     const [country,setCountry]=useState(_store.country)
     const [state,setState]=useState(_store.state)
     const [city,setCity]=useState(_store.city)
     const [currency,setCurrency]=useState(_store.currency);
    
     const [groupCategories,setGroupCategories]=useState([])
     const [storeUpdated,setStoreUpdated]=useState(false);
     const [onStoreImageChange,setOnstoreImageChange]=useState(false);
     const [image,setImage]=useState(null);
     const[isCountriesloaded,setIscountriesLoaded]=useState(false);
     const [isGroupLoaded,setIsGroupLoaded]=useState(false); 
     const [countries,setCountries]=useState([]);
     const [currencyLabel,setCurrencyLabel]=useState('');
     const [iamge_url]=useState(_store.image.secure_url);
     const userObj = JSON.parse(localStorage.getItem('user'));
     const [checkedCategories,setCheckedCategories] =useState([]);
     const [showEditCat,setShowEditCat]=useState(false);
     const onEditClick =()=>{
      setShowEditCat(!showEditCat)
     }
     //var   user = userObj.user;
     function onFileInputChange(e) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            document.getElementById('store-image').src = e.target.result
            document.getElementById('store-show-img').src = e.target.result
            setOnstoreImageChange(!onStoreImageChange);
         console.log(file)
         setImage(file);
        };

        try {
            reader.readAsDataURL(file)

        } catch (error) {
            console.log({ readAsDataURLError: error })
        }
    }
    const onCurrencyChange =(e)=>{
      const index=e.target.value;
      //const cid=countries[index].id;
      const name=countries[index].currency;
      console.log(`${countries[index].currency} ${countries[index].id} : currency ${countries[index].currency}`)
      setCurrency(index);
      setCurrencyLabel(name)
    
    }
    const findIndexOfObject = (array,nameofItem)=>{
      // Find Radagast
     return array.findIndex(function (item) {
      console.log(item.currency === nameofItem)
   return item.currency === nameofItem;
 });
}
    
   /*  const handleOnCategoryChange = (e)=>{
      var id=e.target.value
      setCategoryId(id)
      const cat=groupCategories.filter((item)=>item._id===id)
      console.log(cat[0])
      setCategory(cat[0]);
      console.log(cat[0]._id);
    } */
    const handleUpdate=(e)=>{
      e.preventDefault();
      updateStore().then((response)=>{
          if(response.status===200){
            setStore(response.data)
               setName(response.data.name)
               setDescription(response.data.description);
               setPhone(response.data.phone);
               setEmail(response.data.email);
               setCountry(response.data.country);
               setState(response.data.state);
               setCity(response.data.city);
               
              setMessage({body:'update successfully',severity:'success'})
              setOpenSnackBar(true)
              /***/if (onStoreImageChange){

                  UploadStoreImage(image).then((response) => {
                      if (response.data.status===200){
                        setMessage({body:'store image updated ',severity:'success'})
                        setOpenSnackBar(true) 
                      }
                      //addToast(exampleToast(response.data.message));
                    })
              } 
              setStoreUpdated(!storeUpdated);
             
         }else{
          setMessage({body:'update unsuccessful',severity:'error'})
          setOpenSnackBar(true)
          
         }
         

      });
}

     const handleUpdateStore= async(e)=>{

      const body={
        storeid:_store._id,
        name:name,
        email:email,
        phone:phone,
        description:description,
        country:country,
        state:state,
        city:city,
        checkedCategories:checkedCategories,
        currency:currencyLabel,
        image:image,
        auth_token:userObj.auth_token
      }
        e.preventDefault();
       await store.edit(body).then((response)=>{
            if(response.status===200){
              setStore(response.data)
                 setName(response.data.name)
                 setDescription(response.data.description);
                 setPhone(response.data.phone);
                 setEmail(response.data.email);
                 setCountry(response.data.country);
                 setState(response.data.state);
                 setCity(response.data.city);
                 
                 setMessage({body:'update successful',severity:'success'})
                 setOpenSnackBar(true)

                  if (onStoreImageChange){

                    UploadStoreImage(image).then((response) => {
                        if (response.data.status===200){
                          setMessage({body:'store image update successful',severity:'success'})
                           setOpenSnackBar(true)
                        }
                        //addToast(exampleToast(response.data.message));
                      })
                } 
                setStoreUpdated(!storeUpdated);
               
           }else{
            setMessage({body:'update  unsuccessfully',severity:'success'})
            setOpenSnackBar(true)
           }
           

        });
  }

  const UploadStoreImage = (file) => {
    //const url = process.env.STORE_URL;

    const formData = new FormData();
      formData.append('public_id',iamge_url);
      formData.append('image', file);
    

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MGQ4N2FkODExNzM0MTU3NmMwOGYzYjciLCJpYXQiOjE2MjQ5OTg3MDd9.d8KtF6Q2KFQrvDQNOPzVN_4S8Iaz47vH5GHSm2cI0Eg',
      }

    }
    return post(`${process.env.REACT_APP_SERVER_URL}/stores/updateImage/${_store._id}`, formData, config)
  }

  const updateStore =()=>{
     
    const url = `${process.env.REACT_APP_SERVER_URL}/stores/${_store._id}`;
   
    const formData=new FormData();
          formData.append("storeId",_store._id);
          formData.append("name",name);
          formData.append("email",email);
          formData.append("phone",phone);
          formData.append("description",description);
          formData.append("country",country);
          formData.append("state",state);
          formData.append("city",city);
          formData.append("image",image);
          formData.append("categories",JSON.stringify(checkedCategories));

    const body={
             storeId:_store._id,
             name:name,
             email:email,
             phone:phone,
             description:description,
             country:country,
             state:state,
             city:city,
             currency:currencyLabel,
             categories:checkedCategories

    }
    const config = {
        headers: {
            'Content-Type':'application/json',
            'auth-token':userObj.auth_token,
          },
         
          
    }
    return patch(url, body,config)
  
  };
  const getCountries =  useCallback( async() => {

    // if (!mountedRef.current) return null ;

   if (!isCountriesloaded){
    try{
      const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
      await axios.get(url).then((response)=>{
            setCountries(response.data.countries)
            let currencyCode = findIndexOfObject(response.data.countries,'GHS')
            setCurrency(currencyCode)      
        })
            
      
      // console.log(mountedRef.current)
      
      }catch(err){
          console.log(err)
      }
   }
  },[isCountriesloaded]);
    useEffect(()=>{


        const fetchStore = async () => { //get specific Store
         try {
          const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/stores/${_store._id}`);
          const data = await res.json();
          return data;
         } catch (error) {
           console.log({fetch_store_message:error})
         }
        }
        const getStore = async () => {
            try {
             const storefromServer = await fetchStore();
             //console.log(storefromServer)
            if (storefromServer.length >0){
                setStore(storefromServer)

            }
        
            } catch (error) {
              console.log({message:error})
            }
           };
           const handlegetGroup =async ()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
    
           await axios.get(url).then((response)=>{
                //console.log(response.data)
                setGroupCategories(response.data);
                setCheckedCategories(_store.categories)

            })  
        }
       
        if (!isGroupLoaded){
            handlegetGroup();
        }
      

        getStore();
          if(!isCountriesloaded){
            getCountries()
          }
          return ()=>{
            setIscountriesLoaded(true)
            setIsGroupLoaded(true)
          }            

    },[getCountries, isCountriesloaded, isGroupLoaded, _store]);
  return (
    <div className="store">
      {/* <div className="updateStoreTitle">
            <div className="storeTitleContainer">
                <h1 className="storeTitle">Edit Store</h1>
                <Link to="/dashboard/newStore">
                 <button className="storeAddButton">Create</button>
              </Link>
            </div>
       </div> */}
        <ContentTitleBar store={null} pagetitle='Edit Store' buttons={ [{title:'Create Store',link:`/dashboard/newStore`}]}/>

       <Grid container justifyContent='space-between' marginTop={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="storeShow">
                    <div className="storeShowTop">
                        <img src={`${_store.image.secure_url}` } id="store-show-img" alt="" className="storeShowImg" />
                       <div className="storeShowTopTitle">
                           <span className="storeShowstorename">{name}</span>
                           <span className="storeShowstoreTitle">{description}</span>
                       </div>
                       
                    </div>

                    <div className="storeShowBottom">
                  
                        <span className="storeShowTitle">Contact Details</span>
                        <div className="storeShowInfo">
                             <PhoneAndroid className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{phone}</span>
                        </div>
                        <div className="storeShowInfo">
                             <MailOutline className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{email}</span>
                        </div>
                         {
                          countries!==undefined ? <div className="storeShowInfo">
                          <Money className="storeShowIcon"/>
                     <span className="storeShowInfoTitle">{`${currency
}`}</span>
                     </div>:''
                         }

                        <div className="storeShowInfo">
                             <LocationSearching className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{`${country} , ${state} , ${city}`}</span>
                        </div>

                        {_store.categories.length >0 ? <><span className="storeShowTitle">Categories</span>
                        <div className="">
                          <ul>
                           {
                            _store.categories.map((category,index)=>{
                              return(
                                    <li>                                <span className="storeShowInfoTitle">{category.name}</span>
                                    </li>
                              )
                            })
                           }</ul>
                          </div></> :''}
                       </div>
                </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8} >
                  <div className="storeUpdate"> 
                    <span className="storeUpdateTitle" >Edit</span>
                    <form  className="storeUpdateForm" onSubmit={handleUpdateStore}>
                      <Grid container justifyContent='space-between' >
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                             
                        <div className="storeUpdateItem">
                            <label>StoreName</label>
                            <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="storeUpdateInput"/>
                        </div> 
                        
                        <div className="storeUpdateItem">
                            <label>Email</label>
                            <input type="text" className="storeUpdateInput" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Description</label>
                            <textarea rows="3" value={description}  onChange={(e)=>{setDescription(e.target.value)}} className="storeUpdateTextarea"/>
                        </div>
                       
                        <div className="storeUpdateItem">
                            <label>Phone</label>
                            <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Country</label>
                            <input type="text" value={country} onChange={(e)=>{setCountry(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>State</label>
                            <input type="text" value={state} onChange={(e)=>{setState(e.target.value)}}className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>City</label>
                            <input type="text" value={city} onChange={(e)=>{setCity(e.target.value)}} className="storeUpdateInput"/>
                        </div>

                        <div className="storeUpdateSelectItem">
                            <label>Currency</label>
                            <Select value={currency} name="currency" autoWidth required onChange={onCurrencyChange}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.currency_name}`} </MenuItem>
                   ))}
                   </Select> 
                        </div>

                        <div className="storeUpdateItemCheckBox">
                         <Grid container justifyContent='space-between' margintop={5} marginbottom={5}>
                          <Grid item><label>Category</label></Grid> <Grid item onClick={()=>{onEditClick()}}><Edit/></Grid>
                         </Grid>
                     {showEditCat ? <CheckboxList data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />:''
}
                    {/* <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
                    {  
                       groupCategories.map((group,index)=>{
                       return <option key={index} value={group._id}>{group.name}</option>
                      })
                      }
               
                    </select> */}
                            </div>  

                      
                 </Grid>

                     <Grid item xs={12} sm={12} md={6} lg={6} >
                       <Grid container justifyContent='flex-end' alignItems='flex-end' direction='column' className='updateactionContainer'>
                           <Grid item border={0}>
                              <Grid container justifyContent='space-between' direction='column' spacing={2}>
                          <Grid item>
                           <div className="storeUpdateUpload">
                            <img src={`${_store.image.secure_url}`} alt="" className="storeUpdateImg" id="store-image" />
                            <label htmlFor="file"> <Publish className="storeUpdateIcon"/> </label>
                             <input type="file" id="file" onChange={onFileInputChange} style={{display:"none"}}/>
                         </div>
                          </Grid>
                          <Grid item>
                          <Button type='submit' variant='contained' color='primary' fullWidth >Update</Button>
                           

                          </Grid>
                      </Grid>
                              </Grid>
                       </Grid>
                  
                         </Grid>
                      </Grid>
                      
                    </form>
                </div>
                  </Grid>
            </Grid>
           {/*  <div className="storeContainer">
                <div className="storeShow">
                    <div className="storeShowTop">
                        <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/stores/${store.image[0].filename}` } id="store-show-img" alt="" className="storeShowImg" />
                       <div className="storeShowTopTitle">
                           <span className="storeShowstorename">{name}</span>
                           <span className="storeShowstoreTitle">{description}</span>
                       </div>
                       
                    </div>

                    <div className="storeShowBottom">
                  
                        <span className="storeShowTitle">Contact Details</span>
                        <div className="storeShowInfo">
                             <PhoneAndroid className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{phone}</span>
                        </div>
                        <div className="storeShowInfo">
                             <MailOutline className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{email}</span>
                        </div>
                        <div className="storeShowInfo">
                             <LocationSearching className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{`${country} , ${state} , ${city}`}</span>
                        </div>

                        {store.categories.length >0 ? <><span className="storeShowTitle">Categories</span>
                        <div className="">
                          <ul>
                           {
                            store.categories.map((category,index)=>{
                              return(
                                    <li>                                <span className="storeShowInfoTitle">{category.name}</span>
                                    </li>
                              )
                            })
                           }</ul>
                          </div></> :''}
                       </div>
                </div>
                <div className="storeUpdate"> 
                    <span className="storeUpdateTitle" >Edit</span>
                    <form  className="storeUpdateForm" onSubmit={handleUpdate}>
                    <div className="storeUpdateLeft">
                        <div className="storeUpdateItem">
                            <label>StoreName</label>
                            <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="storeUpdateInput"/>
                        </div> 
                        
                        <div className="storeUpdateItem">
                            <label>Email</label>
                            <input type="text" className="storeUpdateInput" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Description</label>
                            <textarea rows="3" value={description}  onChange={(e)=>{setDescription(e.target.value)}} className="storeUpdateTextarea"/>
                        </div>
                       
                        <div className="storeUpdateItem">
                            <label>Phone</label>
                            <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Country</label>
                            <input type="text" value={country} onChange={(e)=>{setCountry(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>State</label>
                            <input type="text" value={state} onChange={(e)=>{setState(e.target.value)}}className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>City</label>
                            <input type="text" value={city} onChange={(e)=>{setCity(e.target.value)}} className="storeUpdateInput"/>
                        </div>

                        <div className="storeUpdateSelectItem">
                            <label>Currency</label>
                            <Select value={currency} name="currency" autoWidth required onChange={onCurrencyChange}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.currency_name}`} </MenuItem>
                   ))}
                   </Select> 
                        </div>

                        <div className="storeUpdateItemCheckBox">
                    <label>Category</label>
                      <CheckboxList data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />

                    <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
                    {  
                       groupCategories.map((group,index)=>{
                       return <option key={index} value={group._id}>{group.name}</option>
                      })
                      }
               
                    </select> 
                </div>  

                        </div> 
                    <div className="storeUpdateRight">
                        <div className="storeUpdateUpload">
                            <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/stores/${store.image[0].filename}`} alt="" className="storeUpdateImg" id="store-image" />
                            <label htmlFor="file"> <Publish className="storeUpdateIcon"/> </label>
                                <input type="file" id="file" onChange={onFileInputChange} style={{display:"none"}}/>
                       </div>
                       <button className="storeUpDateButton">Update</button>
                    </div>
                    </form>
                </div>
            </div> */}
      </div>
  )
}



import React,{useState,useEffect} from 'react'
import {Card,CardMedia,CardActions,Typography,IconButton,Grid} from '@mui/material';

import useStyles from './styles';
import { AddShoppingCart,FavoriteBorderOutlined,Favorite } from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import { blue, orange,red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { truncateString,formarttoPiCurrency } from '../../../utils/Utils';
import ReactCountryFlag from "react-country-flag"
import { myCld } from '../../../cloudinaryImage'



const RelatedProduct = ({product,key,onAddToCart,onUpdateLikes,favorites,setOpenModal,setIsproductLoaded,setIsCommentsLoaded,setIsStoreLoaded,setStoreId,setStore,setProductId,setShowProgress}) => {
    const classes=useStyles();
    const history=useHistory();
    const[like,setLike]=useState(false);
    const loggedin =localStorage.getItem('loggedin');
    let img = myCld.image(`${product.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy')
       // console.log(img.toURL())
    const theme = createTheme({
  
        palette: {
          primary:{
            main:"#ff9800",
          /*   main:"#6d1b7b", */
          },
            secondary: {
                main:red[500],
                contrastText:'#fff'
            },
            warning:{
              main:red[100],
              contrastText:'#fff'
            }
          },
  });  

  const handleAddtoFavorites =(product)=>{
    if (loggedin==='true'){
      
    setLike(!like);
    onUpdateLikes(product._id,product.storeId);
    }else{
      setOpenModal(true)
    }
  }
 
    const handleProductOnClick=(productid,storeid)=>{
      setShowProgress(true)
        setIsproductLoaded(false) 
        setIsCommentsLoaded(false) 
        setIsStoreLoaded(false)
         setProductId(productid)
         if (storeid){
        
        history.push(`/proceedcheckout?storeId=${storeid}&productId=${productid}`)
       
       
         }
     
    }
    // eslint-disable-next-line no-unused-vars
    const Buynow= ({product})=>(
      <Grid container justifyContent={'flex-end'} onClick=
                  {()=>{onAddToCart(product,0,'buynow')}}>
        <Grid item ><Typography variant="body2" className={classes.buynow} fontSize={11}  fontWeight={600}>
    Buy now
</Typography></Grid>
         {/*<Grid item >
           <AddShoppingCart  className={classes.icon}/>   
                  </Grid>*/}
      </Grid>
   )
     const FreeTag= ()=>(
        <Grid container justifyContent={'flex-start'} spacing={1}>
          <Grid item xs={8}><Typography variant="span"  fontSize={11}>
      shipping :
  </Typography></Grid>
          <Grid item xs={3}><Typography variant="span"  fontSize={12} className={classes.freeTag}>
      free
  </Typography></Grid>
        </Grid>
     )
      useEffect(() => {
       // console.log(favorites.includes(product._id))
           if (favorites.includes(product._id,0)){
             setLike(true)
           }else{
             setLike(false)
           }
      },[favorites,setLike,product])
  return ( 
    <ThemeProvider theme={theme}>

   {product ? <div style={{width:'120px'}} key={key}>
    <Card className={classes.root} >
      {
       <Grid container justifyContent='flex-start' spacing={0.5} style={{position:'absolute',margin:'4px 3px'}}>
       {/*  {product.countryScope.map((item,i)=>{
            return <Grid item ><ReactCountryFlag countryCode={item.countryCode} svg
                
            style={{
             width: '1em',
             height: '1em',
         }}/></Grid>
       })
       
       
       } */}
        </Grid>
      }
           <CardMedia className={classes.rpmedia}  image={img.toURL()} title={product.name} onClick={()=>{handleProductOnClick(product._id,product.storeId)}}/>
         {/*   <AdvancedImage className={classes.media} cldImg={img}/> */}
             <Grid container justifyContent={'space-between'} flexDirection='column' padding={0.5} onClick={()=>{handleProductOnClick(product._id,product.storeId)}}>
              <Grid item>
              <Typography sx={{fontSize:'0.6rem'}} color='GrayText' variant='span' fontSize={12} fontWeight={600} >
                        {truncateString(product.name,18)}
                    </Typography>
              </Grid>

              <Grid item>
              <Typography sx={{fontSize:'0.6rem'}} variant="span" fontSize={14} className={classes.price}>
                        {`${formarttoPiCurrency(product.price)}`}
                    </Typography>

              </Grid>

               <Grid container justifyContent='space-between'>
             {/*   <Grid item>
              {product.shippingFees >= 0.00000001 ? <Typography variant="span" fontSize={11} className={classes.pricei}>
                        {`shipping :${formarttoPiCurrency(product.shippingFees)}`}
                    </Typography>:<FreeTag/>}
                </Grid> */}
                <Grid item>

                {/*  <Grid container justifyContent='flex-start' spacing={0.5}>
                 {product.countryScope.map((item,i)=>{
                     return <Grid item ><ReactCountryFlag countryCode={item.countryCode} svg
                         
                     style={{
                      width: '1em',
                      height: '1em',
                  }}/></Grid>
                })  }
                 </Grid> */}
                         
        
                </Grid>
               </Grid>
             </Grid> 
              {/* 
             </Grid>
             
              <CardContent  className={classes.cardContent}>
                  <div className={classes.cardContentSub}>
                   
                    
                   
                  </div>
                  <div className={classes.description}>
                  <Typography  dangerouslySetInnerHTML={{__html:truncateString(product.description,54)}} variant="body2" color="textSecondary" noWrap={false}/>  
                  </div>
              </CardContent> */}
            {/*  <CardActions disableSpacing padding={0} className={classes.cardActions}>
               <IconButton aria-label="Add to Favourite" onClick={()=>{ handleAddtoFavorites(product)}}>
                   { like ===true ?  <Favorite className={classes.icon} />: <FavoriteBorderOutlined className={classes.icon} /> }
                  </IconButton> 
                  <IconButton>
                      <Buynow  product={product} />
                  </IconButton>
                 
                  
                 {/*  <IconButton aria-label="Add to Cart" onClick=
                  {()=>{onAddToCart(product,0,'addtocart')}}>
                    <AddShoppingCart  className={classes.icon}/>  
                  </IconButton> 

              </CardActions>*/}
        </Card>
    </div>:''}
    </ThemeProvider>
  )
}

export default RelatedProduct


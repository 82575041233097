/* eslint-disable no-unused-vars */

import { React, useState,useEffect,useCallback} from 'react';
import './new-Product.css';
import Specs from './specs/Specs'
import { Grid,Button,Typography,Select,MenuItem,TextField} from '@mui/material';
import {ExpandMore,ExpandLess} from '@mui/icons-material'
import thumbnail from './ImagesContainer/thumbnail-wide.png';
import {useHistory} from 'react-router-dom';
import ImageGallery from './imageGallery/ImageGallery';
// eslint-disable-next-line no-unused-vars
import { EditorState , convertToRaw} from 'draft-js';
import CheckboxList from '../../newStore/checkList/CheckboxList'
import draftToHtml from 'draftjs-to-html'
import {StoreCard} from '../../../components'
import {Countries} from '../../../assets/jsonData/countries'
import ContentTitleBar from '../../../components/contentitlebar/ContentTitleBar';
/* import TextEditor from './textEditor/TextEditor';
import useStyles from './style' */
import {axiosClient,config} from '../../../services/axiosClient'

export default function NewProduct({store,balance,productCategories,onFormSubmit,setMessage,setOpenSnackBar,accessToken,handlegetTransactions,page,perPage,setPageper}) {

  const getCountry= (data) =>{
    let country = {}
   if(data.length >0){
    for(var i=0;i<data.length;i++){
      const obj ={name: data[i].name, countryCode: data[i].iso2}
      
      if (JSON.stringify(obj)===JSON.stringify(store.country)){
           country=data[i] //assign whole country object

      }
    }
  } 
    return country
  
}
    const [productImages,setProductImages]=useState([]);
    const [base64EncodedImage,/* setBase64EncodedImage */]=useState([]);
    const [digitalProductUrl, setdigitalProductUrl] = useState('no');

    const [name, setName] = useState('');
    const [description, setDescription] = useState();
    const [shippingarea,setShippingarea]=useState()
    const [price, setPrice] = useState('');
    const [shippingFees,setShippingFees] =useState();
    const [stock,setStock]=useState();
    const [active,setActive]=useState('');
    const [specification,setSpecification] = useState('none');
    const [productCategory,setProductCategory]=useState({});
    const [selectedCategory,setSelectedCategory]=useState()
    const [showSpecification,setShowSpeicification]=useState(true);
    const [showDigitalProductFileInput,setShowDigitalProductFileInput] = useState(false);
    const [checkedCategories,setCheckedCategories] =useState([getCountry(Countries)])
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState([]);
    const [clearImages,setClearImages]=useState(false);
    const [showChecklist,setShowCheckList]=useState(false)
    //retrieves specs variables eg colors  ans size
    const [colors,setColors]=useState([]);
    const [sizes,setSizes]=useState([]);
    //const [editorstate,setEditorState]=useState(EditorState.createEmpty());
    //const[category]=useState(store.category);
    const[categories]=useState(store.categories.length > 0 ? store.categories :[]);
    const[filStoreProtCategories,setFilStoreProCategories]=useState([]);
    const[isfiltered,setIsfiltered]=useState(false)
    const[countryScope,setCountryScope]=useState([store.country])
    const[isTransactionsLoaded,setIsTransactionsLoaded]=useState(false)
         // let query=QueryParams();
         const  history = useHistory()

         const filterProduct =useCallback(()=>{
          var arr=[];

           for (var i=0;i < categories.length;i++){
                console.log(i)
            for(var j=0;j < productCategories.length;j++ ){

                  if (categories[i]._id===productCategories[j].categoryGroupId){
                    /* console.log(productCategories[j].categoryGroupId)*/
                     
                    arr.push(productCategories[j])
                  }
              }
           }
           setFilStoreProCategories(arr)
           //console.log(arr)
         },[categories, productCategories])

        const onSpecificationChange = (e) => {
        setSpecification(e.target.value)
        if(e.target.value==="no"){
          setShowSpeicification(false);
        }else{
          setShowSpeicification(true)
          setShowDigitalProductFileInput(false) //Specification is set SPECIFIED ,hide Digital product file input
        }
        console.log(e.target.value)
      }
      const onProductSpecsChange = (e) => {
        const val =e.target.value
        setSelectedCategory(val)
        const prodcat=productCategories.filter((item)=>item._id===val)
        setProductCategory(prodcat[0])
        console.log(prodcat)
      }
      const onstockChange =(e)=>{
          setStock(e.target.value)
      }
      const onshippingFeesChange =(e)=>{
        setShippingFees(e.target.value !== '' ?e.target.value : 0)  
      }
      const onAddProductCLick =()=>{
        //  let storeid=document.getElementById("storeselect").value
         // console.log(storeid)
          //setStoreId(storeid)
        
      }

      const getCountryNames = (data) =>{
          let countries = [store.country] //iniatia a default country 
         if(data.length >0){
          for(var i=0;i<data.length;i++){ //add countries which are not included already
              const obj ={name: data[i].name, countryCode: data[i].iso2}
              
            
          
            const found = countries.some(item => item.name !== obj.name); // check if country not found
               console.log(found)
            if(found){
              countries.push(obj)

            }

          }
         
         // console.log(countries)

          setCountryScope((countryscope) => [...countryscope,...countries]);

         // console.log(countryScope)
         return countries

         }else{
           // setCountryScope(countryScope.slice(0,1))
            // console.log(countryScope)
           return countries
         }
      }

     const handleOnFormsubmit =(e,clearFields,colors,sizes,name,price,shippingFees,categoryId,description,specification,digitalProductUrl,storeid,stock,active,base64EncodedImage,productCategory)=>{
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
      }
      
      
      e.preventDefault()// Stop form default submit
        
      let countryscope = getCountryNames(checkedCategories); //get country names from selected countries
      console.log(countryscope)

      
      var body={
        name:name,
        price:price,
        shippingFees:shippingFees,
        description:description,
        specification:specification,
        digital_product_url:digitalProductUrl,
        storeId:storeid,
        stock:stock,
        active:active,
        color:colors,
        size:sizes,
        encodedimages:base64EncodedImage,
        category:productCategory,
        countryScope:countryscope,
        shippingArea:shippingarea
       
      }
      var userObj=JSON.parse(localStorage.getItem('user'))
     // var auth_token=userObj.auth_token

       if (productImages.length >= 3){
          onFormSubmit(e,clearFields,body,accessToken);

       }else{
        setMessage({body:'add three images of product',severity:'error'})
        setOpenSnackBar(true)
       }
     }
      const clearImagesonSubmit=(images)=>{
         if (clearImages) {
           images=[];
         }
      }
      

      const clearFields = () =>{
        setName('')
        setDescription('')
        setStock(0)
        setActive('no')
        setPrice('0')
        setShippingFees('0')
        setSelectedCategory('')
        setProductCategory({})
       // setEditorState(EditorState.createEmpty());
        document.getElementById("product-image0").src=thumbnail;
        document.getElementById("product-image1").src=thumbnail;
        document.getElementById("product-image2").src=thumbnail;
        
      // setShowSpeicification(false);
       setShowDigitalProductFileInput(false);
       setProductImages([]);
       setCountryScope([]);
       setCheckedCategories([])
       setClearImages(true);

     
      }
    
    
      const onDigitalProductUrlChange = (e) => {
        setdigitalProductUrl(e.target.value);
      }
      const handleNavigate =(location)=>{
        history.push(location)
    } 
      const onDigitalProuctInputChange = (e)=>{
            if(e.target.value==="no"){
             setShowDigitalProductFileInput(false)
            }else{
             setShowDigitalProductFileInput(true)
            }
            console.log(e.target.value)
      }
        
      const handleImages=(Images)=>{
        let tmp=[];
       Images.map((item)=>{
          tmp.push(item)
         // console.log(tmp);
          return null
       })
       //setProductImages(tmp);
    }
   
    const onEditorStateChange = (editorstate)=>{
      //console.log(editorstate.getCurrentContent())
      const htmlcontent=draftToHtml(convertToRaw(editorstate.getCurrentContent()))
     // setEditorState(editorstate)
      setDescription(htmlcontent);
      
  }
 
    
    
    
        //Get color and size input Values from input els, @elId is element Id 
      const getInputValues = (elId) => {
        var inputValues = [];
    
        var inputEl = document.getElementsByClassName(elId);
    
        for (var i = 0; i < inputEl.length; i++) {
             inputValues.push(inputEl[i].value);
        }
         //console.log(inputValues)
        return inputValues;
      }

     /*  const onFormSubmit = (e) => {
       
        const form = e.currentTarget
        if (form.checkValidity() === false) {
          e.stopPropagation()
        }
        
        e.preventDefault()// Stop form default submit
        
            initiateAndCreateProduct().then((response) => {
              console.log(response.data);
             if (response.data.status===200){
              //window.location.reload();
               
                clearFields();
             }else if (response.data.status===400){ 

              Alert.error(response.data.message, {
                position: 'top-right',
                effect: 'jelly'
    
            });
           // history.go(0);
             }
            });
           
      }
     const initiateAndCreateProduct =()=>{
        
        const url = '${process.env.REACT_APP_SERVER_URL}/products/';
    
        console.log(colors);
        const formData = new FormData();
        //getInput values
       // let colors = getInputValues('color-specs');
        //let sizes  = getInputValues('size-specs');
    
        for (let i = 0; i < colors.length; i++) {
           if(colors[i]!==""){
            formData.append('color', colors[i]);
           }
        }
        for (let j = 0; j < sizes.length; j++) {
          if (sizes[j]!==""){
            formData.append('size', sizes[j]);
          }
        }
        formData.append('name', name);
        formData.append('price', price);
        formData.append('category',store.categoryId);
        formData.append('description', description);
        formData.append('specification', specification);
        formData.append('digital_product_url', digitalProductUrl);//append digital
        formData.append('storeId', store._id);
        formData.append('stock',stock);
        formData.append('active',active)
        console.log(JSON.stringify(formData));
     
        //append files to image to create an a file array
      
        for (var i = 0; i <= productImages.length; i++) {
          formData.append('image', productImages[i]);
          //console.log(productImages);
        }
    
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'auth-token':
              user.auth_token,
          },
        }
        return post(url, formData, config)
      
      };
      useEffect(()=>{
        const handlegetCategories =()=>{
          const storeCatGroupid=category._id;
          const url=`${process.env.REACT_APP_SERVER_URL}/category/${storeCatGroupid}`
  
           axiosClient.get(url,{},config).then((response)=>{
              console.log(response.data)
              setProductCategories(response.data);
          })  
      }
          if (!isProductCategoriesLoaded){
             handlegetCategories()
          }
          return ()=>{
            setIsProdCatsLoaded(true)
          }
      },[category._id, isProductCategoriesLoaded])
   */
     useEffect(()=>{
      //
      if (!isfiltered){
        filterProduct();
      }
       if (!isTransactionsLoaded){
        handlegetTransactions(page,perPage)
       }

      return ()=>{
       if (filStoreProtCategories.length>0){
        setIsfiltered(true)
       }
       setIsTransactionsLoaded(true)
      }
     },[filStoreProtCategories.length, filterProduct, handlegetTransactions, isTransactionsLoaded, isfiltered, page, perPage])

     const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
        const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,{},config).then((response)=>
              setCountries(response.data.countries)
             
        )
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
  
    useEffect(()=> {
   //  addresses.length >0 ? setShowAddresses(true):setShowAddresses(false);
     if (!isCountriesloaded) {getCountries();}
      return ()=>{
        setIscountriesLoaded(true)
       // mountedRef.current=false;
      };

    },[getCountries, isCountriesloaded]);
       
          
       
    return (
        <div className="client-newProduct">
         {/*  <div className='storeCurrencyLabel'>
           <span className="client-addproductStoreTitle" >{store.name}</span>
            <span>{` Local currency: ${store.currency}`}</span>
           </div> */}
           <StoreCard store={store} balance={balance}/>
           <ContentTitleBar store={store} pagetitle={'New Product'} buttons={ [{title:'Products',link:`/product-list?`}]}/>
           <Grid container justifyContent={'space-between'}   >
                  <Grid item padding={1} style={{backgroundColor:'var(--app-font-info)'}}>
                  <Typography variant="body1" style={{fontSize:'1rem'/*,padding:'3px',margin:'1px' */,color:'var(--app-secondary)'}}>We kindly request that you refrain from uploading products that include adult content, harassment, violence, drugs or personal images as product images.</Typography>
                 {/*  <Typography variant='body1' fontSize={'0.8rem'} color='#fff'>This test net,Stores Created at mainnet shall require veirfication before allowed to post product !!</Typography> */}
                </Grid>
     </Grid>
       

          <div className="addProductTitleContainer">
          {/*     <h2 className="client-addProductTitle">Add New Product </h2>
            
            
        <Link to={`/dashboard/products?storeId=${store._id}&storeName=${store.name}&categoryId=${store.categoryId}`}>
          <button className="ProductListButton">Products</button>
          </Link>
 
          </div> */}
           <div className='client-page-content'>
          
          <div className="addProductFormContainer">
          { store.status==='Verified' ?
           <form className="addProductForm" onSubmit={(e)=>{handleOnFormsubmit(e,clearFields,colors,sizes,name,price,shippingFees,store.categoryId,description,specification,digitalProductUrl,store._id,stock,active,base64EncodedImage,productCategory)}}>
           <Grid container justifyContent="space-around"  xs={12} sm={12} md={12} lg={12} spacing={1} padding={0}>
           <Grid item justifyContent="space-between" spacing={1} padding={0} xs={12} sm={12} md={6} lg={6}>
       
            <Grid item  xs={12} sm={12} md={12} lg={12}>
           <div className="client-addProductItem">
                <label>Name</label>
                <TextField type="text" variant='standard' required className='productameinput' value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Pie Apple"/>
                </div>
           
             </Grid>
             
               <Grid item  xs={12} sm={12} md={12} lg={12}>
             <div className="client-addProductItem description">
              <label htmlFor="validationTextarea">Description</label>
               {/*  <textarea id="description" name="description" rows="4"
         placeholder= "Describe the product you are selling" value={description}
         required onChange={(e)=>{setDescription(e.target.value)}}></textarea> */}

          <TextField  rows={3} required multiline fullWidth  placeholder= "Describe the product you are selling" variant='standard'  onChange={(e)=>       {setDescription(e.target.value)}} value ={description}/>
        
             {/*  <TextEditor onEditorStateChange={onEditorStateChange} editorstate={editorstate}/> */}
              <Grid item key={'product-gallery'} xs={12} sm={12} md={12} lg={12}>
           {/* <ImagesContainer handleImages={handleImages} onSubmit={onSubmit} setOnsubmit={setOnsubmit} clearImagesonSubmit={clearImagesonSubmit}/> */}
             <ImageGallery handleImages={handleImages} productImages={productImages} base64EncodedImage={base64EncodedImage}/>
            
          </Grid>
      
         </div>
       
        </Grid>

         </Grid>

         <Grid item justifyContent="space-between" xs={12} sm={12} md={4} lg={4}  padding={0}>
           <Grid container justifyContent='flex-start' spacing={1}>
  
           <Grid item  xs={4} sm={12} md={10} lg={10}>
             <div className="client-addProductItem">
            <label >Price</label>
           {/*   <input type="number"  placeholder="0.02000000" value={price} required onChange={(e)=>{setPrice(e.target.value)}} /> */}
             <TextField variant='standard'  type="number" name="name" placeholder="0.02000000" value={price} onChange={(e)=>{setPrice(e.target.value)}} required/>
         </div>
           
          </Grid>
          <Grid item  xs={5} sm={12} md={10} lg={10}>
          <div className="client-addProductItem">
             <label>Shipping fees</label>
            {/*  <input type="number"  placeholder="0.00000011" value={shippingFees} required onChange={(e)=>{onshippingFeesChange(e)}} /> */}
             <TextField variant='standard' type="number" name="name" placeholder="0.00000011" value={shippingFees} onChange={(e)=>{onshippingFeesChange(e)}} />
           </div>
           
             </Grid>
          
           
             <Grid item  xs={3} sm={12} md={10} lg={10}>
          <div className="client-addProductItem">
             <label>Stock</label>
             {/* <input type="number" placeholder="10" value={stock} required onChange={onstockChange} /> */}
             <TextField variant='standard' type="number" name="name" placeholder="5" value={stock} onChange={onstockChange} required/>
           </div>
           
             </Grid>
             
             <Grid item  xs={4} sm={12} md={10} lg={10}>
          
          <div className="client-addProductItem">
          <div className="client-addProductItem">
           <label htmlFor="validationCustom04">Specification</label>
           {/*   <select id="validationCustom04" value={specification} onChange={onSpecificationChange}>
             <option>yes</option>
             <option>no</option>
              </select>  */}
              <Select value={specification} variant='standard' name="specification"  required fullWidth onChange={onSpecificationChange}>
                   <MenuItem key={'active-no'} value='yes' >Yes </MenuItem>
                   <MenuItem key={'active-yes'} value='no' >No </MenuItem>

                   </Select> 
              
             </div>
           </div>
            </Grid>

             <Grid item  xs={5} sm={5} md={10} lg={10}>
          
            {/*  <div className="client-addProductItem">
           <label htmlFor="validationCustom06">Category</label>
          <select id="validationCustom06" value={selectedCategory} required onChange={onProductSpecsChange}>
             <option value={null}></option>
            {
              productCategories.map((cat,index)=>{
                return  <option value={cat._id} key={index}>{cat.name}</option>
              })
            }
            </select>
      </div> */}
      
     {filStoreProtCategories.length > 0 ? <div className="client-addProductItem">
           <label htmlFor="validationCustom06">Category</label>
         {/*  <select id="validationCustom06" value={selectedCategory} required onChange={onProductSpecsChange}>
             <option value={null}></option>
            {
               filStoreProtCategories.map((cat,index)=>{
                return  <option value={cat._id} key={index}>{cat.name}</option>
              })
            }
            </select> */}
            <Select value={selectedCategory} variant='standard' name="country"  required  onChange={onProductSpecsChange} fullWidth>
            <MenuItem  value={null}></MenuItem>

                    {filStoreProtCategories.map((cat,index)=>(
                       <MenuItem key={index} value={cat._id}>{cat.name}</MenuItem>
                   ))}
                   </Select>    
      </div>:''}
            
             </Grid>
              
             <Grid item  xs={3} sm={2} md={2} lg={2}>
             <label>Active</label>
       
             <Select value={active} variant='standard' name="active"  required fullWidth style={{padding:3}} onChange={(e)=>{setActive(e.target.value)}}>
                   <MenuItem key={'active-no'} value='yes' >Yes </MenuItem>
                   <MenuItem key={'active-yes'} value='no' >No </MenuItem>

                   </Select> 
                {/*  <select name="active" id="active" className="active" value={active} required onChange={(e)=>{setActive(e.target.value)}}>
              <option value=""></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
             </select> */}
             </Grid>
             <Grid item  xs={4} sm={4} md={10} lg={10}>
          
           <div className="client-addProductItem" >
          { /* !showSpecification ?:''   <>
         <label htmlFor="validationCustom05">Digital Product</label>
        
             <Select value={digitalProductUrl} variant='standard' name="digital_product_Url"  required fullWidth onChange={onDigitalProuctInputChange}>
                   <MenuItem key={'digPr-url-yes'} value='no' >No </MenuItem>
                   <MenuItem key={'digPr-url-no'} value='yes' >Yes </MenuItem>

                   </Select> 
             </> 
             
             */}
          </div>

             </Grid>
             <Grid item  xs={12} sm={12} md={10} lg={10}>
               <div className="client-addProductItem">
               
                 {showSpecification ? <><label>Specification</label><Specs setColors={setColors} setSizes={setSizes}/></>:<></>}
             </div>
             </Grid>

             <Grid item xs={12} sm={12} md={10} lg={10}>
          <div className="client-addProductItem">
            {/*   showDigitalProductFileInput ?   <div className="digital_product">
               <label>Google Drive /Amazon s3 Url File</label>
               <div className="client-addProductItem">
               <input type="text" id="digital-product-file" placeholder="https://drive.google.com/file/d/1PzOdYqBftPID4BNvUa3T_OzEBkzUBwDT/view?usp=drivesdk" onChange={onDigitalProductUrlChange} />
               </div>
               </div> :<></> */
           }
            </div>
          
             </Grid>
             <Grid item xs={12} sm={12} md={10} lg={10}>
               <div className="client-addProductItem">
               <Grid container justifyContent='space-between'>
                <Grid item >
                <label>Country of sale</label>
                </Grid>
               
                <Grid item >
                {showChecklist ?  <ExpandLess  onClick={()=>{/* setShowCheckList(!showChecklist) */}}/>: <ExpandMore onClick={()=>{/* setShowCheckList(!showChecklist) */}}/>}
              
                </Grid>
               </Grid>
               <Grid container >
               <Grid item >
                    {<Typography variant='body2' color='darkgrey' >
                      {
                        checkedCategories.map((item)=>{
                            return `,${item.name}`
                        })
                      }</Typography>}
                </Grid>
               </Grid>
                {
                   showChecklist ? <div className='checkList'>
                   <CheckboxList data={countries} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />
                 </div>:''
                }
                </div>
               </Grid>



               <Grid item xs={12} sm={12} md={10} lg={10}>
               <div className="client-addProductItem">
               <Grid container justifyContent='space-between'>
                <Grid item >
                <label>Shipping Area</label>
                </Grid>
               <Grid>
                <Typography variant='body2' sx={{fontSize:'0.70rem'}}>Type in cities or areas you are delivering withen your selected Country</Typography>
               </Grid>
               </Grid>
               <Grid container >
               <Grid item >
                    
                </Grid>
               </Grid>
                {
                   <TextField  rows={2} required multiline fullWidth  placeholder= "Tamale,Lagos,Hong Kong,New York" variant='standard'  onChange={(e)=>       {setShippingarea(e.target.value)}} value ={shippingarea}/>
                }
                </div>
               </Grid>

             <Grid item xs={12} sm={6} md={10} lg={10}>
               <div className="client-addProductItem">
              <Button className="addProducButton" fullWidth variant='outlined' color='primary' type="submit"  onClick={onAddProductCLick}>Publish</Button>

        </div>
             </Grid>
             {/* <Grid item key={'product-na'} xs={6} sm={6} md={6} lg={12}>
         
             </Grid> */}
          </Grid> 
           </Grid>
          
            </Grid>
       
{/* 
             <div className="productFormTop">
                  <div className="productFormTopItem">

               
             

         
       
      </div>
         <div className="productFormTopItem">
       
          
           </div>
        <div className="productFormTopItem">
     
       

          
        
         </div>
        </div> */}
           
            
           
      
        </form>: <Grid container marginLeft={'30%'} marginTop={'50%'} >
                 <Grid item  alignItems='center' justifyContent={'center'}>
                   <Grid container direction='column' rowSpacing={4} justifyContent={'space-around'} alignItems='center'>
                   <Grid item > Store not Verified , please contact <a style={{textDecoration:'none'}} href='/dashboard/help' >support</a>  for assitance</Grid>
                  {/* <Grid item ><Button variant='contained' size='small' onClick={()=>{handleNavigate('/dashboard/help')}}>Contact support</Button> 
                 </Grid>*/}
                   
                   </Grid>
                 </Grid> 
                 <Grid item xs={12} sm={12} md={8} lg={8}>
                 
                 </Grid>
           </Grid>}
        </div>
        </div>   
       </div>
       </div>     
    )
}

import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     root:{
         padding:' 0%',

     },
     content:{
        padding:' 5% 25%',
        [theme.breakpoints.down('xs')]:{
            /* marginBottom:'10vh', */
            padding:' 2% 5%',
           
      }

    },
    logo:{
        width:'100px',height:'100px',border:'1px solid darkgray'
    }
    
     
}))
import { Card, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { truncateString } from '../../utils/Utils'

const ProductList = ({products,handleSelectProduct,handlegetStore}) => {

    const [filteredProducts,setFileteredProducts]= useState(products);



    const onSearchFieldChange =(e)=>{
        const searchterms = e.target.value
        const results = products.filter(prd => prd.name.includes(searchterms));
            if (results.length >0 && searchterms !==''){
              setFileteredProducts(results)
            }else if (results.length ===0 && searchterms !==''){
              setFileteredProducts(results)
              if (results.length > 0){
                handlegetStore(results[0].storeId)
              }
            }
            else
            {
              setFileteredProducts(products)
              if (products.length > 0){
                handlegetStore(products[0].storeId)
              }
            }
      }
    const ReviewProduct =({product,index})=>{
        return(
           
                           <Card key={`pm-pro-${index}`} className='pm-product-card'>
                      <Grid container justifyContent='flex-start' alignItems='center' onClick={()=>{handleSelectProduct(product)}}> 
                       <Grid item xs ={7} padding={1}>
                          <div className="productListItem"  >
                     <img className="productListImg" src={`${product.image[0].url}`} alt=""/>
                    <Typography variant='caption' > {truncateString(product.name,20)}</Typography>
                 </div>
                       </Grid>
                       
                         <Grid item xs={5}>
                         <Typography variant='caption' fontSize={8.8}>{new Date(product.dateCreated).toISOString()}</Typography>
                         </Grid>
                         {/* <Grid item xs={2}>
                          <Grid container justifyContent='space-between' alignItems='center'>
                  
                       </Grid>
                         </Grid> */}

                        
                                        

                    </Grid>
                    </Card>
                   
        )
      }
  return (
    <Grid container justifyContent={'space-around'} direction={'column'}>
    <Grid item marginBottom={1}>
       <TextField type='text' variant='outlined'placeholder='Search product' color='primary' onChange={(e)=>{onSearchFieldChange(e)}} fullWidth />
     </Grid>
    <Grid item border={'1px solid darkgrey'} height={600} overflow={'scroll'}>

    {
           filteredProducts.length >0 ? 
           <Grid  container justifyContent={'space-between'}   padding={1}  spacing={1} >
        
              {
               filteredProducts.map((product,index)=>{
                 return(
                   <Grid item xs={12} > 
                    <ReviewProduct product={product} index={index}/>
                    </Grid>
                  )
               })
              }
           
          
             </Grid> :''}
    </Grid>
    </Grid>
  )
}

export default ProductList

import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({

    root:{
        width:'30vw',
        marginLeft:'35% ',
        [theme.breakpoints.down('xs')]:{
           width:'100vw',
           marginLeft:'0% ',

        },
    }
}));
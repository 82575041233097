/* import React, { useEffect, useRef } from 'react';

export default function Banner({ adkey, height, width }) {
  const banner = useRef(null);

  useEffect(() => {
    const atOptions = {
      key: adkey,
      format: 'iframe',
      height: height,
      width: width,
      params: {},
    };

    const loadScript = () => {
        console.log('script loaded')
      const conf = document.createElement('script')
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
      script.async = true;
      script.onload = () => {
        console.log('timer started ... ')

        if (banner.current && !banner.current.firstChild) {
            console.log('not first child')
            banner.current.append(conf)
            banner.current.append(script)
        }
      };

      if (banner.current) {
        console.log('current')
        banner.current.innerHTML = '';
        banner.current.appendChild(script);
        banner.current.append(script)

      }
    };

    loadScript();

    // Reload the script within 15 seconds
    const reloadTimeout = setTimeout(loadScript, 15000);

    return () => {
      clearTimeout(reloadTimeout);
    };
  }, [adkey, height, width]);

  return (
    <div
      style={{
        margin: '5px 2px',
        border: '1px solid #eeeeee',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        textAlign: 'center',
      }}
      ref={banner}
    ></div>
  );
}*/


 import { useEffect, useRef } from 'react'
export default function Banner({adkey,height,width}) {
    const banner = useRef(null)
   
    useEffect(() => { 
        const atOptions =  {
        key: adkey,
        format: 'iframe',
        height: height,
        width: width,
        params: {},
    }
   

    if (banner.current && !banner.current.firstChild) {
         //console.log('showing ads')
        const conf = document.createElement('script')
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
   
        banner.current.append(conf)
        banner.current.append(script)
    }
}, [adkey, banner, height, width])

    return <div style={{ 
        margin: '5px 2px',
        border: '1px solid #eeeeee',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        textAlign: 'center',
      }} ref={banner}></div>
}


import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     root:{
         marginBottom:0,
     },
     title:{
       fontSize:9,
     },
     content:{
        padding:'3% 10%',
        borderRadius:1,
        fontSize:8,
        [theme.breakpoints.down('xs')]:{
            /* marginBottom:'10vh', */
            padding:' 1% 0%' ,
           
      }
    },
    contactformcontainer:{
        padding: '5% 15%',
        border:'0px solid var(--app-font-darkgray)',
        [theme.breakpoints.down('xs')]:{
            /* marginBottom:'10vh', */
            padding:' 1%',
           
      }
    },
    contactform:{
        padding:15,
        border:'1px solid var(--app-font-darkgray)',
        [theme.breakpoints.down('xs')]:{
            /* marginBottom:'10vh', */
            padding:' 2% 3%',
           
      }
    }
     
}))
import { useState,useEffect,useCallback } from 'react';
import './newstore.css'
import {Publish} from '@material-ui/icons';
import { Grid,Select,MenuItem,TextField,Button} from '@mui/material';
import CheckboxList from './checkList/CheckboxList'
import thumbnail from '../../../assets/icons/thumbnail-wide.png';

import ContentTitleBar from '../../components/contentitlebar/ContentTitleBar';
import axios from 'axios';
export default function NewStore({stores,setStores,handleSubmitStore}) {
    const [storeImage, setStoreImage] = useState([]);
    const userObj = JSON.parse(localStorage.getItem('user'));
    var   user = userObj.user;
    const [storeName, setStoreName] = useState('');
    const [email, setEmail] = useState('');
    //const [country,setCountry]=useState('');
    //const [state, setState] = useState('');
    //const [city,setCity]=useState('');
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState([]);
    const [country,setCountry]=useState(0);
    const [countrylabel,setCountryLabel]=useState('');
    const [states,setStates]=useState([]);
    const [state,setState]=useState('');
    const [statelabel,setStateLabel]=useState('');
    const [cities,setCities]=useState([]);
    const [city,setCity]=useState('');
    const [citylabel,setCityLabel]=useState('');
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [category, setCategory] = useState({});
    const [categoryid, setCategoryId] = useState('');
    const [currency,setCurrency] = useState('');
    const [currencyLabel,setCurrencyLabel]=useState('')
    const [isGroupLoaded,setIsGroupLoaded]=useState(false); 
    const [groupCategories,setGroupCategories]=useState([])
    const [checkedCategories,setCheckedCategories] =useState([])
   // const [ghPostGps, setGhPostGps] = useState('');


    const clearfields =()=>{
      setStoreName('');
      setDescription('')
      setPhone('');
      setEmail('');
      setCountry('');
      setState('');
      setCity('');
      setCategory('');
      setCurrency('');
      document.getElementById("storeimg").src=thumbnail;
      setCheckedCategories([]);
    }
   /* 
    const handleOnCategoryChange = (e)=>{
      var id=e.target.value
      setCategoryId(id)
      const cat=groupCategories.filter((item)=>item._id===id)
      console.log(cat[0])
      setCategory(cat[0]);
      console.log(cat[0]._id);
    } */
    const onSubmitStore =(e)=>{
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
      }  
      e.preventDefault()
      const body={
        name :storeName,
        description : description,
        country: countrylabel,
        state : statelabel,
        city : citylabel,
        email :email,
        phone : phone,
        userId: user._id,
        categories :checkedCategories,
        currency: currencyLabel,
        encodedimages: storeImage,
        status:"Unverified"
      } 

      handleSubmitStore(body,clearfields)
    }
    
  
    //Handle on formInput valu change change 
    const onFileChange = (e) => {
  
      var file = e.target.files[0]
       var fr= new FileReader();
           fr.onload=(e)=>{
              document.getElementById('storeimg').src=e.target.result
             setStoreImage(e.target.result);

           }
          try {
            fr.readAsDataURL(file)
          } catch (error) {
            
          }
    }
    
            
  const onCurrencyChange =(e)=>{
    const index=e.target.value;
    //const cid=countries[index].id;
    const name=countries[index].currency;
    console.log(`${countries[index].currency} ${countries[index].id} : currency ${countries[index].currency}`)
    setCurrency(index);
    setCurrencyLabel(name)
  
  }
  const onCountryChange =(e)=>{
    const index=e.target.value;
    const cid=countries[index].id;
    const name=countries[index].name;
    console.log(`${countries[index].name} ${countries[index].id}`)
    setCountry(index);
    setCountryLabel(name)
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  }
  const onStateChange=(e)=>{
    const index=e.target.value;
    const sid=states[index].id;
    const name=states[index].name;
    console.log(`${states[index].name} ${states[index].id}`)
    setState(index);
    setStateLabel(name)
    getCities(sid);
  }
  const onInputStateChange=(e)=>{
    console.log(e.target.value)
    const name=e.target.value;
    setStateLabel(name);
  }
  const onInputCityChange=(e)=>{

    const name=e.target.value;
    setCityLabel(name);
  }
  const onCityChange=(e)=>{
    const index=e.target.value;
    //const cid=cities[index].id;
    const name=cities[index].name;
    console.log(`${cities[index].name} ${cities[index].id}`)
    setCity(index);
    setCityLabel(name)
  }
 
  const getStates= async (cid)=>{
    const url=`${process.env.REACT_APP_SERVER_URL}/states/${cid}`;
    await axios.get(url).then((response)=>{
           console.log(response.data.states)
          setStates(response.data.states);

    })
 }
 const getCities= async (sid)=>{
  const url=`${process.env.REACT_APP_SERVER_URL}/cities/${sid}`;
  await axios.get(url).then((response)=>{
        console.log(response.data.cities)
        setCities(response.data.cities);

  })
}
   const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
        const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axios.get(url).then((response)=>
              setCountries(response.data.countries)
             
        )
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
    useEffect(()=>{
      const handlegetGroup =()=>{
          const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
  
           axios.get(url).then((response)=>{
              //console.log(response.data)
              setGroupCategories(response.data);
          })  
      }
     
      if (!isGroupLoaded){
          handlegetGroup();
      }
      if(!isCountriesloaded){
              getCountries();
      }

    
      return ()=>{
          setIsGroupLoaded(true);
          setIscountriesLoaded(true)

      }
  })
  return (
    <div className="newstore">
             {/* <div className="addnewStoreTitleContainer">
                 <h1 className="addNewStoreTitle">Add New Store</h1>
                 <Link to="/dashboard/stores">
                 <button className="storeListButton">Stores</button>
              </Link>
             </div> */}
             <ContentTitleBar store={null} pagetitle='Add New Store' buttons={ [{title:'Stores',link:`/dashboard/stores`}]}/>
             
          <div className="newStoreFormContainer">
                 <form action="" className="newStoreForm" onSubmit={onSubmitStore}>
                <Grid container justifyContent='space-between'>
                  <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Grid container justifyContent='space-between' direction='column'>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                <div className="newStoreFormItem">
                    <label>Name</label>
                    <input type="text" name="name" placeholder="Naeate Computers" value={storeName} onChange={(e)=>{setStoreName(e.target.value)}} required/>
                </div>
            
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                 <div className="newStoreFormItem">
                    <label>Description</label>
                    <textarea type="text" name="description" rows="5" placeholder="What are the products are you selling " value={description} onChange={(e)=>{setDescription(e.target.value)}} required/>
                </div> 
                </Grid>{/*
                <Grid item xs={12} sm={12} md={6} lg={6}>
               <div className="newStoreFormItem">
                    <label>Store Category</label>
                    <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
              {  
               groupCategories.map((group,index)=>{
                return <option key={index} value={group._id}>{group.name}</option>
               })
            
              
              }
               
                    </select>
                </div> 
                </Grid>*/}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem checkList">
                   <CheckboxList data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />

                </div>  {/**/}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem">
                    <label>Store Phone</label>
                    <input type="phone" maximum={13} name="email" placeholder="" value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem">
                    <label>Store Email</label>
                    <input type="email" name="email" required placeholder=""value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem">
                    <label>Country</label>
                    <Select value={country} name="country"  required  onChange={onCountryChange} fullWidth>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{c.name}</MenuItem>
                   ))}
                   </Select>       
                </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem">
                    <label>State/Region</label>
                   <>{
                    states.length >0 ? <Select value={state}  onChange={onStateChange} required fullWidth >
                    {states.map((s,index)=>(
                       <MenuItem key={s.id} value={index}>
                            {s.name}
                       </MenuItem>
                   ))}
                   </Select>: <TextField   label='State' onChange={onInputStateChange} fullWidth />
                    }
                  </>
                   
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="newStoreFormItem">
                <label>City</label>
                   {cities.length > 0 ?  <Select value={city}  fullWidth onChange={onCityChange} required >
                   {cities.map((s,index)=>(
                       <MenuItem key={s.id} value={index}>
                            {s.name}
                       </MenuItem>
                   ))}
                   </Select> :  <TextField  label='City' onChange={onInputCityChange} fullWidth/>
                  }
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  
                <div className="newStoreFormItem">
                    <label>currency</label>
                   {/*  <select type="text" name="currency" value={currency} onChange={(e)=>{setCurrency(e.target.value)}} required >
                    <option value="none" required></option>
                <option value="USD">USD Dollar $</option>
                <option value="GHS">Ghana ¢</option>
                <option value="EUR">Euro €</option>
                <option value="CAD">Canada $</option>
                <option value="GBP">UK £</option>
                <option value="JPY">Japanese Yen £</option>
                <option value="CNY">Chenese Yuan £</option>
                    </select> */}
                    <Select value={currency} name="currency"  required fullWidth onChange={onCurrencyChange}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.currency_name}`} </MenuItem>
                   ))}
                   </Select> 
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="newStoreUpload">
                            <img src={thumbnail} alt="" className="newStoreUploadImg"  id="storeimg"/>
                            <label htmlFor="file"> <Publish className="newStoreUploadIcon"/>{/* Click to upload store logo */}</label>
                                <input type="file" id="file" style={{display:"none"}}  onChange={onFileChange}/>
                       </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button type='submit' variant='contained' color='primary' fullWidth>Create Store</Button>
                  </Grid>
               
               
                     

              
                      </Grid>
                  </Grid>

                 {/*  <Grid item xs={12} sm={12} md={6} lg={6}>

                  </Grid> */}
                </Grid>
             
            </form>
        </div> 
    </div>
  )
}

import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
      display:'flex',
      flexDirection:'row',
      maxWidth:'100%',
      height:'180px',
      marginTop:10
    
    },
    price:{
       color:'red'
    },
  
    media:{
        objectFit:'cover',
        height:80,
        width:'100%', 
        borderRadius:'4px',
        boxShadow:'0px 0px 15px -10px rgb(0,0,0) !important',
      '-webkit-box-shadow':'0px 0px 15px -10px rgb(0,0,0)',
        [theme.breakpoints.down('xs')]:{
          height:70,
          width:'100%'
        }
    },
    layout: {
      marginTop: '5%',
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
        width: '98%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        padding:0,
      },
    },
    orderDetails:{
      margin:"10px 0px",
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '0px 0px',
        border:'0px solid !important',
        padding:0,
        
      },
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
      width:'100%',
    },
    step:{
      [theme.breakpoints.down('xs')]: {
        padding:'0px !important'    
      },
    },
    iconContainer: { // define styles for icon container
     
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(0.6)',
        padding:'0px',
        margin:'0px !important'
      },
    },
     step_label_root: {
      fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px !important',
      padding:'0px !important'
    },
  }
   
}))
import { Fragment} from "react";
import { Grid,Checkbox,Box,Typography} from "@mui/material";

const CheckboxList = ({data,checkedCategories,setCheckedCategories}) => {

 /*  const handleChange1 = (isChecked) => {
    if (isChecked)
      return setCheckedCategories(
        data.map((item) => item)
      );
    else setCheckedCategories([]);
  } */

  const handleChange2 = (isChecked, item) => {
    const index = findIndexOfObject(checkedCategories,item.name);
          //console.log(index," "+ isChecked )
    // The checked value is altered before the state changes for some reason is not a trully controlled component
    // So the next conditions are INVERTED.

    if (isChecked) return setCheckedCategories((state) => [...state, item]);

    if (!isChecked && index > -1)
      return setCheckedCategories((state) => {
        state.splice(index, 1);
        console.log(state)
        return JSON.parse(JSON.stringify(state)); // Here's the trick => React does not update the f* state array changes even with the spread operator, the reference is still the same.
      });
  };

  const findIndexOfObject = (array,nameofItem)=>{
     // Find Radagast
return array.findIndex(function (item) {
	return item.name === nameofItem;
});

  
    
  }
  
  const itemIsinclude = (array,item)=>{
     var bol=false;
    for (var i=0;i < array.length;i++){

      if (item.name === array[i].name){
        bol= true
      }
    }
    //console.log(bol)
    return bol
  }
  return (
    <Fragment>
      {/* Parent 
     <Button onClick={()=>{handleChange1(false)}}>refresh</Button>
      <Checkbox
        checked={checkedCategories.length === data.length}
        indeterminate={
          checkedCategories.length !== data.length &&
          checkedCategories.length > 0
        }
        onChange={(event) => handleChange1(event.target.checked)}
      />*/}

      {/* Childrens */}
      <Box sx={{ display: "flex", flexDirection: "column",width:'100%',border:'1px solid gray' , height: '300px',
    overflow:' scroll'}} >
        {checkedCategories &&
          data.map((item) => (
            <Grid container  justifyContent={'flex-start'} alignItems='center'>
              <Grid item > <Checkbox
              key={item._id}
              checked={itemIsinclude(checkedCategories,item)} 
              onChange={(event) =>
                handleChange2(event.target.checked, item)
              }
              inputProps={{ "aria-label": "controlled" }}
            /></Grid>
            <Grid item >
             <Typography>{item.name}</Typography>

            </Grid>
             </Grid>
          ))}
      </Box>

{/*       <h3>ID's: {JSON.stringify(checkedCategories)}</h3>
 */}    </Fragment>
  );
};

export default CheckboxList;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    width: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'snow',
    borderRadius:'4px',
    boxShadow: 0,
    p: 4,
    padding:20,
    [theme.breakpoints.up('xs')]:{
        top: '50%',
        width: '35%',
      }, [theme.breakpoints.down('xs')]:{
        top: '50%',
        width: '70%',
        padding:10,

      },
      message:{
        fontSize:12
      }


    
  }
}))
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  root:{
   /*  backgroundColor:'#2196f3', ,*/
    backgroundImage:'linear-gradient(to bottom right, var(--app-primary), snow)'
   /*  backgroundImage:'linear-gradient(to bottom right,darkgrey,snow )'*/,
 
    borderRadius:'2px',
    padding:'2px',
    border: `0px solid #2196f3`,
    [theme.breakpoints.down('xs')]:{
          /* marginBottom:'10vh', */
         padding:0,
    }
  },
 storeImg:{
    width: '100%',
    border:'2px solid var(--app-primary)',
    backgroundColor:'var(--app-primary)',
    borderRadius: '50%',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    aspectRatio: 1/1,
  }
  
}));

import { useState,useEffect,useCallback } from 'react';
import './newstore.css'
import {Publish} from '@material-ui/icons';
import { Grid,Select,MenuItem,TextField,Typography,Button,InputAdornment, CircularProgress} from '@mui/material';
import {ExpandLess,ExpandMore} from '@material-ui/icons'
import CheckboxList from './checkList/CheckboxList'
import storeThumbnail from '../../assets/icons/online_store_96px.png';
import {axiosClient,config} from '../../services/axiosClient';
import imageCompression from 'browser-image-compression';
import SearchableSelect from '../checkoutform/SearchableSelect';
import SearchableCurrencySelect from './SearchableCurrencySelect'
import {Countries} from '../../assets/jsonData/countries'

export default function NewStore({stores,setStores,handleSubmitStore, setOpenSnackBar, setMessage}) {
    const [storeImage, setStoreImage] = useState();
    const userObj = JSON.parse(localStorage.getItem('user'));
    var   user = userObj.user;
    const [storeName, setStoreName] = useState('');
    const [storeTag, setStoreTag] = useState('');
    const [email, setEmail] = useState('');
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState([]);
   
    const [states,setStates]=useState([]);
    const [state,setState]=useState('');
    const [statelabel,setStateLabel]=useState('');
    const [cities,setCities]=useState([]);
    const [city,setCity]=useState('');
    const [citylabel,setCityLabel]=useState('');
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneCountry,setPhoneCountry] =useState('');
    const [countryPhoneCode,setCountryPhoneCode]=useState('+233')
    const [countryObj,setCountryObj]=useState({})

    const [currencyLabel,setCurrencyLabel]=useState('')
    const [isGroupLoaded,setIsGroupLoaded]=useState(false); 
    const [groupCategories,setGroupCategories]=useState([])
    const [checkedCategories,setCheckedCategories] =useState([])
    const [showCategoryList,setShowCategoryList]=useState(true)
    const [isTagAvailable,setIsTagAvailable]=useState(false)
    const [checking,setChecking]=useState(false)


    const clearfields =()=>{
      setStoreName('');
      setDescription('')
      setPhone('');
      setEmail('');
      setCountryObj({});
      setState('');
      setCity('');
      setCheckedCategories([]);
      setCurrencyLabel('');
      document.getElementById("storeimg").src=storeThumbnail;
      setCheckedCategories([]);
    }
   
    /* const handleOnCategoryChange = (e)=>{
      var id=e.target.value
      setCategoryId(id)
      const cat=groupCategories.filter((item)=>item._id===id)
      console.log(cat[0])
      setCategory(cat[0]);
      console.log(cat[0]._id);
    } */
    const onSubmitStore =(e)=>{
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
      }  
      e.preventDefault()

      const body={
        name :storeName,
         tag : storeTag,
        description : description,
        country: countryObj,
        state : statelabel,
        city : citylabel,
        email :email,
        phone : phoneCountry,
        userId: user._id,
        categories :checkedCategories,
        currency: currencyLabel,
        encodedimages: storeImage,
        status:"Verified"
      } 
      if(isTagAvailable){
        setMessage({body:'tag allready taken choose different name' ,severity:'info'})
        setOpenSnackBar(true)
      }else{
        if(checkedCategories.length >0){
          
         handleSubmitStore(body,clearfields)
      }else{
        setMessage({body:'Select atleast one category for your store' ,severity:'info'})
        setOpenSnackBar(true)
      }
      }
      
              

       if (storeImage){
       }else{
        console.log('please select image')
       }
     
    }
    /* const handleSubmit = (event) => {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.stopPropagation()
      }   
      
      event.preventDefault()
        
      UploadImageAndCreateStore(storeImage, user).then((response) => {
        console.log(response.data.store);
        if (response.status===200){
           localStorage.setItem('stores', JSON.stringify(response.data.stores));
          setStores(response.data.stores)

         
        


        clearfields();
        }else{
        
        }
        //addToast(exampleToast(response.data.message));
      })
    }
   */
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } 

    //Handle on formInput valu change change 
   async function onFileChange(e){
    var base64EncImage='';

      var file = e.target.files[0]
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true
      }  
       var fr= new FileReader();
         
            const compressedFile = await imageCompression(file, options);
            blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
             base64EncImage=base64image
            })
           fr.onload=(e)=>{
          
              document.getElementById('storeimg').src=e.target.result
              setStoreImage(base64EncImage);

           }
          try {
            fr.readAsDataURL(file)
          } catch (error) {
            
          }
    }
    
    const checkTag = async(tag) =>{
      const url=`${process.env.REACT_APP_SERVER_URL}/stores/tag/${tag}`
           setChecking(true)
     await  axiosClient.get(url,config).then((response)=>{
           setChecking(false)
          console.log(response.data.store)
            response.data.store === null ? setIsTagAvailable(false): setIsTagAvailable(true)
            
           })  
    }
  
   const onSrchCurrencyChange =(e)=>{
    const country=e.target.value;
    //const cid=countries[index].id;
    const name=country.currency;
    //console.log(`${JSON.stringify(countries[index])} ${countries[index].id} : currency ${countries[index].currency}`)
    setCurrencyLabel(name)
  
  }   
  /* const onCurrencyChange =(e)=>{
    const index=e.target.value;
    //const cid=countries[index].id;
    const name=countries[index].currency;
    
    console.log(`${JSON.stringify(countries[index])} ${countries[index].id} : currency ${countries[index].currency}`)
    setCurrencyLabel(name)
  
  } */
  const onStoreNameChange = (e)=>{
    var name = e.target.value
    setStoreName(name)
    var tag = name.replace(/ /g, '')
     setStoreTag(tag.toLowerCase()) 

     checkTag(tag.toLowerCase())
  }

  const onStoreTagChange = (e)=>{
    var name = e.target.value
    var tag = name.replace(/ /g, '')
     console.log(tag.toLowerCase())
     setStoreTag(tag.toLowerCase()) 
     checkTag(tag.toLowerCase())
  }
  const onPhoneChange =(e)=>{
    setPhone(e.target.value)
    setPhoneCountry(`${countryPhoneCode}${e.target.value}`)
   console.log(`${countryPhoneCode}${e.target.value}`)
}
 /*  const onCountryChange =(e)=>{
    const index=e.target.value;
    const cid=countries[index].id;
    //const name=countries[index].name;
    const countryObject={
          name:countries[index].name,
          countryCode:countries[index].iso2
    }
    
    console.log(countryObject)
    setCountryObj(countryObject)
    setCountryPhoneCode(`+${countries[index].phone_code}`)
    console.log(`${countries[index].name} ${countries[index].id}`)
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  } */

  const onSrchCountryChange =(e)=>{
    console.log(e.target)
    const country=e.target.value;
    const cid=country.id;
    //const name=country.name;
    const countryObject={
      name:country.name,
      countryCode:country.iso2
    }   
    setCountryObj(countryObject)

    setCountryPhoneCode(`+${country.phone_code}`)
    console.log(`${country.name} ${country.id}`)
   
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  }
  const onStateChange=(e)=>{
    const index=e.target.value;
    const sid=states[index].id;
    const name=states[index].name;
    console.log(`${states[index].name} ${states[index].id}`)
    setState(index);
    setStateLabel(name)
    getCities(sid);
  }
  const onInputStateChange=(e)=>{
    console.log(e.target.value)
    const name=e.target.value;
    setStateLabel(name);
  }
  const onInputCityChange=(e)=>{

    const name=e.target.value;
    setCityLabel(name);
  }
  const onCityChange=(e)=>{
    const index=e.target.value;
    //const cid=cities[index].id;
    const name=cities[index].name;
    console.log(`${cities[index].name} ${cities[index].id}`)
    setCity(index);
    setCityLabel(name)
  }
 
  const getStates= async (cid)=>{
        // const mstates= States.filter((s)=>s.country_id===cid)
    const url=`${process.env.REACT_APP_SERVER_URL}/states/${cid}`;
    await axiosClient.get(url,{},config).then((response)=>{
           console.log(response.data.states)
          setStates(response.data.states);

    })
 }
 const getCities= async (sid)=>{
        //const mstates= States.filter((s)=>s.country_id===cid)
  //setCities(response.data.cities);
  const url=`${process.env.REACT_APP_SERVER_URL}/cities/${sid}`;
  await axiosClient.get(url,{},config).then((response)=>{
        console.log(response.data.cities)
        setCities(response.data.cities);

  })
}
   const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
        setCountries(Countries)
       /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,{},config).then((response)=>
              setCountries(Countries)
             
        ) */
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
    
    useEffect(()=>{
    /*   function blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
    }
const getImageFromStorage = async () => {

    try {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/s3/Dalir23.png`, {
          responseType: 'blob',
        })
        .then((response) => {
          console.log(response)
          blobToDataURL(response.data, function (dataurl) {
          setImgFromStorage(dataurl); 
          });
        });
    } catch (err) {
      console.error(err);
    }
  }; */
      const handlegetGroup =()=>{
          const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
  
           axiosClient.get(url,config).then((response)=>{
              //console.log(response.data)
              setGroupCategories(response.data);
          })  
      }
     
      if (!isGroupLoaded){
          handlegetGroup();
          //getImageFromStorage()
      }
      if(!isCountriesloaded){
              getCountries();
      }


    
      return ()=>{
          setIsGroupLoaded(true);
          setIscountriesLoaded(true)

      }
  })
  return (
    <div className="newstore"> 
       
       <div className="addnewStoreTitleContainer">
              <Grid container justifyContent='space-between' alignItems={'center'} direction='column' spacing={0}  >
                <Grid item >
                 <Typography variant='h5'>New Store</Typography>
                </Grid>
                <Grid item >
                 <Typography variant='body2' color='GrayText'>Add store to start selling </Typography>
                </Grid>

              </Grid>
                {/*  <Link to="/dashboard/stores">
                 <button className="storeListButton">Stores</button>
              </Link> */}
             </div>
       <div className='client-page-content'>
          
      
          <div className="client-newStoreFormContainer">
                 <form action="" className="newStoreForm" onSubmit={onSubmitStore}>
                <Grid container justifyContent='space-between'>
                  <Grid item xs={12} sm={12} md={8} lg={8} >
                      <Grid container justifyContent='space-between' direction='column'>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                <div className="newStoreFormItem-column">
                    <label>Name</label>
                    <TextField variant='standard' type="text" name="name" placeholder="Naeate Computers" value={storeName} onChange={(e)=>{onStoreNameChange(e)}} required fullWidth/>
                </div>
            
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                <div className="newStoreFormItem-column">
                    <label>Tag</label>
                    <TextField variant='standard' type="text" name="tag" placeholder="naeatecomputers" value={storeTag} onChange={(e)=>{onStoreTagChange(e)}} required fullWidth={false}/>
                     <Grid container justifyContent={'flex-start'}>
                        <Grid item>
                        <Grid container justifyContent={'flex-start'} spacing={0} marginTop={1}>
                      <Grid item>   <Typography variant='body2'>{`https://daabiamall.com/store/`}</Typography></Grid>
                      <Grid item>   <Typography variant='body2' color={isTagAvailable ?'red' : 'green'}>{`${storeTag}`}</Typography></Grid>
                    </Grid>
                        </Grid>
                        <Grid item >
                          <Grid justifyContent={'center'} alignItems={'center'}>
                            <Grid item>
                            {checking ?  <CircularProgress  variant='indeterminate' color ='primary' size={14} />:''}
                            </Grid>
                          </Grid>
                        </Grid>

                     </Grid>
                    
                </div>
                  
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                 <div className="newStoreFormItem-column">
                    <label>Description</label>
                    <TextField variant='standard' multiline={true} minRows={2}  maxRows={3} type="text" name="description" rows="5" placeholder="Describe the products are you selling " value={description} onChange={(e)=>{setDescription(e.target.value)}} required fullWidth/>
                </div> 
                </Grid>{/**/}
                <Grid item xs={12} sm={12} md={6} lg={6}>
               {/*  <div className="newStoreFormItem">
                    <label>Store Category</label>
                    <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
              {  
               groupCategories.map((group,index)=>{
                return <option key={index} value={group._id}>{group.name}</option>
               })
            
              
              }
               
                    </select>
                </div>  */} 
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="newStoreFormItem-column ">
                  <Grid container justifyContent='space-between'>
                   <Grid item > <Typography>Select Categories for your Store</Typography> </Grid>
                   <Grid item >
                     {
                      showCategoryList ? <ExpandLess onClick={()=>{setShowCategoryList(!showCategoryList)}}/> : <ExpandMore onClick={()=>{setShowCategoryList(!showCategoryList)}}/>
                     }
                   </Grid>

                  </Grid>
                      {
                        showCategoryList ?  <div className='checkList'>
                        <CheckboxList required={true}  data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />
                       </div>
                  :''    
                      }  

                </div> 
                </Grid>
      
                    <Grid item xs={12} sm={12}>    
                <div className="newStoreFormItem">
                    <label>Country:</label>
                  {/*   <Select value={country} variant='standard' name="country"  required  onChange={onCountryChange} fullWidth>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{c.name}</MenuItem>
                   ))}
                   </Select>  */}
                    <SearchableSelect required  allOptions={countries} onChange={onSrchCountryChange} Label='Country'/>     
                </div>
                
                </Grid>
                <Grid item xs={12} sm={12}>    
                <div className="newStoreFormItem">
                    <label>Currency:</label>
                
                    {/* <Select value={currency} variant='standard' name="currency"  required fullWidth onChange={onCurrencyChange}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.name}`} </MenuItem>
                   ))}
                   </Select>  */}
                   <SearchableCurrencySelect  allOptions={countries} onChange={onSrchCurrencyChange} Label='Currency'/>
                </div>
               
                </Grid>

                  
               <Grid item xs={12} sm={12}>    
               <div className="newStoreFormItem">
                    <label>Email:</label>
                    <TextField variant='standard' type="email" name="email" required placeholder=""value={email} onChange={(e)=>{setEmail(e.target.value)}} fullWidth/>
                </div>
                </Grid>
                <Grid item xs={12} sm={12}>    
                <div className="newStoreFormItem">
                    <label>Phone:</label>
                   

                    <TextField variant='standard' type="number" maximum={13} name="phone" placeholder="" value={phone} required onChange={(e)=>{onPhoneChange(e)}}  InputProps={ {
              startAdornment: <InputAdornment position="start">{countryPhoneCode}</InputAdornment>
            }} fullWidth/>
                </div>
                
                </Grid>

                    
            
                <Grid item xs={12} sm={12}>    
                 <div className="newStoreFormItem">
                    <label>State/Region:</label>
                   <>{
                    states.length >0 ? <Select value={state} variant='standard'  onChange={onStateChange} required fullWidth >
                    {states.map((s,index)=>(
                       <MenuItem key={s.id} value={index}>
                            {s.name}
                       </MenuItem>
                   ))}
                   </Select>: <TextField variant='standard'  label='State' onChange={onInputStateChange} fullWidth />
                    }
                  </>
                   
                </div>
                </Grid>
                <Grid item xs={12} sm={12}>    
                    <div className="newStoreFormItem">
                <label>City:</label>
                   {cities.length > 0 ?  <Select value={city} variant='standard' fullWidth onChange={onCityChange} required >
                   {cities.map((s,index)=>(
                       <MenuItem key={s.id} value={index}>
                            {s.name}
                       </MenuItem>
                   ))}
                   </Select> :  <TextField variant='standard' label='City' onChange={onInputCityChange} fullWidth/>
                  }
                </div>
                   </Grid>
                  
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="newStoreUpload">
                            <img src={storeThumbnail} alt="" className="newStoreUploadImg"  id="storeimg"/>
                            <label htmlFor="file"> <Publish className="newStoreUploadIcon"/>{/* Click to upload store logo */}</label>
                                <input type="file" id="file" style={{display:"none"}}  onChange={onFileChange}/>
                       </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} marginBottom={2}>
                  <Button type='submit' variant='outlined' color='primary' fullWidth>Create Store</Button>
                  </Grid>
              
                      </Grid>
                  </Grid>

                 {/*  <Grid item xs={12} sm={12} md={6} lg={6}>

                  </Grid> */}
                </Grid>
             
            </form>
        </div> 
       </div>
    </div>
  )
}

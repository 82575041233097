/* eslint-disable no-unused-vars */
import  React,{useState,useEffect} from 'react';
import {Link, useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Store from '@mui/icons-material/Store';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Person from '@mui/icons-material/Person';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


export default function MenuAppBar({handleMenuOnClick,devicetype}) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [imagefilename,setImageFilename]=useState('');
  const [userId,setuserId]=useState(null)
  const [loggedin,setLoggedIn]=useState(JSON.parse(localStorage.getItem('loggedin')));
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
   console.log(devicetype)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const history=useHistory();
  const imgonLoadError=(e)=>{
    try{
      e.target.onerror = null; 
      e.target.src = 'imgAvatar'
    }catch(error){
      console.log({error:error});
    }
  }
 
    const handleLogout=()=>{
       setAnchorEl(null);
      // setAuth(false)
       localStorage.setItem('loggedin',false);
       localStorage.removeItem('user')
       localStorage.removeItem('stores')
       history.push('/dashboard/login');
    
      
    }
    
    useEffect(()=>{
      if (history.location.pathname==="/dashboard/login"||history.location.pathname==="/dashboard/signup"){
        localStorage.setItem('loggedin',false);
        setLoggedIn(false)
      }
        var user =localStorage.getItem('user');
         //console.log("user "+user)
         if (user!==null){
           if (localStorage.getItem('loggedin'!==false)){
             try{
              loggedin ? setImageFilename(user.image[0].filename) : setImageFilename('');
              loggedin ? setuserId(user._id):setImageFilename('');
              }catch(err){ 
                console.log({error:err})
              }

           }


         }else{
              history.push('/dashboard/login'); 
         }
    
     
    },[user,loggedin,history])
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
             <MenuIcon onClick={(e)=>{handleMenuOnClick(e) }}/> {/* {devicetype !=='mobile'?:''} */}
           

          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
         { `${process.env.REACT_APP_WEBSITE_NAME}`}
          </Typography>
          {loggedin && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <React.Fragment >
         <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 12,
              height: 32,
              bgcolor:'red',
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Link className="topbarLink" to={{pathname:`/dashboard/user/_id=${userId}`,search:`user=${JSON.stringify(user)}`}}>
        <MenuItem onClick={handleClose} className="topbarLink"><ListItemIcon> <Person fontSize="small" /> </ListItemIcon>Account</MenuItem></Link>
        <Link className="topbarLink" to={{pathname:`/dashboard/stores`}}>
        <MenuItem onClick={handleClose}><ListItemIcon><Store/></ListItemIcon>Stores</MenuItem>
        </Link>
        <Divider/>
      {/*<MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>*/}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      </React.Fragment>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

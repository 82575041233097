import React,{useState,useEffect} from 'react'
import {Grid,Paper,Stepper,Step,StepLabel,Typography,CircularProgress,Divider,Button} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import useStyles from './styles';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import Medialinks from '../../medialinks/Medialinks'
const steps =['Shipping address','Payment details'];

const CheckOut = ({cart,user,order,onCaptureCheckout,error,addresses,setAddresses,handleDeleteAddress,showAddresses,setShowAddresses,setAddaddress,checkInCompletePayments,medialinks,showAds}) => {
    const [activeStep,setActiveStep]=useState(0);
    const [ischeckAddress,setCheckadress]= useState(false);
    const [shippingData,setShippingData]=useState({});
    const [address,setAddress]= useState(addresses.length>0 ? addresses[0]:{});
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('a');
    const[paymentMethod,setPaymentMethod]=useState('');
    const[iscompletePaymentCheck,setisIncompletePaymentCheck]=useState(false);
    
    /* const updateShowAddress = () => setShowAddresses( addresses.length > 0 ?true :false)
 */
    const classes = useStyles();
  //  const history = useHistory();
    const nextStep = () =>{ setActiveStep((prevActiveStep) => prevActiveStep + 1)}
    const backStep = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1)}


    const handleonRadioButtonChanged = (event,alphabets) => {
      var value=event.target.value  
      
      var index=alphabets.indexOf(value);
      var addr=addresses[index];
      setSelectedValue(event.target.value);
      setAddress(addr)
             console.log(addr);

    };

    const handleonPaymentMethodChanged = (event,alphabets) => {
      var value=event.target.value  
      
      var index=alphabets.indexOf(value);
      var addr=addresses[index];
      setSelectedPaymentMethod(event.target.value);
     /*  setAddress(addr) */
             console.log(value);
            setPaymentMethod(value)
    };
    
    

     const next = (data) =>{
            setShippingData(data);
            nextStep();
     };
  let Confirmation = () => (order.customer ? (
    <>
      <div className={classes.confirmation}>
        <Typography variant="body1">Thank you for your purchase , {order.customer.firstname} {/* {order.customer.lastname} */} { `):`}</Typography>

       
       
        <Typography variant="subtitle2">Order ref: {order.shipping.orderNumber}</Typography> 
         <Divider className={classes.divider} />
        <Typography variant="body1" style={{fontSize:'0.8rem',padding:'2px',margin:'1px',color:'var(--app-secondary)',backgroundColor:'var(--app-font-info)'}}>Please tap on orders to confirm recieved of your order when your order is shipped, note ! you will have to recieve the real package before confirming in mainnet</Typography>
      </div>
      <br />
      <>
             <Typography variant='body2' className='recents-products-title' >Follow our social media links for more updates </Typography>
          {  medialinks ?  <Medialinks medialinks={medialinks} />:''}
             </>
      <Grid container justifyContent='space-between'>
            <Grid item > <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
            </Grid>
            <Grid item > <Button component={Link} variant="outlined" type="button" to="/cart">Cart</Button>
            </Grid>
        </Grid>
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  ));

  if (error) {
    Confirmation = () => (
      <>
        <Typography variant="h5">Error: {error}</Typography>
        <br />
        <Grid container justifyContent='space-between'>
            <Grid item > <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
            </Grid>
            <Grid item > <Button component={Link} variant="outlined" type="button" to="/cart">Cart</Button>
            </Grid>
        </Grid>
      </>
    );
  }
     const Form = () => activeStep === 0
     ?<AddressForm cart={cart}  next={next} address={address}  setAddress={setAddress} addresses={addresses} showAddresses={showAddresses} setShowAddresses={setShowAddresses} handleonRadioButtonChanged={handleonRadioButtonChanged} selectedValue={selectedValue} handleDeleteAddress={handleDeleteAddress} setAddaddress={setAddaddress} showAds={showAds}/>
     :<PaymentForm shippingData={shippingData}   cart={cart} nextStep={nextStep} backStep={backStep}  onCaptureCheckout={onCaptureCheckout}  showAddresses={showAddresses} setShowAddresses={setShowAddresses} selectedPaymentMethod={selectedPaymentMethod} handleonPaymentMethodChanged={handleonPaymentMethodChanged} paymentMethod={paymentMethod} setActiveStep={setActiveStep}/>
    React.useEffect(()=>{
        if(!ischeckAddress){
          const user =  JSON.parse(localStorage.getItem('user')).user
            
          if (user.addresses.length >0){
           setShowAddresses(true)
           setAddresses(user.addresses)
           
          }
        }
       if (!iscompletePaymentCheck){
        checkInCompletePayments()
       }
        return ()=>{
          setCheckadress(true)
          setisIncompletePaymentCheck(true)
        }
    })
     
  return (
     <>
{/*      <div className={classes.toolbar}/>
 */}      <main className={classes.layout}>
          <Paper className={classes.paper}>
        <Typography variant="h4" align="center">Checkout</Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((step)=>(
                <Step key={step}>
                     <StepLabel>{step}</StepLabel>
                </Step>

            ))}
            
        </Stepper>
        {activeStep===steps.length? <Confirmation/>:cart&&<Form/>}
        </Paper>
      </main>
      
     </>
  )
}

export default CheckOut

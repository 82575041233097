import React,{useState} from 'react'
import useStyle from './styles';
import {Card,CardMedia,Grid} from '@mui/material';

import { myCld } from '../../../cloudinaryImage';

const ImageView = ({images}) => {
    const classes=useStyle();
    const[imageIndex,setImageIndex]=useState(0);
    const[selected,setselected]=useState(0)
   const onThumbNailCLick=(index)=>{
     setselected(index)
     setImageIndex(index)
     
   }

  const FilledImages =()=>(
     <Grid container justifyContent='space-between' direction={'column'} className={classes.viewcontainer}>
      <Grid item className={classes.thumbnailContainer}>
     
           <Grid  container justifyContent='space-between' className={classes.thumbnailList}>
            {
              images.map((img,index)=>(
                <Grid item >
                  <Card className={classes.root} key={index}>
                <CardMedia className={`${classes.mediaThumbnail} ${selected===index? classes.select:classes.disSelect} `} onClick={()=>{onThumbNailCLick(index)}}  image={myCld.image(`${img.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/* {`${img.secure_url}`} */ title={img.resource_type}/>
                </Card>
                </Grid>
              ))
            }
         
     </Grid>
      </Grid>
      <Grid item >
      
          <Card>
            <CardMedia className={classes.mediaPreview} id="img-preview"  image={myCld.image(`${images[imageIndex].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/* {images[imageIndex].secure_url} */ title={images[imageIndex].resource_type}/>
            </Card>
        
      </Grid>
     </Grid>
    
  )


 
  return (
    <div className={classes.container}>{
       images.length>0?  <FilledImages/>:'loading ...'
      }
   </div>
  )
}

export default ImageView

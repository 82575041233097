import { Fragment} from "react";
import { Grid,Checkbox,Box,Typography} from "@mui/material";
const CheckboxList = ({data,checkedCountries,setcheckedCountries,handleReset}) => {

  const handleChange2 = (isChecked, item) => {
    const index = findIndexOfObject(checkedCountries,item.name);
          //console.log(index," "+ isChecked )
    // The checked value is altered before the state changes for some reason is not a trully controlled component
    // So the next conditions are INVERTED.

    if (isChecked) return setcheckedCountries((state) => [...state, item]);
    
    if (!isChecked && index > -1)
      return setcheckedCountries((state) => {
        state.splice(index, 1);
        console.log(state)
        return JSON.parse(JSON.stringify(state)); // Here's the trick => React does not update the f* state array changes even with the spread operator, the reference is still the same.
       });
  };

  const findIndexOfObject = (array,nameofItem)=>{
    console.log(nameofItem)
     // Find Radagast
return array.findIndex(function (item) {
  console.log(item.name)
	return item.name === nameofItem;
});

  
    
  }
  
  const itemIsinclude = (array,item)=>{
     var bol=false;
    for (var i=0;i < array.length;i++){
      if (item === array[i].name){
        bol= true
      }
    }
    //console.log(bol)
    return bol
  }
  return (
    <Fragment>
      {/* Parent 
     <Button onClick={()=>{handleChange1(false)}}>refresh</Button>
      <Checkbox
        checked={checkedCountries.length === data.length}
        indeterminate={
          checkedCountries.length !== data.length &&
          checkedCountries.length > 0
        }
        onChange={(event) => handleChange1(event.target.checked)}
      />*/}

      {/* Childrens */}
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3,width:'80%' }} >
        {checkedCountries &&
          data.map((item,index) => (
            <Grid container key={`check-item-${index}`} justifyContent={'flex-start'} alignItems='center'>
              <Grid item > <Checkbox
              key={item._id}
              checked={itemIsinclude(checkedCountries,item.name)} 
              onChange={(event) =>
                handleChange2(event.target.checked, item)
              }
              inputProps={{ "aria-label": "controlled" }}
            /></Grid>
            <Grid item >
             <Typography>{item.name}</Typography>

            </Grid>
             </Grid>
          ))}
      </Box>

{/*       <h3>ID's: {JSON.stringify(checkedCountries)}</h3>
 */}    </Fragment>
  );
};

export default CheckboxList;

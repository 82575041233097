import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    border:'0px solid',
    width:'100% !important'
   /* margin: '0% 14%',
    [theme.breakpoints.down('xs')]:{
      margin: '0% 0%',

    }
    ,[theme.breakpoints.down('sm')]:{
      margin: '0% 0%',

    }
 */
  },

}));

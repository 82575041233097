import * as React from 'react';
import Box from '@mui/material/Box';
import {Button,Grid,CircularProgress} from '@mui/material';
import {ContentCopy} from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {truncateString} from '../../../../utils/Utils'
import { Link } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '70%',
  left: '50%',
  height:'59vh',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'snow',
  color:'#000',
  border: '2px solid var(--app-primary)',
  borderTopRightRadius: 16, 
  borderTopLeftRadius: 16, 
  boxShadow: 24,
  p: 3,
  marginBottom:0
};

export default function Alert({open,setOpen,handleOpen,handleClose,textvalue,handleWithdraw,storename,completedPayment,showprogress,showBtn,setShowBtn,charges}) {
  //console.log(completedPayment)
  
   const withdraw =()=>{
    if (parseFloat(textvalue) > 0.00000001){
     const calc=  calculatePercentage(textvalue)

      const amount=calc.merchantPay;
      const ourpay=calc.ourpay
      const debitAmount =calc.debitAmount
      const txtamount=parseFloat(textvalue)
            console.log(amount)
      const memo=`widraw  for ${storename}`
      handleWithdraw(parseFloat(amount),ourpay,debitAmount,null,null,memo)
      setShowBtn(false)
     }else{
  
     }
  }

  const calculatePercentage =(amount) =>{

       // const percentage = parseFloat(amount)/100
        //const subtractPercent = parseFloat(amount)-parseFloat(percentage);
        //const MerchantPay= subtractPercent-parseFloat(charges)
        const MerchantPay = parseFloat(amount)
        const DebitAmount = parseFloat(amount)+parseFloat(charges)
        console.log(/* "1% : "+ percentage +  " subtractPercent : " +subtractPercent + */"Merchant pay : " + MerchantPay )
   return {/* ourpay:percentage, */merchantPay:MerchantPay,debitAmount:DebitAmount}
  }

  return (
    <div>
      <Modal 
        open={open}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item>  <Typography id="modal-modal-title" sx={{fontSize:'0.8rem'}} variant="h6" component="h2">
            Widraw to your wallet
          </Typography></Grid>
          <Grid item onClick={()=>handleClose()}>X</Grid>
         </Grid>

        { showBtn ? <Grid container marginTop={2} justifyContent='space-between' >
            <Grid item>
              <Grid container justifyContent={'space-between'} direction={'column'}>
               
               
                <Grid item >
                <Typography variant='body2' sx={{fontSize:'0.8rem',mt: 2 }}>
           {` withdraw ${calculatePercentage(textvalue).merchantPay.toFixed(8)} to your wallet address associated with your pi account`}
          </Typography>
                </Grid>
              </Grid>
            </Grid>
         </Grid>:''}
         {
          showBtn ? <Grid container justifyContent='center' alignItems={'center'} marginTop ={5}>
          <Grid item>
            <Button variant='contained' color='primary'  className='client-withdraw-Button' onClick={()=>{withdraw()}}>Withdraw</Button>
          </Grid>
       </Grid> :<>{
         showprogress ? <Grid item marginTop ={20}>
         <Grid container alignItems={'center'}>
           <Grid item xs={4} > 
         </Grid>
         <Grid item xs={4} > 
           <Grid container justifyContent="center" alignItems='center'>
            <Grid item >
            <CircularProgress  variant='indeterminate' color ='primary' size={29} />      
            </Grid>
           </Grid>
         </Grid>
         <Grid item xs={4} >
         </Grid>
         
         </Grid>
     </Grid>:''
       }</>
         }
        {
          
          completedPayment!==undefined ?  <Grid container justifyContent='space-between' border={'0px solid darkgrey'} marginTop={3} >
              <Grid item xs={12}>
               
               <Grid container justifyContent='space-between' border={0}  borderRadius={1} padding={'3px 5px'}  >
                 <Grid item xs={4} alignContent={'center'}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{`Amount`}</Typography>
                 </Grid>
                 <Grid item xs={2} justifyContent={'center'}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{`+`}</Typography>
                 </Grid>
                 <Grid item xs={3}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{`Gas fee`}</Typography>
                 </Grid>
                 <Grid item xs={3}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>Total</Typography>
                 </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12}>
               
               <Grid container justifyContent='space-between' border={0}  borderRadius={1} padding={'3px 5px'}  >
                 <Grid item xs={4} alignContent={'center'}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{parseFloat(completedPayment.amount)}</Typography>
                 </Grid>
                 <Grid item xs={2} justifyContent={'center'}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{`+`}</Typography>
                 </Grid>
                 <Grid item xs={3}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{`${charges}`}</Typography>
                 </Grid>
                 <Grid item xs={3}>
                   <Typography variant='body2' sx={{fontSize:'0.8rem'}}>{parseFloat(completedPayment.amount)+parseFloat(charges)}</Typography>
                 </Grid>
               </Grid>
            </Grid>

          <Grid item marginTop={1}>
            <Grid container flexDirection={'column'} spacing={2} >
                <Grid item>
                  <Grid container direction='column'>
                  <Grid item >
                <Typography style={{color:'textGrey',padding:0,fontSize:'0.8rem'}}>
          {`Paid ${parseFloat(completedPayment.amount).toFixed(8)} to `}
        </Typography></Grid>
         <Grid item >
        <Typography style={{color:'GrayText',padding:0,height:20, overflowWrap: "anywhere",fontSize:'0.7rem'}}  id="modal-modal-description">
          {` ${truncateString(completedPayment.to_address,60)}`}
        </Typography>
                </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                {
                  completedPayment !==undefined ? <Grid container justifyContent='space-between
                  ' alignItems={'center'}>
                     <Grid item xs={1}><Typography sx={{ mt: 2,fontSize:'0.6rem',color:'GrayText'}}>
                  {`txid :  `}
                </Typography></Grid> 
                    <Grid item xs={9}>
                      <Link to={`https://blockexplorer.minepi.com/tx/${completedPayment.transaction.txid}`} style={{ mt: 5,fontSize:'0.6rem',color:'blue',overflowWrap: "anywhere" }}>
                     {truncateString(completedPayment.transaction.txid,40)}
              </Link> 
              </Grid>
                <Grid item  xs={2}style={{border:'0px solid'}} >
                  <Grid container justifyContent={'center'} alignItems='center'marginTop={2}>
                      <Grid > 
                  <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() =>  navigator.clipboard.writeText(completedPayment.transaction.txid)}/></Grid>
                  </Grid>
                </Grid>
                </Grid>:''
                }
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} marginTop={5} style={{border:'0px solid'}}>
                <Grid container justifyContent={'center'} alignItem='center' >
                    <Grid item xs={4}>
                       
                    </Grid>
                    <Grid item  xs={4} >
                      <Grid container justifyContent={'center'} alignItem='center' >
                        <Grid item >
                       <CheckCircleOutlineIcon color='success' style={{ transform:'scale(3)'}}/>
                        </Grid>
                      </Grid>
                        
                    </Grid>
                    <Grid item  xs={4} >
                       
                    </Grid>
                    
                </Grid>
          </Grid>
       </Grid>:''
        }
        
        </Box>
      </Modal>
    </div>
  );
}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import Maintenance from './Maintenance'
import reportWebVitals from './reportWebVitals';
import {CircularProgress} from '@mui/material';
import { Lines,CustomPreloader } from 'react-preloaders2';
import {  ThemeProvider,createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: { primary:{main:"#6d1b7b",  },  }
});  
ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter> 
     {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ?   <Maintenance/> 
     :
     <>
     <App />
     {<CustomPreloader>
         <span>... loading</span>
         </CustomPreloader>} 
         
     </>
     }
   

      {/*<Lines />*/}
  
    
         </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import useStyles from './styles'
import { Grid, Typography } from '@mui/material'
import ContactForm from './ContactForm'
import InforAppbar from '../appbar/InfoAppbar'
import InfoFooter from '../footer/InfoFooter'
const ContactUs = ({setMessage ,setOpenSnackBar}) => {
    const classes =useStyles()
//to do https://medium.com/weekly-webtips/simple-react-contact-form-without-back-end-9fa06eff52d9
  return (
     <div className={classes.root}>
          <Grid container  justifyContent={'space-between'} direction={'column'}>

<Grid item >
 <InforAppbar />
</Grid> 
<Grid container direction='column' className={classes.content} >
<Grid item>{/* titile */}
     <Grid container justifyContent={'space-between'} spacing={2} padding={2} direction='column' >
     <Grid item>
         <Typography variant='h4' className={classes.title}>Contact Us</Typography>
     </Grid>
     <Grid item>
         <Typography variant='h6'>Send Us a Message ,we shall get in touch with you</Typography>
     </Grid>
   </Grid>

     </Grid>

<Grid item >{/* form */}
     <Grid container justifyContent={'space-between'} spacing={2} marginTop={5} marginBottom={5}>
    
      <Grid item xs={12} sm={2} md={2} lg={2} ></Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
           <ContactForm setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}/>
          </Grid>
           <Grid item xs={12} sm={2} md={2} lg={2}>  </Grid>
         </Grid>
     </Grid>
   </Grid>
   
<Grid item  xs={12} sm={12} md={12} lg={12}>
<InfoFooter />
</Grid>
     </Grid>
       
         
          
         
     </div>
  )
}

export default ContactUs

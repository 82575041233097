import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { Grid, TextField,Button, Typography, RadioGroup, FormControlLabel, Radio, Input, FormControl, FormLabel} from '@mui/material';
import useStyles from './styles'
import React from 'react';
const ContactForm = ({setMessage,setOpenSnackBar}) => {
    const classes = useStyles()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [value, setValue] = React.useState('');
  const [valueError, setValueError] = React.useState(true);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
    React.useEffect(()=>{
        !value ?setValueError(true):setValueError(false)
    },[value])
  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    console.log(data)
    var reply_to=email
    var submitas=value
   try {
        const templateParams = {
          name,
          email,
          subject,
          submitas,
          message,
          reply_to
        };
        
       if (!valueError){
        await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
          ).then((ret)=>{
              setMessage({body:'message sent successfull',severity:"info"})
              setOpenSnackBar(true)
          });
       }
        reset();
      } catch (e) {
        console.log(e);
      }
    console.log('Name: ', name);
    console.log('Email: ', email);
    console.log('Subject: ', subject);
    console.log('Message: ', message);
  };

  return (
    <div>

<Grid container justifyContent={'space-between'}  direction='column' className={classes.contactformcontainer}>
               

                <Grid item>{/* form */}
                    <form id='contact-form' className={classes.contactform} onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container justifyContent={'space-between'} direction='column'  padding={1} spacing={3}>
                    <Grid item xs={12}  >
                      <Grid container justifyContent={'space-between'} spacing={1}>
                        <Grid item xs={6}>
                        <TextField variant='standard' type="text" name="name" placeholder="Name" fullWidth  {...register('name', {
                            required: { value: true, message: 'Please enter your name' },
                          maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                             }
                          })} />
                        
                    {errors.name && <Typography variant='body2' color='GrayText' className='errorMessage'>{errors.name.message}</Typography>}
                         </Grid>

                         <Grid item xs={6}>
                         <TextField variant='standard' type="email" name="email" placeholder="Email" fullWidth  {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })} />
                       
                    {errors.email && (
                      <Typography variant='body2' color='GrayText' className='errorMessage'>Please enter a valid email address</Typography>
                    )}
                         </Grid>
                       </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={'sapce-between'} direction='column' spacing={3}>
                                <Grid item >
                                <TextField fullWidth variant='standard' type="text" name="subject" placeholder="Subject"   {...register('subject', {
                        required: { value: true, message: 'Please enter a subject' },
                        maxLength: {
                          value: 75,
                          message: 'Subject cannot exceed 75 characters'
                        }
                      })} />{errors.subject && (
                      <Typography variant='body2' color='GrayText' className='errorMessage'>{errors.subject.message}</Typography>
                    )}
                            
                    
                                </Grid>
                                <Grid item>
                               
     <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Submit As</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="submitas"
        value={value}
        onChange={handleChange}
        aria-required
      >
   
        <FormControlLabel value="user" control={<Radio />} sx={{color:'GrayText'}}  label="User" />
        <FormControlLabel value="merchant" control={<Radio />}  sx={{color:'GrayText'}} label="Merchant" />
        <FormControlLabel value="business" control={<Radio />}  sx={{color:'GrayText'}} label="Business" />

      </RadioGroup>
    </FormControl>
    {valueError && <Typography variant='body2' color='GrayText' className='errorMessage'>{'Please select one'}</Typography>}
                               
                                </Grid>
                                <Grid item >
                                    <TextField multiline  fullWidth  rows={5}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      placeholder='Message'/>
                        
                    {errors.message && <Typography variant='body2' color='GrayText' className='errorMessage'>Please enter a message</Typography>}
                                </Grid>
                                <Grid item >
                               <Button  type='submit' size='large' color='primary' variant='contained'>Submit</Button>
                          
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               
                
              
              </form>
                  
                </Grid>


              </Grid>
              
              
            </div>

  );
};

export default ContactForm;
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {NavLink} from 'react-router-dom'
import {FacebookOutlined,Twitter,WhatsappOutlined,Telegram,Instagram} from '@mui/icons-material';

const preventDefault = (event) => event.preventDefault();

export default function Medialink({medialinks}) {
  console.log(medialinks)
  const gotoLink =(url)=>{
       console.log(url)
    window.open(url, '_blank');
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        typography: 'body1',
        '& > :not(style) + :not(style)': {
          ml: 2,
        },
      }}
      onClick={preventDefault}
    >
      <Link target='_blank' onClick={()=>{gotoLink(medialinks.facebook)}} href={`${medialinks.facebook}`} underline="none">
        <FacebookOutlined />
      </Link>
      <Link onClick={()=>{gotoLink(medialinks.telegram)}} href={`${medialinks.telegram}`} underline="hover">
       <Telegram />
      </Link>
      <Link  onClick={()=>{gotoLink(medialinks.whatsapp)}} href={`${medialinks.whatsapp}`} underline="always">
       <WhatsappOutlined />
      </Link>
    </Box>
  );
}
/* eslint-disable no-unused-vars */
 import React ,{useState,useEffect} from 'react'
 import{Container,Typography,Button, Grid,Snackbar,Alert,Stack} from '@mui/material';
 import Slide from '@mui/material/Slide';
 import useStyles from './styles';
 import CartItem from './cartItem/CartItem';
 import {Link,useHistory} from 'react-router-dom';
 import {formarttoCurrency,DateDiff, formarttoPiCurrency} from '../../utils/Utils';
 import CustomizedSnackbar from '../snackbar/CustomizedSnackbar';
 import CheckOutNavigation from '../../components/checkoutnavigation/CheckOutNavigation'

const Cart = ({cart, handleUpdateCartQty,handleupdateColorSize,handleupdateMeasurement,handleRemoveFromCart, handleEmptyCart,handleupdateSelection,cartitemchecked, setIscartitemChecked,updateCartItems,authenticateToken,handleUnprocessedOrders}) => {
    const classes= useStyles();
    const [itemsCount,setItemsCount]=useState(0);
    const [onHookFormError,setOnhookFormError]=useState(false);
    const [errorMessage,setErrorMessage]=useState('');
    const [showError,SetShowError]=useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [isCartUpdated,setIsCartUpdated]  = useState(false);
    const[datediff,setDateDiff]=useState(0);
    const[isAccessTokenRefreshed,setAccessTokenRefreshed]=useState(false)
    const[isUnprocessedOrders,setHandleunprocessedOrders]=useState(false)
    
    var colorSizeError=false; 
    var sizeErrorValue=false;
    var quantityError =false;
    var stockError =false;
    var measurementError=false;
    var anItemSelectedError=false

   const history=useHistory();
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }
   const handleUpdateCartItems =(items)=>{
     var itemIds=[];
     var qtys=[];
    for(var i=0;i < items.length;i++){
          itemIds.push(items[i].product._id)
          qtys.push(items[i].quantity)
    }
     updateCartItems(itemIds,qtys)
   }
   const handleCheckOut=()=>{
   try{
    checkError()
     
    if (!colorSizeError && !sizeErrorValue && !quantityError && !anItemSelectedError && !stockError /* && !measurementError */){
       // SetShowError(false)

        history.push('/checkout')
   }else{
         SetShowError(true)
         setOpenSnackBar(true)
   }

   }catch(err){
       console.log(err)
   }
   } 
   
   const checkError=()=>{
       const values=[]
       const sizeValues=[]
       const zeroStockedItems =[]
       const zeroQuantityItems =[]
       const selectedItems =[]
       for(var i=0;i<cart.items.length;i++){
            //var measurement=cart.items[i].measurement
            if (cart.items[i].selected===true){
              console.log(cart.items[i].selected)
              selectedItems.push(0)
              //  var objValues=Object.values(measurement) //get values withen ,measurement object 
                if (cart.items[i].quantity <=0){
                  zeroQuantityItems.push(cart.items[i].quantity)
                console.log()
               }
                const stock = cart.items[i].product.stock
               if (stock <=0){
                zeroStockedItems.push(stock)
                console.log()
               }
            }else{
                
                setErrorMessage('Please select at least one item')
                setOpenSnackBar(true)
            }
           
            
          // console.log(cart.items[i].selected)
            if (cart.items[i].product.color.length>=1 && cart.items[i].selected===true){//if items has color specification provided by vendor add its user choice into the array 
                 values.push(cart.items[i].color)
            }
            if(cart.items[i].product.size.length>=1 && cart.items[i].selected===true){//if items has size specification provided by vendor add its user choice into the array 
             sizeValues.push(cart.items[i].size)
            }
           
           
       }
        
        //check through items  if user has choosen a specc if not value will be null else the user choice is found 
       if(values.includes('null')){  //Error null value is found user does selected some choice 
           colorSizeError=true
           setErrorMessage('Please choose color')
       }else{// no colorSizeError ,not single null value found withen the list
           colorSizeError=false
        //   setErrorMessage('')

       }
     if(sizeValues.includes('null')){ 
        setErrorMessage('Please choose size')
         sizeErrorValue= true
       }else{
        sizeErrorValue = false
     // setErrorMessage('')

     }
     if (zeroQuantityItems.includes(0)){
      setErrorMessage('Item selected Quanity must be at least 1')
      quantityError= true
     }else{
      quantityError= false
     }
     if (zeroStockedItems.includes(0)){
      setErrorMessage('Item selected Stock  is 0')
      stockError = true
     }else{
      stockError = false
     }
    if (selectedItems.includes(0)){
      anItemSelectedError=false
    }else{
      setErrorMessage('Please select at least one item')
      anItemSelectedError=true
    }
     /*   for (let i=0;i<objValues.length;i++){//if customer doeos not input measure value return measurement Error
        if (objValues[i]===""){
            measurementError=true
        }else{
            measurementError=false
        }
    }
 */
   } 
   useEffect(()=>{
       try{
        if(!isAccessTokenRefreshed){
          const userobj =JSON.parse( localStorage.getItem('user'))
          authenticateToken(userobj.user)
         } 
         if (!isUnprocessedOrders){
            setTimeout(()=>{
              //handleUnprocessedOrders()
            },5000)
         }

         // console.log(datediff)
           if(!isNaN(datediff)){
              if (!isCartUpdated && datediff > 3000){
                  handleUpdateCartItems(cart.items)
                 }
           }
       }catch(err){
        console.log(err)
       }

       return ()=>{
        setIsCartUpdated(true)
        setAccessTokenRefreshed(true)
        setHandleunprocessedOrders(true)
       }
   })

    useEffect(()=>{ 
           //console.log("selected List "+colorSelectedList);
           try{
            console.log(cart.interactedDate)
            setDateDiff(new DateDiff(cart.interactedDate, Date.now()).getDifferenceInSeconds())
     
        }catch(err){
         console.log(err)
        }
       try{
        cart.items.length!==undefined?setItemsCount(cart.items.length):setItemsCount(cart.items.length)
       }catch(err){
           console.log(err)
       }
    },[cart,setItemsCount])
    const EmptyCart = ()=>(
       <div className={classes.notice}>
         <Typography variant="subtitle2">No items in your shopping cart,
        <Link to="/" className={classes.link}>start adding some !</Link>
        </Typography>
       </div>
    );
    const  FilledCart =()=>(
          <>
            <Grid container spacing={0.5} className={classes.filledcart} justifyContent='flex-start' > 
                {cart.items.map((item,index)=>(
                    <Grid item xs={12} sm={4} md={4} lg={4} key={`grid-${index}`}>
                        <CartItem cartitem={item} key={`cartitem-${index}`} onUpdateCartQty={handleUpdateCartQty} onUpdateColorSize={handleupdateColorSize} onUpdateMeasurement={handleupdateMeasurement} onRemoveFromCart={handleRemoveFromCart} onHookFormError={onHookFormError} setOnhookFormError={setOnhookFormError} onUpdateSelect={handleupdateSelection} cartitemchecked={cartitemchecked} setIscartitemChecked={setIscartitemChecked}/>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent='space-between' direction={'column'} border={0} marginBottom={15}>
                <Grid item  >
                <Grid container justifyContent='flex-start' >
                     <Grid item>
                     <Typography variant="h6" className={classes.price}>{`Subtotal: ${formarttoPiCurrency((cart.subtotal+cart.subfees),'π')}`} </Typography>
                      </Grid>
                <Grid item>

                </Grid>
            </Grid>
                </Grid>
                <Grid item >
                <Grid container justifyContent='space-between' >
                     <Grid item >
                     <Button className={classes.emptyButton} size="small" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>Empty Cart</Button> 
                      </Grid>
                <Grid item>
                <Button  className={classes.checkoutButton} size="small" type="button" variant="contained" onClick={()=>{handleCheckOut()}} color="primary">Check Out</Button>
                </Grid>
            </Grid>
                </Grid>
            </Grid>
           {/*  <div className={classes.cardDetails}>
             <div> <Typography variant="h6" className={classes.price}>{`Subtotal: ${formarttoPiCurrency((cart.subtotal+cart.subfees),'π')}`} </Typography>
              </div>
                <div className={classes.checkoutButtons}>
                <Button className={classes.emptyButton} size="small" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>Empty Cart</Button> 
                 <Button  className={classes.checkoutButton} size="small" type="button" variant="contained" onClick={()=>{handleCheckOut()}} color="primary">Check Out</Button>
              </div>
            </div> */}
         </>
    )

    if (!cart) return EmptyCart();

    return (
        <div className={classes.root}>
               {/*  <Alert stack={{limit: 1}} />
               
                   { errorMessage!=='' ? Alert.error(errorMessage, {
                        position: 'bottom-right',
                        effect: 'jelly'
            
                    }):''  } */}
               
              {/*  <Stack spacing={2} sx={{ width: '100%' }}>

      <Snackbar 
      open={open} 
      autoHideDuration={3000} onClose={handleClose}  
       anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
       TransitionComponent={SlideTransition}

        >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
         {errorMessage}
        </Alert>
      </Snackbar>
      </Stack> */}
      <CheckOutNavigation/>
      <CustomizedSnackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar}
    message={errorMessage} 
    severity={"error"}
    variant={'outlined'}
    verticalPosition={"bottom"} 
    horizontalPosition={'center'}
    transition={'slide'}
 />
            <div className={classes.container} >
                
{/*    <div  className={classes.toolbar}>  </div>
 */}                <Typography className={classes.title} variant="h6" gutterBottom>Your Shopping Cart</Typography>
              
               
              {cart ? <> {itemsCount===0 ? EmptyCart(): FilledCart()}</>:''}
            </div>
       </div>
    )
}

export default Cart

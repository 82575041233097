import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     root:{
        width:'100%',
        border:'0px solid ',marginBottom:0,position:'absolute',
         [theme.breakpoints.down('xs')]:{
            /* marginBottom:'10vh', */
            display:'none'
           
      }
     },
    
     
}))
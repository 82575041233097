import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  home: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    margin: '0% 14%',
    [theme.breakpoints.down('xs')]:{
      margin: '0% 0%',
    }
    ,[theme.breakpoints.down('sm')]:{
      margin: '0% 0%',

    }

  },
  root: {
    flexGrow: 1,
  },
}));

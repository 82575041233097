const cartObj={
    "items": [],
    "subtotal": 0,
    "subfees": 0,
    "date": "2022-08-03T18:50:39.646Z",
    "interactedDate": "2022-11-15T21:25:05.770Z",
    "_id": "--",
    "userId": "61782f2f391d5d0cd0c7af3b",
    "expires": "2023-02-03T18:58:32.957Z",
    "__v": 0
  }

  export {cartObj}


import * as React from 'react';
import Box from '@mui/material/Box';
import {Button,Grid} from '@mui/material';
import {ContentCopy} from '@mui/icons-material'
import {CheckCircleOutlineIcon} from '@mui/icons-material/CheckCircleOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height:'20vh',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'snow',
  color:'#000',
  border: '2px solid var(--app-primary)',
/*   borderTopRightRadius: 12, 
 */  borderRadius: 4, 
  boxShadow: 24,
  p: 3,
};

export default function Medalalerts({open,setOpen,handleOpen,handleClose,textvalue,handleWithdraw,storename,completedPayment,showprogress,showBtn,setShowBtn}) {
 

  return (
    <div>
      <Modal 
        open={open}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item> <Typography id="modal-modal-title" variant="h6" component="h2">
            Daily Rewards
          </Typography></Grid>
          <Grid item onClick={()=>handleClose()}>X</Grid>
         </Grid>

        {
      <Grid container justifyContent='space-between'   >
         
                
          <Grid item xs={12} marginTop={2} >
                <Grid container justifyContent={'center'} alignItem='center' spacing={0.5} >
                    <Grid item xs={3} >
                    </Grid>
                    <Grid item  xs={6} >
                      <Grid container justifyContent={'space-around'} direction='row' alignItems={'center'}   >
                        <Grid item >
                         <EmojiEventsIcon color='primary' style={{ transform:'scale(2.5)'}} />
                        </Grid>
                        <Grid item>
                         <Typography id="modal-reward-title" variant="h6" component="h2" >2X</Typography>
                        </Grid>
                      </Grid>
                        
                    </Grid>
                    <Grid item  xs={3} >
                       
                    </Grid>
                    
                </Grid>
          </Grid>
          <Grid item xs={12} marginTop={2}>
          <Typography  variant="body2" >You've earn daily attendance points, come back tommorow to earn more</Typography>
          </Grid>
       </Grid>
        }
        
        </Box>
      </Modal>
    </div>
  );
}
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import ImageView from './imageview/ImageView'
import { formarttoPiCurrency} from "../../utils/Utils"
const ProductDetail = ({myCld,product,store,active,setActive ,verified,setVerified}) => {

  return (
         
            <Grid item xs={7} sm={7} md={7} lg={7}>
            
    <Grid container justifyContent='space-around' >
        <Grid item xs={12} sm={12} md={12} lg={12} >
            
          <Grid container justifyContent={'space-between'} >
           <Grid item xs={12} sm={3} md={3} lg={4} className="pm-productInfoTopWrapper" padding={2}>

           <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item>
            <img src={myCld.image(`${product.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} alt="" className="productInfoImg" /> 
            </Grid>
            <Grid item>
            <Typography variant='span' fontWeight='bold' color={'black'}>{product.name}</Typography>
         
            </Grid>
            
        </Grid>
          </Grid>
          
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Price</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{formarttoPiCurrency(product.price)}</Typography>

            </Grid>
        </Grid>
        </Grid>

      
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Shipping</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{formarttoPiCurrency(product.shippingFees)}</Typography>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} >
            <Grid item xs={6}>
            <Typography variant='body1' color={'darkgray'}>Color</Typography>
            </Grid>
            <Grid item xs={6}>
           <Grid container justifyContent={'space-between'} direction='column' >
           {product.color.map((color)=>{ 
            return  <Grid item >
                   <Typography variant='body2'>{color} </Typography>
                </Grid> })}
           </Grid>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} >
            <Grid item xs={6}>
            <Typography variant='body1' color={'darkgray'}>Size</Typography>
            </Grid>
            <Grid item xs={6}>
           <Grid container justifyContent={'space-between'} direction='column' >
           {product.size.map((size)=>{ 
            return  <Grid item >
                   <Typography variant='body2'>{size} </Typography>
                </Grid> })}
           </Grid>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Stock</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{product.stock.currentstock}</Typography>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Country Scope</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{`${product.countryScope.map((scope)=>{return scope.name})},`}</Typography>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Active</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{product.active}</Typography>

            </Grid>
        </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
            <Typography variant='body1' color={'darkgray'}>Verified</Typography>
            </Grid>
            <Grid item>
            <Typography variant='body2'>{`${product.verified}`}</Typography>

            </Grid>
        </Grid>
        </Grid>
       <hr style={{margin:1}} />
        {
            store.length > 0 ?  <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1}>
            <Grid container justifyContent={'space-between'}  direction={'column'}>
                <Grid item>
                <Typography variant='body1' color={'darkgray'}>Store</Typography>
                </Grid>
                <Grid item>
                <Grid container justifyContent={'space-between'}>
                {/* <Grid item>
                <Typography variant='body2'>Name </Typography>
                </Grid> */}
                <Grid item marginBottom={1}>
                  <Typography variant='body2'>{store[0].name}</Typography>
                </Grid>
                </Grid>
    
                </Grid>
                <Grid item>
                <Grid container justifyContent={'space-between'}>
                <Grid item>
                <Typography variant='body2' color={'darkgray'}>Country </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>{store[0].country.name}</Typography>
                </Grid>
                </Grid>
    
                </Grid>
                <Grid item marginTop={1}>
                <Grid container justifyContent={'space-between'}>
                <Grid item>
                <Typography variant='body2' color={'darkgray'}>Category</Typography>
                </Grid>
                <Grid item>
                    <Grid container justifyContent={'space-between'}>
                    {store[0].categories.map   ((category)=>{
                        return  <Grid item >
                        <Typography variant='body2'>{`${category.name},`}</Typography>
                        </Grid>
                        
                        })}
                       
                    </Grid>
                </Grid>

                </Grid>
    
                </Grid>
            </Grid>
            </Grid>:''
        }


            </Grid>


            <Grid item xs={6} sm={6} md={6} lg={8} padding={1}>
            <ImageView images={product.image}/>
               
            </Grid>


          </Grid>  
      <Grid item xs={12} sm={12} md={12} lg={12}  >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent={'flex-start'} alignItems={'flex-start'} direction={'column'} spacing={2}>
            <Grid item justifyContent={'flex-start'} >
            <Typography variant='body1' color={'darkgray'}>Description</Typography>
            </Grid>
            <Grid item  justifyContent={'flex-start'}>
            <Typography variant='body2'>{product.description}</Typography>

            </Grid>
        </Grid>
        </Grid>
     </Grid>
        </Grid>
      
    
        <Grid item xs={12} sm={12}>
           <Grid container justifyContent='flex-start' direction={'column'} >
               <Grid item >
               <Grid container justifyContent={'space-between'} spacing={1}>
                    <Grid item xs={6} >
                <div className='formItem'>
            <label>Verified</label>
            <Select variant="standard" value={verified} name="country"  required fullWidth onChange={(e)=>{setVerified(e.target.value)}}>
        <MenuItem key={0} value={true}>true</MenuItem>
        <MenuItem key={1} value={false}>false</MenuItem>

     
       </Select> 
            </div>
                    </Grid>
                    <Grid item xs={6}>
              <div className='formItem'>
            <label>Active</label>
            <Select variant="standard" value={active} name="country"  required fullWidth onChange={(e)=>{setActive(e.target.value)}}>
        <MenuItem key={0} value={'yes'}>Yes</MenuItem>
        <MenuItem key={1} value={'no'}>No</MenuItem>

     
       </Select>     
           
            </div>
            </Grid>
                  </Grid>
            </Grid>
           
              <div className='editButtonContainer'>
            <Button variant='outlined' color='primary' type="submit" fullWidth >Update</Button>
            </div>
           </Grid>
        </Grid>
   </Grid>
   </Grid>
     )
  }

export default ProductDetail

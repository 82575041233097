import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expand: {fontSize:'16',border:'0px solid',marginBottom:'-5px'},
  collapseList:{marginLeft:25},

category:{
    width: '15vw',
  /*   height:' 100vh', */
    backgroundColor:' rgb(251, 251, 255)',
   position: 'fixed',
    top:  40,
    right: 0,
    transition:' transform .3s cubic-bezier(0, .52, 0, 1)',
    Zindex: 1000,
    
  },
categoryHide:{
    transform: 'translate3d(-100vw, 0, 0)'
  },
categoryShow:{
    flex: 1,
    transform: 'translate3d(0vw, 0, 0)',
    
/*     overflow: 'scroll',
 */  }
 
}));

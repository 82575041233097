/* eslint-disable no-unused-vars */
import { useState,useEffect,useCallback } from "react";
import React from 'react';
 import { Sidebar } from "./components/sidebar/Sidebar";
 import {MobileSlider} from './components/mobilemenu/MobileSlider'
 //import { Topbar } from "./components/topbar/Topbar";
 import { Appbar } from "./components";
import Confirm from "./components/email/Confirm"
import "./dashboard.css"
import {BrowserRouter as Router,Switch,Route,useHistory
} from "react-router-dom";
import { CircularProgress } from '@mui/material';
import Home from "./pages/home/Home";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import Store from "./pages/store/Store"
import StoreList from "./pages/storeList/StoreList";
import NewStore from "./pages/newStore/NewStore";
import ProductsList from "./pages/productlist/ProductsList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";
import Transactions from "./pages/transactions/Transactions";
import Sales from './pages/sales/Sales';
import LogIn from "./pages/login/LogIn";
import SignUp from "./pages/signup/SignUp";
import axios from 'axios';

import {Daabia,product,store} from './daabia/daabia'
/* import Countries from '../assets/jsonData/countries'
import States from '../assets/jsonData/states'
import Cities from '../assets/jsonData/cities' */

/* import Sidebar from 'react-sliding-sidemenu' */
 const  Dashboard=({setOpenSnackBar,setMessage}) =>{

    //get deivce type

    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    };
  const[countries]=useState([])
  const[states]=useState([])
  const[cities]=useState([])
  const [showSidebar,setShowSideBar]=useState(true);
  const [showMobileSidebar,setShowMobileSideBar]=useState(true);
  const [stores,setStores] = useState([]);
  const [filteredstores, setFilteredStores] = React.useState([]);//filered verified Stores
  //console.log(parseInt(localStorage.getItem('storeindex')))
 const [storeindex,setStoreindex]=useState(  isNaN(parseInt(localStorage.getItem('storeindex'))) ? 0 : parseInt(localStorage.getItem('storeindex')));
 const[ user,setUser ]= useState();
 const [userObj,setUserObj]=useState()
 const [products,setProducts]=useState([]);
 const[refreshToken,setRefreshToken]=useState(localStorage.getItem('refreshToken'));
 const[accessToken,setAccessToken]=useState(localStorage.getItem('accessToken'));
 const [productxs,set_Products]=useState([]);
 const [transactions,setTransactions]=useState([]);
 const [temp_transactions,set_tempTransactions]=useState([]);
 const [sales,setSales]=useState([]);
 const [completedAggregate,setCompletedAggregate]=useState([]);
 const [inCompletedAggregate,setinCompletedAggregate]=useState([]);
 const [alltimeAggregate,setAlltimeAggregate]=useState([]);
 const [analyticsLoaded,setIsanalyticsLoaded]=useState(false);
 const [monthlySales,setMonthlySales]=useState([]);
 const [isStoreEngineInit,setIStoreEngineInit]=useState(false)
 const [ismonthlySalesLoaded,setIsmonthlySalesLoaded]=useState(false);
 const [isStoresLoaded,setIsStoreLoaded]=useState(false);
 const [isTransLoaded,setIstransLoaded]=useState(false);
 const [isSalesLoaded,setIsSalesLoaded]=useState(false);
 const [isproductsLoaded,setIsproductsLoaded]=useState(false);
 const [is_ProductsLoaded,set_IsproductsLoaded]=useState(false);
 const [isProductCategoriesLoaded,setIsProdCatsLoaded]=useState(false);
 const [productCategories,setProductCategories]=useState([]);
 const  [showAlltransactions,setShowAllTrans]=useState(JSON.parse(localStorage.getItem('show_all'))!==null ?JSON.parse(localStorage.getItem('show_all')):false);
 const [switchText,setSwitchText]=useState('Show all');
 const [showprogress,setShowProgress]=useState(false);
 const [devicetype]=useState(getDeviceType());
 const [useStoreId,setUseStoreId]=useState('none');

 



 //const history = useHistory()
             //product.get()
  const handleLoadUser = useCallback(()=>{  
      try{
        var _userObj=JSON.parse(localStorage.getItem('user'))
        console.log(_userObj)
        setUserObj(_userObj)
        setUser(_userObj.user);
      }catch(err){
        console.log(err)
      }
  },[])
 const handletoggleSideBar=(bol)=>{
   setShowSideBar(bol);
 }
  const toggleSideBar=()=>{
    setShowSideBar(!showSidebar);
  }
  const handleMenuOnClick =(e)=>{

     setShowMobileSideBar(!showMobileSidebar);
     console.log("clicked");
     e.stopPropagation();
  }
  const handleShowAllTransactions = (e)=>{    
    setShowAllTrans(e.target.checked);
    var status=''; 
    var checked=e.target.checked;
    checked ? status='' :status='Completed';
    checked ? setSwitchText('Show less') :setSwitchText('Show all');
    localStorage.setItem('show_all', checked)
    
    setTransactions(temp_transactions)
    setTransactions(temp_transactions.filter((item)=> item.status.title!==status));

    
    
  }
  const handleOnStoreChange =(e)=>{

    let storeStatus=stores[e.target.value].status
    console.log(storeStatus)
    resetStates();
    setStoreindex(e.target.value)
    localStorage.setItem('storeindex',e.target.value);
    setShowMobileSideBar(!showMobileSidebar);
    //initializeStore();
   /*    if (window.location.pathname ==='dashboard/product'){
        console.log('onstore changed')
        window.location ='/dashboard/products'
    } */
  }
  const handleOnStoreSelected =(index)=>{
    resetStates(); //this trigger reloads for all endpoints for data
    setStoreindex(index)
    localStorage.setItem('storeindex',index)
  }

  const resetStates=()=>{
    setIstransLoaded(false);
    setIsSalesLoaded(false);
    setIsproductsLoaded(false);
    setIsanalyticsLoaded(false);
    set_IsproductsLoaded(false);
    setIsProdCatsLoaded(false)
    setShowProgress(true)
    setTransactions([]);

    setTimeout(()=>{
      setShowProgress(false)
    },3000)
  }
  const paths =[ 
    '/dashboard',
    '/dashboard/users',
    '/dashboard/user/:userId',
    '/dashboard/newUser/',
    '/dashboard/store',
    '/dashboard/stores',
    '/dashboard/store/:storeId',
    '/dashboard/newStore/',
    '/dashboard/products',
    '/dashboard/product',
    '/dashboard/newProduct',
    '/dashboard/transactions',
    '/dashboard/sales',];

    const initializeStore =()=>{
     // var daabia =new Daabia(stores[storeindex]._id);
     // daabia.fetchProducts()
    }
    //Store Start

    const  handleSubmitStore = async(body,clearfields)=>{
       /*console.log(userObj)*/
     await  store.create(userObj.auth_token,body,clearfields).then((response) => {
        console.log(response.data.store);
        if (response.status===200 && response.data.store!==null){
           localStorage.setItem('stores', JSON.stringify(response.data.stores));
          setStores(response.data.stores)
          setMessage({body:'store created successfully',severity:'success'})
          setOpenSnackBar(true)
         
        clearfields();
        }else{
          setMessage({body:'store submittion failed',severity:'error'})
          setOpenSnackBar(true)
        }
        //addToast(exampleToast(response.data.message));
      })
    } 
  

    async function deleteStore(_id) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/stores/${_id}`);
       // console.log(response);
        if (response.data.deletedCount>=1){
          setStores(stores.filter((item) => item._id !==_id))
          localStorage.setItem('stores',stores.filter((item) => item._id !==_id))
        }
      } catch (error) {
        console.error(error);
      }
    }

    const handleDeleteStore = async(_id)=>{
      /**/ try {
        const response = await store.delete(_id);

        if (response.data.deletedCount>=1){
          setStores(stores.filter((item) => item._id !==_id))
          localStorage.setItem('stores',stores.filter((item) => item._id !==_id))
        }
      } catch (error) {
        console.error(error);
      } 
    }
    const  handleDeleteProduct = async (_id) =>{
      try {
        const response =  await product.delete(_id);
        if (response.data.deletedCount>=1){
        setProducts(products.filter((item) => item._id !==_id))
        
        }
      } catch (error) {
        console.error(error);
      }
    }
//Transactions
const handleUpdateTransaction=(orderid,status,setSelectionModel)=>{

  editTransaction(orderid,status).then((response)=>{
  
    const data=response.data.data;
    
    setTransactions(data)//set transaction data  with new updated one 
    setSelectionModel([]);
    //console.log(response.data);
    
  });
}

const editTransaction =(orderid,status)=>{
const url = `${process.env.REACT_APP_SERVER_URL}/orders/${orderid}`;
const user = JSON.parse(localStorage.getItem('user'));

const body={
       status:status,
       storeId:stores[storeindex]._id
    
}
const config = {
  headers: {
      'auth-token':
        user.auth_token,
    },
}
return axios.patch(url, body,config)

};


const handlegetCategories =async()=>{
  //const storeCatGroupid=category._id;
/*   const url=`${process.env.REACT_APP_SERVER_URL}/category/${storeCatGroupid}`
 */
console.log('loading categories ... ')
  const url=`${process.env.REACT_APP_SERVER_URL}/category`
  await axios.get(url).then((response)=>{
      //console.log(response.data)
      setProductCategories(response.data);
  })  
}
 

const handleUpdateManyTransactions=(option,setSelectionModel,selected_Ids)=>{


editTransactions(option,selected_Ids).then((response)=>{            

    if(response.status===200){
     setTransactions(response.data.data);
     setSelectionModel([])
    }                   

});
}

const editTransactions =(option,selected_Ids)=>{
const ids=JSON.stringify(selected_Ids);
const url = `${process.env.REACT_APP_SERVER_URL}/orders/many/byIds/${ids}`;
const user = JSON.parse(localStorage.getItem('user'));
const storeId = stores[storeindex]._id;

const body={
     storeId:storeId,
     status:option,
     ids: ids
}
const config = {
headers: {
    'auth-token':
      user.auth_token,
  },
}
return axios.patch(url, body,config)

};
//Sales Start here
// eslint-disable-next-line no-unused-vars
const handleUpdateSales=(orderid,setSelectionModel)=>{
     
  editSales(orderid).then((response)=>{

    const data=response.data.data;
    setSales(data)//set transaction data  with new updated one 
    setSelectionModel([]);
    //console.log(response.data);
    
  });
}

const editSales =(orderid,status)=>{
const url = `${process.env.REACT_APP_SERVER_URL}/orders/${orderid}`;
const user = JSON.parse(localStorage.getItem('user'));

const body={
       status:status,
       storeId:stores[storeindex]._id
    
}
const config = {
  headers: {
      'auth-token':
        user.auth_token,
    },
}
return axios.patch(url, body,config)

};


// eslint-disable-next-line no-unused-vars
const handleUpdateManySales=(option,setSelectionModel)=>{


editsales(option).then((response)=>{            

    if(response.status===200){
     setSales(response.data.data);
     setSelectionModel([])
    }                   

});
}

const editsales =(option,selected_Ids)=>{
const ids=JSON.stringify(selected_Ids);
const url = `${process.env.REACT_APP_SERVER_URL}/orders/many/${ids}`;

const body={
     storeId:stores[storeindex]._id,
     status:option,
     ids: ids
  
}
const config = {
headers: {
    'auth-token':
      user.auth_token,
  },
}
return axios.patch(url, body,config)

};

   //New Products Here
   const onFormSubmit = (e,clearFields,body,auth_token) => {
       
    console.log(body)
   /*  var body={
      name:name,
      price:price,
      shippingFees:shippingFees,
      categoryId:categoryId,
      description:description,
      specification:specification,
      digitalProductUrl:digitalProductUrl,
      storeid:storeid,
      stock:stock,
      active:active,
      colors:colors,
      sizes:sizes,
      productImages:productImages,
      productCategory:productCategory
    } */
       product.create(accessToken,body).then((response) => {
        setShowProgress(true)
         // console.log(response.data);
         if (response.data.status===200){
          setShowProgress(false)
          //window.location.reload();
          setProducts(products=>[...products,response.data.product])
            clearFields();
            setMessage({body:'product created successfully',severity:'success'})
            setOpenSnackBar(true)
         }else if (response.data.status===401){ 
          setMessage({body:response.data.message,severity:'error'})
          setOpenSnackBar(true)
       // history.go(0);
         }else if (response.data.status===403){

          setMessage({body:response.data.message,severity:'error'})
          setOpenSnackBar(true)
          var user = userObj.user
          authenticateToken(user)
         }

        });
       
  }

   //New Products Here
   const onProductUpdateFormSubmit = (e,clearFields,body,auth_token) => {
       
  
       product.edit(auth_token,body).then((response) => {
         // console.log(response.data);
         if (response.data.status===200){
          //window.location.reload();
          setProducts(products=>[...products,response.data.product])
            clearFields();
            setMessage({body:'product updated successfully',severity:'success'})
            setOpenSnackBar(true)
         }else if (response.data.status===401){ 
          setMessage({body:response.data.message,severity:'error'})
          setOpenSnackBar(true)
          var user = userObj.user
            console.log(user)
          authenticateToken(user)
       // history.go(0);
         }
        });
       
  }

    // eslint-disable-next-line no-unused-vars
    const fetchProducts = async ()=>{
      try{
         const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/store/${stores[storeindex]._id}`);
         const data=await res.json();
               console.log(data);
               return data.products;
      }catch(error){

      }
}

const handlegetProducts = async()=> {
    try{
       const products = await product.get();
       
       let tmp =[];
          for(let i=0;i<products.length;i++){
            tmp.push(products[i]);
            
          }
       setProducts(tmp);
    }catch(error){

    }
}

const fetchStores = async(user)=>{
  try{
    
           const url =`${process.env.REACT_APP_SERVER_URL}/stores/user/${user._id}`;
      
           return  axios.get(url);
           
  }catch(error){

  }
}
const checkStatus =(store)=>{
  return store.status==='Verified'
}

const handlegetStores = useCallback(async() => {
try{
    await fetchStores(user).then((response)=>{
      if (response.status===200)
      setStores(response.data.store);  
        const mstores=response.data.store
        setFilteredStores(mstores.filter(checkStatus))
        
      localStorage.setItem('stores',JSON.stringify(response.data.store))

    });
 
}catch(error){
    //console.log(error)
}
},[user])

const fetchTransactions = async (stores) => {//get Orders 
  
  try {
   
    
   if (stores){
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/orders/${stores[storeindex]._id}`);

       const data = await res.json();
       return data.orders;
   }
 

 } catch (error) {
 console.log({fetch_store_message:error})
 }
}

const handlegetTransactions = async (stores) => {

 try {
 const transactions = await fetchTransactions(stores); 

  let tmp =[];
  for(let i=0;i<transactions.length;i++){
    tmp.push(transactions[i]);

  } 

  handlegetProducts()
  set_tempTransactions(tmp);

   var status = ''
   setShowAllTrans(false);
   setShowProgress(false)
   localStorage.setItem('show_all',false);
   showAlltransactions ? status='' :status='Completed';
   showAlltransactions ? setSwitchText('Show less') :setSwitchText('Show all');

   setTransactions(tmp.filter((item)=> item.status!==status))

} catch (error) {
  //setStoreId(stores[0]._id);
  console.log({message:error})
}
};

const fetchSales= async (stores) => {//get Orders 
  
  try {
  
    
 const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/orders/completed/${stores[storeindex]._id}`);
 const data = await res.json();
 
 return data.orders;

 } catch (error) {
 console.log({fetch_sales_message:error})
 }
}

const handlegetSales = async (stores) => {

 try {
 const ordersFromServer = await fetchSales(stores);  
  let tmp =[];
  for(let i=0;i<ordersFromServer.length;i++){
    tmp.push(ordersFromServer[i]);
    
  }
   setSales(tmp)

} catch (error) {
  //setStoreId(stores[0]._id);
  console.log({message:error})
}
};

const authenticateToken = async (user) =>{
  //setShowProgress(true)

  const url = `http://localhost:3002/auth/authenticate`;

  const  options={
    headers:{
      Authorization:`Bearer ${accessToken}`
    }
   }
await axios.post(url,{},options).then((response)=>{
//console.log(response.data);
if (response.data.status === 403){
 /* 
 setAccessToken(response.data.accessToken)
 setMessage({body:`access token refreshed`,severity:'info'})
 setOpenSnackBar(true)
}else{  */
try{
  var userid = user.user._id
 if(userid===undefined){

  handleLoadUser()
 }else{
   hanldegetAccessToken(userid);
 }
}catch(err){
console.log(err)
}

}else{
setShowProgress(false)
}
})

}  

const hanldegetAccessToken =  async(_id) =>{
      await getAccessToken(_id).then((response)=>{
         setShowProgress(false)

if (response.data.status === 200){

setAccessToken(response.data.accessToken)
localStorage.setItem('accessToken',response.data.accessToken)
setMessage({body:`access token refreshed`,severity:'info'})
setOpenSnackBar(true)
}
})
}


  const getAccessToken =  async(_id) =>{
     const url = `http://localhost:3002/auth/token`;
  return  axios.post(url,{userId:_id,token:refreshToken});
}

 useEffect(()=>{
  if (user  === undefined){
    handleLoadUser() ;
  }
 
  if (!isStoresLoaded){
    console.log(user)
    handlegetStores(user)}


    if (stores.length >0){
       handlegetCategories();
      if(window.daabia_storeid===undefined){
       
        var daabia =new Daabia(stores[storeindex]._id);
        daabia.fetchProducts() 
       

         //console.log(stores)
       // console.log(stores[storeindex]._id)
     }
    }

 return ()=>{
 
    setIsproductsLoaded(true);
    setIsStoreLoaded(true);
  }
 },[handlegetStores,handleLoadUser, isStoresLoaded, storeindex, stores, user])


 useEffect(()=>{ 
  try{
    if (!stores){
           setStores(JSON.parse(localStorage.getItem('stores'))!==null?JSON.parse(localStorage.getItem('stores')):[]);
    setUseStoreId(stores.length > 0 ? stores[storeindex]._id:'none');

    }
    /* if (useStoreId==='none'){
      setDaabia(new Daabia(stores[storeindex]._id));

    }
     */

  
  }catch(err){
    console.log(err)
  }
const _fetchStores = async(user)=>{
  try{
    
           const url =`${process.env.REACT_APP_SERVER_URL}/stores/user/${user._id}`;
      
           return await  axios.get(url);
           
  }catch(error){

  }
}

const _handlegetStores = async (user) => {
try{
    await _fetchStores(user).then((response)=>{
      if (response.status===200)
      setStores(response.data.store);  

      localStorage.setItem('stores',JSON.stringify(response.data.store))

    });
 
}catch(error){
    //console.log(error)
}
}

  const fetchSales= async (stores) => {//get Orders 
    try {
    
      
   const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/orders/completed/${stores[storeindex]._id}`);
   const data = await res.json();
   
   return data.orders;
  
   } catch (error) {
   console.log({fetch_sales_message:error})
   }
  }
  const handlegetSales = async (stores) => {
    console.log('laoding sales ... ')
    try {
    const ordersFromServer = await fetchSales(stores);  
     let tmp =[];
     for(let i=0;i<ordersFromServer.length;i++){
       tmp.push(ordersFromServer[i]);
       
     }
      setSales(tmp)
   
   } catch (error) {
     //setStoreId(stores[0]._id);
     console.log({message:error})
   }
   };
  const _fetchProducts = async ()=>{
    try{
       const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/store/${stores[storeindex]._id}`);
       const data=await res.json();
            // console.log(data)
             return data.products;
    }catch(error){

    }
}
const handleget_Products = async ()=> {

  try{
     const productsFromServer = await _fetchProducts();
     setShowProgress(false)
     setProducts(productsFromServer)
     set_Products(productsFromServer)
     set_IsproductsLoaded(true);

    /*  let tmp =[];
        for(let i=0;i<productsFromServer.length;i++){
          tmp.push(productsFromServer[i]);
          set_Products(productsFromServer!==undefined ? productsFromServer :[]);
        } */
     
  }catch(error){

  }
}
  const handlegetMonthAnalytics =  async (storeid) => {//get Orders 
 
    var url = `http://localhost:${process.env.REACT_APP_SERVER_PORT}/analytics/sales/monthly/${storeid}`
  
    await axios.post(url,{year:2022}).then((response)=>{
      //console.log(response.data)
          setMonthlySales(response.data.monthlySales);
         
  
   });
  }
  const handlegetAnalytics =  async (storeid) => {//get Orders 
 
    var url = `http://localhost:${process.env.REACT_APP_SERVER_PORT}/analytics/report/${storeid}`

    await axios.get(url).then((response)=>{
     // console.log(response.data.transactions)
          //setAnalytics(response.data);
          setTransactions(response.data.transactions);
          setCompletedAggregate(response.data.completedAggregate);
          setinCompletedAggregate(response.data.inCompleteAggregate);
          setAlltimeAggregate(response.data.alltimeAggregate);

   });
 }


       try{

        //console.log(console.log(window.daabia_storeid))
       
        if (window.daabia_storeid!==undefined){
           
           if (stores.length > 0){
            if (!is_ProductsLoaded){handleget_Products()} 
            if (!ismonthlySalesLoaded){ handlegetMonthAnalytics(stores[storeindex]._id);}
          if (!analyticsLoaded){ handlegetAnalytics(stores[storeindex]._id);}
           if(!isSalesLoaded){ 
             handlegetSales(stores)
            }

             
   
           if (!isProductCategoriesLoaded){
              var category=stores[storeindex].category

              }           
              
 /**/
           }
        }
       }catch(err){
      console.log(err)
    }
return ()=>{
    if(user!==undefined){
      setIStoreEngineInit(true)

     if (transactions.length >0){
        setIsanalyticsLoaded(true);
     }
      setIsmonthlySalesLoaded(true);
      setIsproductsLoaded(true);
      if (productxs!==undefined){
        //set_IsproductsLoaded(true);
      }
      setIsStoreLoaded(true);
      setIsProdCatsLoaded(true);
    }
}   

},[is_ProductsLoaded, analyticsLoaded, handlegetStores, isProductCategoriesLoaded, isSalesLoaded, isStoreEngineInit, isStoresLoaded, ismonthlySalesLoaded, isproductsLoaded, productxs, storeindex, stores, transactions.length, useStoreId, user, userObj]);

useEffect(()=>{
  
})
 
  return (
    <Router >
    <Route exact path={paths}>
         <Appbar devicetype={devicetype} handleMenuOnClick={handleMenuOnClick} />
    </Route>
     <div className="content">
      
     { showprogress ?  <div className='dash-circularProgress-wrapper'><CircularProgress  variant='indeterminate' color ='secondary' size={25} /></div>:''}
    <div>
       {showSidebar &&
       <Route exact path={paths}>
        
        {devicetype=== 'mobile' ? <MobileSlider showMobileSidebar={showMobileSidebar} stores={filteredstores} storeindex={storeindex} handleOnStoreChange={handleOnStoreChange}      setShowMobileSideBar={setShowMobileSideBar} /> : <><Sidebar stores={filteredstores} storeindex={storeindex} handleOnStoreChange={handleOnStoreChange}/>{/* {filteredstores.length>0 ?:'your store not verified'} */}</>
         }
       </Route>
        } 
     
    </div>
   
      <Route exact path={paths}>
        <div className="toggleSidebarButton" onClick={toggleSideBar}></div>
      </Route>
     
    
     <Switch>
     <Route exact  path="/dashboard">
         {
          /* load user and */ 
          <Home user={user} storeindex={storeindex} stores={stores} transactions={transactions} setStores={setStores} handlegetStores={handlegetStores} handlegetProducts={handlegetProducts} isStoresLoaded={isStoresLoaded}setIsStoreLoaded={setIsStoreLoaded} completedAggregate={completedAggregate} inCompletedAggregate={inCompletedAggregate} alltimeAggregate={alltimeAggregate} monthlySales={monthlySales} productxs={products} />
          }
       </Route>
       <Route path="/dashboard/users">
        <UserList/>
       </Route>
       <Route path="/dashboard/user/">
        {user !==undefined ? <User user={user} setUser={setUser}/>:''}
       </Route>
       <Route path="/dashboard/newUser/">
        <NewUser/>
       </Route>
       <Route path="/dashboard/store">
         <Store setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}/>
         </Route>

       <Route path="/dashboard/stores">
       {stores.length >0 ? <StoreList  stores={stores} setStores={setStores}  onDeleteStore={handleDeleteStore} handleOnStoreSelected={handleOnStoreSelected}/>:''}
       </Route>
       <Route path="/dashboard/store/:storeId">
       
       </Route>

       <Route path="/dashboard/newStore/">
        <NewStore stores={stores} store={stores[storeindex]} setStores={setStores} handleSubmitStore={handleSubmitStore} countries={countries} states={states} cities={cities}/>
       </Route>

       <Route path="/dashboard/products">
        {
          stores.length >0 ? <ProductsList products={products} handlegetProducts={handlegetProducts} handleDeleteProduct={handleDeleteProduct} isproductsLoaded={isproductsLoaded}setIsproductsLoaded={setIsproductsLoaded} store={stores[storeindex]}/>:''
        }
       </Route>
       <Route path="/dashboard/product">
       {
        stores.length > 0 ? <Product  store={stores[storeindex]} setShowProgress={setShowProgress} showprogress={showprogress}setOpenSnackBar={setOpenSnackBar} setMessage={setMessage}authenticateToken={authenticateToken} accessToken={accessToken}/>:''
       }
       </Route>

       <Route path="/dashboard/newProduct">
       {
        stores.length > 0 && productCategories.length>0 ? <NewProduct store={stores[storeindex]} productCategories={productCategories} onFormSubmit={onFormSubmit} setOpenSnackBar={setOpenSnackBar} setMessage={setMessage} accessToken={accessToken}/>:''
       }
       </Route>

       <Route path="/dashboard/transactions">
       {
        stores.length >0 ? <Transactions store={stores[storeindex]}  transactions={transactions} isTransLoaded={isTransLoaded} setIstransLoaded={setIstransLoaded} handlegetTransactions= {handlegetTransactions} handlegetStores={handlegetStores} handleUpdateManyTransactions={handleUpdateManyTransactions} handleUpdateTransaction={handleUpdateTransaction} handleShowAllTransactions={handleShowAllTransactions} showAlltransactions={showAlltransactions} switchText={switchText} setShowProgress={setShowProgress} showprogress={showprogress} /> : ''
       }
       </Route>
       <Route path="/dashboard/sales">
       {
        stores.length > 0 ?  <Sales store={stores[storeindex]} sales={sales} setSales={setSales} stores={stores} />:''
       }
       </Route>
       <Route path="/dashboard/login" >
         <LogIn toggleSideBar={handletoggleSideBar}/>
       </Route>
       <Route path="/dashboard/signup">
         <SignUp  toggleSideBar={handletoggleSideBar} />
       </Route>
       <Route path="/dashboard/email/confirm">
         <Confirm/>
       </Route>
     </Switch>
    
    </div>
    </Router>
  )
}

export default Dashboard
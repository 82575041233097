/* eslint-disable no-unused-vars */
import React,{useState} from 'react'
import {Typography,Button,Card,CardActions,CardContent,CardMedia,MenuItem,TextField} from '@mui/material';
import useStyles from './styles';
import {useForm} from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
//import Measurement from './Measurement';
import { pink,orange } from '@mui/material/colors';
import {formarttoPiCurrency,convertValueFromExponent,truncateString} from '../../../utils/Utils';
import { myCld } from '../../../cloudinaryImage'

const CartItem = ({cartitem,onUpdateCartQty,onUpdateColorSize,onUpdateMeasurement,onRemoveFromCart,onUpdateSelect}) => {
    const classes = useStyles();
    const[color,setColor]=useState(cartitem.color);
    const[size,setSize]=useState(cartitem.size);
    const[measurement]=useState(cartitem.measurement)
    //const [sleeve,setSleeve]=useState(measurement.sleeve);
    const[open,setOpen]=useState(true);
    const[colorSelectedList,setColorSelectedList]=useState([]);
    const [checked, setChecked] = useState(false);
    const [imagepath]=useState(myCld.image(`${cartitem.product.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL())
   
    //const [imagepath]=useState( `${cartitem.product.image[0].secure_url}`)
    const label = { inputProps: { 'aria-label': 'Select item' } };
   const [inputVal,setInputVal]=useState()

    /* const sleeves = [
      {
        value: 'short',
        label: 'Short',
      },
      {
        value: 'long',
        label: 'Long',
      },
    ]; */
    const handleClick=()=>{
      setOpen(!open)
    }
    const handleQuantityContentChange = (e,_id,price,shippingFees,stock)=>{
      setInputVal(e.target.value)
       var quantity =parseInt(e.target.value);
      // console.log(e.target.value+' t '+ parseInt(e.target.value))
      if (quantity >= 1){
       onUpdateCartQty(_id,quantity,price,shippingFees,stock)

      }
    }
   
    // eslint-disable-next-line no-unused-vars
    const {register,getValues,formState: { errors },} = useForm();
    
   /*  const onMeasurementValueChange = () => {
        onUpdateMeasurement(cartitem.product._id,JSON.stringify(getValues()))
    }
    
    const onSleeveChange = (event) => {
      setSleeve(event.target.value);    
      //console.log("form data "+JSON.stringify(getValues()))
      onUpdateMeasurement(cartitem.product._id,JSON.stringify(getValues()))
  } */
     const onCheckBoxChange =(e,cartitem)=>{
      setChecked(e.target.checked); 
      console.log(e.target.checked)
      onUpdateSelect(cartitem.product._id,e.target.checked)
     }
     const onGridColorItemClick=(item)=>{
         setColor(item)
         setColorSelectedList(selected=>[...selected,item])
          console.log("color selected "+colorSelectedList)
         //console.log(item)
     }
     const onGridSizeItemClick=(item)=>{
      setSize(item)
      
      console.log(item)
      
 }
   
    const ColorGridList= ({list,onUpdateColorSize}) =>(
          <>
           <Grid container justifyContent='center' alignItems='center' className={classes.specsListWraper} >
          <Grid item xs={3} sm={3} md={3} lg={3}>
          <Typography variant="body2">{`Select Color`}</Typography>
           </Grid>
           <Grid item xs={9} sm={9} md={9} lg={9}>
          <div className={classes.gridSpecsList} >                        
               <div className={classes.specsGrid} > 
                {list.map((item,index)=>(
                 item!=='' ? <div key={index} className={`${classes.gridSpecsItem} ${color===item ? classes.select:classes.disSelect}`} onClick={()=>{onUpdateColorSize(cartitem.product._id,'color',item);onGridColorItemClick(item)}}>{item}</div>:''
               
                ))}
                </div>
                </div>
            </Grid>
        </Grid>
  </>
    )

    const SizeGridList= ({list ,onUpdateColorSize}) =>(
      <>
     <Grid container justifyContent='center' alignItems='center'  className={classes.specsListWraper} >
      <Grid item xs={3} sm={3} md={3} lg={3}>
     <Typography variant="body2">{`Select Size`}</Typography>

      </Grid>

      <Grid item xs={9} sm={9} md={9} lg={9}>
         <div className={classes.gridSpecsList} >                        
           <div className={classes.specsGrid} > 
            {list.map((item,index)=>(
             item!==''? <div key={index} className={`${classes.gridSpecsItem} ${size===item ? classes.select:classes.disSelect}`} onClick={()=>{onUpdateColorSize(cartitem.product._id,'size',item);onGridSizeItemClick(item)}}>{item}</div>: ''
            ))}
            </div>
           </div>
      </Grid>


     
      </Grid>
    </>)
     React.useEffect(()=>{  
      setInputVal(cartitem.quantity)

       setTimeout(() => {
        //console.log(`... switching checked to ${cartitem.selected}`)
        setChecked(cartitem.selected)
       }, 2000);
     },[cartitem])
     
  return (

        <Card className={classes.root}>
          <Grid container justifyContent="space-between" spacing={0}>
              <Grid item xs={3} sm={3} md={3} lg={3}>
              <CardMedia  image={imagepath} alt={cartitem.product.name} className={classes.media}/>
              </Grid>
             
              <Grid item xs={9} sm={9} md={9} lg={9}  >
 
              <CardActions className={classes.cardActions}>
             <Grid container justifyContent='flex-start'>
                <Grid item>
                <Typography style={{fontSize:'1.1rem'}} className={classes.productname}>{truncateString(cartitem.product.name,30)}</Typography>
                </Grid>
             </Grid>
 
              <div className={classes.buttons}>
                <div className={classes.quantityUpdateWrapper}>
                <Button type="button" size="small" onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity-1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>-</Button>
                <input onChange={(e)=>{handleQuantityContentChange(e,cartitem.product._id,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}} value={inputVal} className={classes.quantity} />
                <Button type="button" size="small"  color="secondary" onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity+1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>+</Button>
                </div>
          
          <Checkbox {...label} sx={{color: "#6d1b7b",
          '&.Mui-checked': {
             color:"#6d1b7b",
          },
        }} checked ={checked} defaultChecked={false} onChange={(e)=>{onCheckBoxChange(e,cartitem)}} /> 
              </div>
              <Grid container justifyContent='space-between' flexDirection='column'>
                <Grid item xs={9} >
                
                <Grid container  justifyContent='space-between'>
                  <Grid item xs={12}>
                  <Grid container>
                      <Grid item xs={4}>
                      <Typography variant="body2">{`price:`}</Typography> 
                        </Grid>
                        <Grid item xs={8}>
                        <Typography variant="body2" className={classes.costTypo} >{`${formarttoPiCurrency(cartitem.line_item_sub_price)}`}</Typography> 
                       </Grid>
                    
                  </Grid>  
                  </Grid>

                  <Grid item  xs={12} >
                  <Grid container>
                      <Grid item xs={4}>
                      <Typography variant="body2" >{`shipping:`}</Typography>
               
                        </Grid>
                        <Grid item  xs={8}>
                      { cartitem.line_item_sub_fees > 0.0000000001 ?  <Typography variant="body2" className={classes.costTypo}>{`${formarttoPiCurrency(cartitem.line_item_sub_fees)}`}</Typography>:'free'
               }
                       </Grid>
                    
                  </Grid>  
                  </Grid>

                  </Grid>  
          
            
          
                </Grid>
                <Grid item xs={3} >
                <Button type="button" size='small'   variant="text" color="primary" onClick={()=>{onRemoveFromCart(cartitem.product._id)}}>Remove</Button>
                </Grid>
              </Grid>
            </CardActions>
            
              </Grid>
          </Grid>
          <Grid>
          <CardActions className={classes.cardActions}>
             <div className={classes.specifications}>
             {/*  <ListItemButton onClick={handleClick}>
              <Typography variant="body2">Measurement</Typography> {open ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
            

               <Collapse in={open} timeout="auto" unmountOnExit>
               <Measurement productid={cartitem.product._id} onMeasurementValueChange={onMeasurementValueChange} measurement={measurement}register={register} onSleeveChange={onSleeveChange} sleeve={sleeve} sleeves={sleeves}/>
               </Collapse> */}
              {
                cartitem.product.size.length>0 ?  <SizeGridList type={"size"} onUpdateColorSize={onUpdateColorSize} list={cartitem.product.size}/>:''
              }
              {cartitem.product.color.length>0 ?  <ColorGridList type={"color"} onUpdateColorSize={onUpdateColorSize} list={cartitem.product.color}/>:''}
            
             </div>
            </CardActions>
          </Grid>
        
           
        </Card>
      
   
  )
}

export default CartItem

import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        maxWidth:'100%',
        margin:'0px 20px 20px 0px',
        
    },
    container:{
        [theme.breakpoints.up(620)]:{
            display:'block',
           },
           [theme.breakpoints.down(620)]:{
            display:'none',
           }
    },
    mediaThumbnail:{
        height:100,
        width:100,
       
    },
    disSelect:{
        border:'0px solid',
       
      }
     ,select:{
        border:'2px solid var(--app-primary)',
      },
    previewContainer:{
        margin:'0px 10px'
    },
    mediaPreview:{
        height:240,
        maxWidth:"100%",
        border:'0px solid',
        objectFit:'contain'
        
    },
    viewcontainer:{
        border:'0px solid',
        marginLeft:'0%',
       
    }
    ,
}))
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {Avatar, IconButton,Icon, ListItemButton, ListItemText, Divider, List, ListItem, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Logo from '../../assets/logo/Daabia-2.png'
import {NavLink} from 'react-router-dom'
import { myCld } from '../../cloudinaryImage';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function InforAppbar() {
  const navItems = [{title:'Home',link:"/"}, {title:'About',link:'/about'},{title: 'Contact',link:'/contact'}];
  const Topmenu = ()=> {
    return(
    <Box  sx={{ textAlign: 'left' }}>
     
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText sx={{textTransform:'none'}} primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
     
  )};
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static">
        <Toolbar>
          
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
           <IconButton  sx={{ p: 0 }}>
           <a style={{textDecoration
  :'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}><Avatar sx={{borderRadius:0,width:'60px'}} alt="Daabia" src={Logo/* myCld.image(`logo/ya1sso8xq8caogxlgyop
`).format('auto').quality('auto').addFlag('lossy').toURL() */} /></a>
                
              </IconButton>
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
          
            <a style={{textDecoration
  :'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>Pi Payment</a>
          
          </Typography>
          
         {/*  <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
           */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      {navItems.map((item) => (
        <NavLink to={item.link}>
            <Button key={item.title} sx={{ color: '#fff',textTransform:'none'}}>
          {item.title}
        </Button>
        </NavLink>
       
      ))}
    </Box>

        </Toolbar>
      </AppBar>
    </Box>
  );
}
import React from 'react'
import {Grid,Typography} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'

const CheckOutNavigation = ({title}) => {

    const history =useHistory()

  return (
    <div>
       <Grid container justifyContent='flex-start' alignItems='center' spacing={1} marginBottom={1} border={0}  >
         <Grid item  onClick={()=>{history.goBack()}}>
                       <ArrowBackIcon htmlColor='darkgray'/>
                    </Grid>
                    <Grid item >
       <Typography variant="5" color='GrayText'>{title}  </Typography> 
 </Grid>
              </Grid>
    </div>
  )
}

export default CheckOutNavigation

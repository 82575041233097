import React,{useState,useEffect} from 'react';
import QueryParams from '../../QueryParams'
import {axiosClient,config} from '../../services/axiosClient';
import {Grid,Typography,CircularProgress} from '@mui/material'
import {useHistory} from 'react-router-dom'
const Confirm = ({setEmailVerified}) => {
    const query =QueryParams();
    const history=useHistory();
    const[confirming,setConfirming]=useState(true);
    const[message,setMessage]=useState('')
    const[isUserconfirmed,setIsuserConfirmed]=useState(false)

     const [id] =useState(query.get('_id'))

      const Confirming = ()=>{
      return(
        <Grid  container justifyContent={'space-between'}>
          <Grid item  >
            <Typography>Confirming please wait ... </Typography>
          </Grid>
          <Grid item  >
          
          <CircularProgress  variant='indeterminate' color ='primary' size={12} />
        
          </Grid>
        </Grid>
      )
    }
    const Complete = ({message})=>{
      return(
        <Grid  container justifyContent={'space-between'}>
          <Grid item  >
            <Typography>{message}</Typography>
          </Grid>
          <Grid item  >
           <Grid container justifyContent={'flex-start'} >
           <Typography style={{cursor:'pointer'}} onClick={()=>{history.push('/account')}} color={'primary'}>Continue</Typography>
           </Grid>
          </Grid>
         
        </Grid>
      )
    }
    useEffect(()=>{
       
         const confirmedUser = ()=>{
          axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/email/confirm/${id}`,config).then((res)=>{
            
            const userObj=res.data.user
            console.log(userObj)
          if (userObj.confirmed){
            setMessage(res.data.msg);
            setConfirming(false)
            setEmailVerified(true)
            localStorage.setItem('confirmed',true)
          }
          })
         }
         if (!isUserconfirmed){
          confirmedUser()
         }
     
        return () => {
          setIsuserConfirmed(true)
        }
    },[id, isUserconfirmed, setEmailVerified])

   
  return (
    <div className='confirm'style={{padding:'10px 20px'}}>
      
        <Typography variant='h5'>{process.env.REACT_APP_WEBSITE_NAME} Email Verification</Typography> <br/>
      {
           confirming ? <Confirming/> :
           <Complete message={message}/>
      }
    </div>
  )
}

export default Confirm
 
import * as React from 'react';
import Box from '@mui/material/Box';
import {Button,Grid}  from '@mui/material';
import Privacy        from '../../privacy/Privacy';
import TermsOfService from '../../privacy/TermsOfService';
import Typography     from '@mui/material/Typography';
import Modal          from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  height:'75vh',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'snow',
  color:'#000',
  border: '2px solid var(--app-primary)',
/*   borderTopRightRadius: 12, 
 */  borderRadius: 4, 
  boxShadow: 24,
  p: 2,
};

export default function Policyterms({open,setOpen,handleOpen,acceptpolicy, termsofService,privacyAction}) {
 

  return (
    <div>
      <Modal 
        open={open}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item> <Typography id="modal-modal-title" variant="body2" >
            Privacy Policy and Terms of Service
          </Typography></Grid>
         {/*  <Grid item onClick={()=>handleClose()}>X</Grid> */}
         </Grid>

        {
      <Grid container justifyContent='space-between'   >
         
         {  !acceptpolicy ?     
          <Grid item xs={12} marginTop={2}  sx={{height:'65vh',overflow:'scroll',border:'0px solid'}}>
                <Grid container justifyContent={'center'} alignItem='center' direction={'column'} spacing={0.5} >
                    <Grid item xs={12} >
                      <Privacy/>
                    </Grid>
                    <Grid item xs={12} >
                    <p>By clicking on agree you accepts the policy and stated above</p>
                    </Grid>
                    <Grid item  xs={12} marginBottom={4}>
                      <Grid container justifyContent={'space-around'} direction='row' alignItems={'center'}   >
                        <Grid item >
                        <Button variant='outlined' size='medium' onClick={()=>{privacyAction('cancel',null)}}>Cancel</Button>
                        </Grid>
                        <Grid item>
                        <Button variant='contained'  size='medium' onClick={()=>{privacyAction('agreed','privacy')}}>Agree</Button>

                        </Grid>
                      </Grid>
                        
                    </Grid>
                   
                </Grid>
          </Grid> :''}

          {acceptpolicy && !termsofService ? <Grid item xs={12} marginTop={2}  sx={{height:'65vh',overflow:'scroll',border:'0px solid'}}>
                <Grid container justifyContent={'center'} alignItem='center' direction={'column'} spacing={0.5} >
                    <Grid item xs={12} >
                      <TermsOfService/>
                    </Grid>
                    <Grid item xs={12} >
                    <p>By clicking on agree you accepts the terms of service and stated above</p>
                    </Grid>
                    <Grid item  xs={12} marginBottom={4}>
                      <Grid container justifyContent={'space-around'} direction='row' alignItems={'center'}   >
                        <Grid item >
                        <Button variant='outlined' size='medium' onClick={()=>{privacyAction('cancel',null)}}>Cancel</Button>
                        </Grid>
                        <Grid item>
                        <Button variant='contained'  size='medium' onClick={()=>{privacyAction('agreed','terms')}}>Agree</Button>

                        </Grid>
                      </Grid>
                        
                    </Grid>
                   
                </Grid>
          </Grid> :''}
          {/* <Grid item xs={12} marginTop={2} >
                <Grid container justifyContent={'center'} alignItem='center' spacing={0.5} >
                    <Grid item xs={3} >
                    </Grid>
                    <Grid item  xs={6} >
                      <Grid container justifyContent={'space-around'} direction='row' alignItems={'center'}   >
                        <Grid item >
                        </Grid>
                        <Grid item>
                         <Typography id="modal-reward-title" variant="h6" component="h2" >2X</Typography>
                        </Grid>
                      </Grid>
                        
                    </Grid>
                    <Grid item  xs={3} >
                       <Button variant='contained'>AGree</Button>
                    </Grid>
                    
                </Grid>
          </Grid> */}
       </Grid>
        }
        
        </Box>
      </Modal>
    </div>
  );
}
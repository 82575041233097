import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding:1,
    [theme.breakpoints.down(620)]:{
      padding:1,
    },
  },
 
 
}));

/* eslint-disable no-unused-vars */
import { Card,Typography,Button,CardContent,Grid} from '@mui/material'
import { AddShoppingCart } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';
import {Link } from 'react-router-dom';
import { formarttoPiCurrency, truncateString } from '../../../utils/Utils';
/* 
import { EditorState , convertFromRaw} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import BasicRating from '../../rating/BasicRating' */

const ProductDetails = ({product,store,onAddToCart}) => {
    const classes =useStyles();
  return (
    <div className={classes.root} >
      {
        product.name!==undefined ?  <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
         <div className={classes.contentSub}>
       <Grid container justifyContent='space-between'>
            <Grid item >
           <Typography variant="h6" sx={{fontSize:'1rem'}}  className={classes.price}>
              {formarttoPiCurrency(product.price)}
          </Typography>
           </Grid>
            <Grid item >
            <Typography variant="span"  sx={{fontSize:'0.9rem'}} className={classes.stock}>
              {`stock ${product.stock.currentstock >=1 ? product.stock.currentstock :0} `}
          </Typography>
            </Grid>
       </Grid>
         
         <Typography variant="body1" color='darkgray'>
              {product.name}
          </Typography>
         
          <div  style={{fontSize:'0.8rem'}}  dangerouslySetInnerHTML={{ __html: product.description }} />
{/*           <BasicRating title={'Product Rate'} ratedValue={0}/>
 */}         </div>
       <Typography style={{fontSize:'0.8rem',marginTop:'5px'}}>
       {product.shippingarea ? product.shippingarea :''}
       </Typography>
         <Grid container justifyContent={'flex-end'} marginTop={1}>
          <Grid item>
   {/* <Button to="/" className='client-action-Button' component={Link} variant='outlined'>Products</Button> */}
          </Grid>
          <Grid item>
          
           <Button className='client-action-Button' /* 'client-buynow-Button' */ type="submit" variant="contained" color="primary" onClick={()=>{onAddToCart(product,0,"buynow")}}>Buy Now{/* Add to  Cart <AddShoppingCart/>  */} 
          </Button>
          </Grid>
         </Grid>
        </CardContent>

       </Card>:''
      }
    </div>
  )
}

export default ProductDetails

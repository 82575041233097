import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
   root:{
       margin:'80px 5px',
       [theme.breakpoints.down('xs')]:{
        margin:'20px 5px',
       }
   },
   storedetails:{
    display:'none',
    [theme.breakpoints.down('xs')]:{
        display: 'block',
      },
   },
   editbar:{
    display:'none',
    [theme.breakpoints.down('xs')]:{
        display: 'block',
      },
   },
   userUpdateWrapper:{
    padding: '2px !important',
    [theme.breakpoints.down('xs')]:{
        padding: '20px !important',
        marginBottom:30,
    },
   storetapIcon:{
    color:'var(--app-primary)'
   },
   rewardCard:{
    border:'1px solid',
    padding:0,
    backgroundImage:'linear-gradient(to bottom right, var(--app-primary), snow)'

   }
}
}))
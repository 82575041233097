import React from 'react';
import {Button,Grid,TextField,Box,FormLabel} from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
/* import DialogContentText from '@material-ui/core/DialogContentText'; */
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles'
function AlertDialog({dialogRef,open,handleClickOpen,handleClose,title,textContent,Icon,positiveButtonText,negativeButtonText,handleSubmit,walletAddress,setWalletAddress,setWalletAddress1}) {
      const classes =useStyles()
  return (     
      <Dialog
         className={classes.root}
         ref={dialogRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{border:'0px solid'}}>
        <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 0, width: '33ch',border:'0px solid' },
      }}
      /* noValidate */
      autoComplete="off"
      onSubmit={(e)=>{handleSubmit(e)}}
    ><div>
<Grid container justifyContent='space-between' spacing={2} style={{width:'100%'}}>
            <Grid item  >
            
           
              <Grid container justifyContent='space-between' direction='column' >
              <Grid item>
             <FormLabel>Wallet Address</FormLabel>
              </Grid>
              <Grid item>
          <TextField
          id='textfield0'
          value={walletAddress}
          placeholder="GDMNTFAZQXI7FO5NFFRR5AK6ETGYPXOJ33BYVNNINQTE5W1LSKO5E432Y"
          multiline
          rows={4}
          onChange={(e)=>{setWalletAddress(e.target.value)}}
          variant="standard"
          required
        />
              </Grid>
              </Grid>
            </Grid>
            <Grid item >
            <Grid container justifyContent='space-between' direction='column' >
                 <Grid item>
                 <FormLabel>Repeat</FormLabel>

                 </Grid>
                 <Grid item >
     <TextField
          id='textfield1'
          placeholder="GDMNTFAZQXI7FO5NFFRR5AK6ETGYPXOJ33BYVNNINQTE5W1LSKO5E432Y"
          multiline
          rows={4}
          onChange={(e)=>{setWalletAddress1(e.target.value)}}
          variant="standard"
          fullWidth
          required
        />
                 </Grid>
             </Grid>
           
            </Grid>
            <Grid item>
              <Button type='submit' color="primary">Update</Button>
            </Grid>
          </Grid>

    </div>
          
       </Box>   
             
        </DialogContent>
       
        <DialogActions>
          <Button onClick={()=>{handleClose(false)}} color="primary">
            {negativeButtonText!==null ? negativeButtonText :'' }
          </Button>
         {/*  <Button onClick={()=>{handleClose(true)}} color="primary" autoFocus>
            {positiveButtonText !==null ? positiveButtonText :''}
          </Button> */}
        </DialogActions>
      </Dialog>
    
  )
}

export default AlertDialog

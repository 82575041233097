import React, { useState } from 'react'
import {Box,Collapse,List,ListItem,ListItemButton,ListItemIcon, ListItemText} from '@mui/material'
import { ExpandMore,ExpandLess} from '@mui/icons-material';
import useStyles from './styles'
import './index.css'
const NestedList = ({showCategory,setShowCategory,groupCategories,categories,handlesearchByCategoryGroups,handlesearchByCategory}) => {
    const classes =useStyles()
    const OuterList =({group,categories})=>{
        const[open,setOpen]=useState(false)
        return <>
        <List style={{padding:0,margin:0}} key={`list-${group._id}`}>
    <ListItem style={{padding:0,margin:0}} key={`list-item-${group._id}`}> 
        <ListItemButton style={{padding:0,margin:0}} >
            <ListItemIcon onClick={()=>{setOpen(!open)}} >{open ? <ExpandLess className={classes.expand}/> : <ExpandMore className={classes.expand}/>}</ListItemIcon>
            <ListItemText style={{padding:0,margin:0}} primary={group.name} onClick={()=>{handlesearchByCategoryGroups(group._id);setShowCategory(!showCategory)}}/>
        </ListItemButton>
    </ListItem>
  </List>
  <Collapse in={open}>
    <List sx={{marginLeft:'15%',padding:0}}>
       { categories.filter((c) => c.categoryGroupId ===group._id).map((category,index)=>{
       return <ListItem style={{padding:0,margin:0}} divider>
            <ListItemButton style={{padding:0,margin:0}} onClick={()=>{setOpen(false);handlesearchByCategory(category._id);setShowCategory(!showCategory)}}>
              
                <ListItemText style={{padding:0,margin:0,fontSize:9}} primary={category.name}/>
            </ListItemButton>
        </ListItem>
        })}
    </List>
  </Collapse>
      </>
    }

  return (
    <Box  className={`${classes.category} ${ showCategory ? classes.categoryShow : classes.categoryHide} `} style={{width:'80%',maxHeight:'90vh',minHeight:'80vh',overflow:'scroll' ,padding:'5px',backgroundColor:'snow'
    ,/* border:'1px solid var(--app-primary)', */position:'absolute',top:'8vh',zIndex:999999}}>
      {
         groupCategories.map((group,index)=>{
       return <OuterList key={group._id} group={group} categories={categories}/>
        })
      }
    </Box>
  )
}

export default NestedList

/* 
const NestedList = () => {
    const[open,setOpen]=useState(false)
    const array=["first","second","third","forth","fifth"]
  return (
    <Box>
      <List>
        <ListItem divider>
            <ListItemButton onClick={()=>{setOpen(true)}} >
                <ListItemIcon>{">"}</ListItemIcon>
                <ListItemText primary={'Expand List'}/>
            </ListItemButton>
        </ListItem>
      </List>
      <Collapse in={open}>
        <List sx={{marginLeft:25}}>
           { array.map((item)=>{
           return <ListItem divider>
                <ListItemButton onClick={()=>{setOpen(false)}}>
                    <ListItemText primary={item}/>
                </ListItemButton>
            </ListItem>
            })}
        </List>
      </Collapse>
    </Box>
  )
}

export default NestedList
 */
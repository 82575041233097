import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    width:'100%',
    [theme.breakpoints.up(820)]: {
      display:'none !important'
    }
  },
  navigationAction:{
    color: "darkgray",
    "&.Mui-selected": {
      color: "#6d1b7b !important"
    },
    "&.MuiBottomNavigationAction-root":{
      minWidth:'60px !important'
     },
  },
  navigationAcIcon:{
    transform:'scale(0.9)',
    boxShadow:'inherit'
  },
  navBadge:{ "& .MuiBadge-badge": { 
    fontSize: 11,marginTop:'4px'
  } },
   
  addIcon:{
      width: 65,
      height: 65,
  },
     /* Styles applied to the root element if selected. */
  selected: {},
 
}));

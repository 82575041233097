/* eslint-disable no-unused-vars */
import React ,{useState} from 'react'
import {Card, CardMedia,Button, Typography ,Grid} from '@mui/material';
import useStyles from './styles';
import './styles.css';
import {formarttoCurrency} from '../../../utils/Utils'
import { myCld } from '../../../cloudinaryImage'

const OrderItem = ({order,handlegetStore}) => {
  const url = order.productImageUrl
const array = url.split('/');

const lastsegment = array[array.length-1];
const pid=lastsegment.substring(0, lastsegment.length - 4)
    const classes=useStyles();
    const FreeTag= ()=>(
      <Grid container justifyContent={'flex-start'} spacing={1}>
        {/* <Grid item ><Typography variant="body2"  fontSize={11}>
    
</Typography></Grid> */}
        <Grid item ><Typography variant="body2"  fontSize={10} className={classes.freeTag}>
    free
</Typography></Grid>
      </Grid>
   )

  return (
    <div> 
      <Card className={classes.root} onClick={()=>{handlegetStore(order)}}>
      <Grid container justifyContent={'space-between'} spacing={1}>
                        <Grid item xs={3} sm={3} md={3} lg={3}>

                        <img alt='order-img' className={classes.orderItem_media} /* src={`${order.productImageUrl}`} */ src={myCld.image(`products/${pid}`).format('auto').quality('auto').addFlag('lossy').toURL()}/>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} padding={1}  >
                        <Grid container justifyContent='space-between' flexdirection={'row'} >
                            
                            <Grid item  xs={9} sm={9} md={9} lg={9} >
                                <Grid container  flexdirection={'column'} justifyContent='space-between'>
                                <Grid item xs={12}>
                                 <Typography variant='body1'>{order.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container justifyContent='space-between' >
                                    <Grid item xs={5}>
                                    <Typography variant='body2'  className={classes.order_label}>
                                 Price :
                                 </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                    <Typography variant='body2'  className={classes.price}>
                                {`${formarttoCurrency(order.priceEach,'π')}`}
                                 </Typography>
                                    </Grid>
                                  </Grid>
                                
                                
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container  justifyContent={'space-between'}>
                                   <Grid item xs={5}>
                                  <Typography variant="body2"  className={classes.order_label} >  Shipping : </Typography>
                                   </Grid>
                                   <Grid item xs={7}>
                                   { order.shippingFees > 0.000000001 ? <Typography variant="body2" className={classes.shipping}>
                        {`${formarttoCurrency(order.shippingFees,'π')}`} 
                      </Typography>:<FreeTag/>}

                                   </Grid>
                                  </Grid>
                                  
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container >
                                    <Grid item >
                                    <Typography variant="body2" className={classes.datecreated}>
                          {`${new Date(order.dateCreated).toDateString()}`}
                     </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
 
                            <Grid item xs={3} sm={3} md={3} lg={3} flexdirection={'column'} alignItems={'flex-start'}  >
                            <Grid >
                                <Typography color='primary'>  {` x ${order.quantity}`}</Typography>
                                </Grid>
                                <Grid >
                               {/*  <Typography variant='body2' className={order.status.title}>{order.status.title}</Typography>  */}
                                <Button className='client-Order-Status' variant='outlined' color={`${order.status.title}`}>{order.status.title}</Button>
                                {/* { order.status.title==='Recieved'? <Typography variant='body2' className={classes.feedback} onClick={()=>{onReviewClick(order)}}>{order.status.title}</Typography>:''} */}
                            
                                </Grid>
                            </Grid>
                         </Grid>
                        </Grid>
                     </Grid>
                  </Card>
     {/*  <Card className={classes.root} onClick={()=>{handlegetStore(order)}}>
           <CardMedia className={classes.media} image={imagepath}> </CardMedia>
    

                <CardContent className={classes.cardContent}>
                     <div className={classes.title}>
                     <Typography noWrap={true} variant="h6" >
                            {order.name}
                        </Typography>
                     </div>
                   <div className={classes.cardContentSub}>
                   <div className={classes.contentSubdetailsWrapper}>
                   <div className={classes.orderDetails}>
                        <Typography variant="body1">
                            {`${formarttoCurrency(order.priceEach,'π')}`}
                        </Typography>
                      <Typography variant="body2">
                        Quantity :{order.quantity}  
                      </Typography>
                    { order.shippingFees > 0.0000000001 ? <Typography variant="body2">
                        Shipping Fees :{`${formarttoCurrency(order.shippingFees,'π')}`} 
                      </Typography>:<FreeTag/>}
                      <Typography variant="body2">
                       Total :{`${formarttoCurrency(order.totalPrice,'π')}`}  
                      </Typography>
                       <Typography variant="body2">
                          {`${new Date(order.dateCreated).toUTCString()}`}
                     </Typography>
                  </div>
                  <div className={classes.orderDetails2}>
                     {order.color!=='null' ?      <Typography variant="body2" className={classes.color}>
                        {order.color}  
                      </Typography>:''}
                     {order.size!=='null' ? <Typography variant="body2" className={classes.size}>
                        {order.size}  
                      </Typography>:''}
                      <Typography variant="body2">
                      </Typography>
                      <Typography variant="body2" className='orderNumber'>
                        {`#${order.orderNumber}`}  
                      </Typography>

                      <Typography variant="body2" className={order.status.title}>
                        {order.status.title}  
                      </Typography>

                  </div>
                   </div>
                   </div>   
                     { order.status.title==='Completed'? <Typography variant='body2' className={classes.feedback} onClick={()=>{onReviewClick(order)}}>feedback</Typography>:''}

                </CardContent>

           
      </Card> */}
    </div>
  )
}

export default OrderItem

import React from 'react';
import {Button,Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles'
function AlertDialog({dialogRef,open,handleClickOpen,handleClose,title,textContent,Icon,positiveButtonText,negativeButtonText}) {
  console.log()
      const classes =useStyles()
  return (
    <div>
     
      <Dialog
         className={classes.root}
         ref={dialogRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Grid container justifyContent='space-between' alignItems='center'  style={{width:'100%'}}>
            <Grid item  xs={12} border={1}>
             <DialogContentText id="alert-dialog-description">
            {textContent}
          </DialogContentText>
            </Grid>
            {/* {Icon ===undefined ?<Grid item xs={0} >
               <Icon/> 
            </Grid>:<Grid item xs={2} >
               <Icon/> 
            </Grid> } */}
          </Grid>
         
             
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(true)}} color="primary" autoFocus>
            {positiveButtonText !==null ? positiveButtonText :''}
          </Button> <Button onClick={()=>{handleClose(false)}} color="primary">
            {negativeButtonText!==null ? negativeButtonText :'' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog

import React,{useState,useEffect} from 'react'
import {AdsTerra, Products} from '../../components'
import QueryParams from '../../QueryParams';
import {Grid,Typography} from '@mui/material'
import {axiosClient,config} from '../../services/axiosClient'
import store_icon from '../../assets/icons/online_store_96px.png';
import { myCld } from '../../cloudinaryImage';
import AdsTerraPopUnder from '../ads/AdsTerrapopUnder';
import { useParams } from 'react-router-dom';
//import {BasicRating } from '../../components'
const Storefront = ({setShowProgress,onAddToCart,onUpdateLikes,favorites,loadMore,LoadingMore,setLoadingMore,noMoreProducts}) => {
    const imgonLoadError=(e)=>{
        e.target.onerror = null; e.target.src = store_icon
    }
     const query =QueryParams()
     console.log(JSON.stringify(query))
     const[storeid,setStoreId]=useState(query.get('storeId'));
     const [isProductsLoaded,setIsProductsLoaded]=useState(false)
     const [isStoreLoaded,setIsStoreLoaded]=useState(false)
     const [products,setProducts]=useState([])
     const [store,setStore]=useState()
     const [storeImage,setStoreImage]=useState('')
     const [categories,setCategories]=useState([])
     const {tag}  = useParams()
  
     useEffect(()=>{

          const fetchProducts = async ()=>{
            try{
                await axiosClient.get(`${process.env.REACT_APP_SERVER_URL}/products/store/${storeid}`,config).then((res)=>{
                console.log(res)
                setProducts(res.data.products)
               })

            }catch(error){
        
            }
        }

        const getStore = async ()=>{
             fetchStore().then((response) => {
              console.log(response.data.store[0].categories);
               if (response.status===200){
                    setStore(response.data.store[0])
                    setStoreId(response.data.store[0]._id)
                   setCategories(response.data.store[0].categories)
                   console.log(response.data.store[0])
                 try{ 
                   setStoreImage(store[0].image.secure_url)
                 }catch(err){
                   console.log(err)
                 }
               }
               //addToast(exampleToast(response.data.message));
             })
           
         }
           
           
             
  
             const fetchStore = async ()=>{
           
              var url=''
                if (storeid===null){ // id tag exist search store by tag else use storeId at storefront route
                  url = `${process.env.REACT_APP_SERVER_URL}/stores/tag/${tag}`;

                }else{
                  url = `${process.env.REACT_APP_SERVER_URL}/stores/${storeid}`; 
                }
              
              return await axiosClient.get(url,{},config)
            
            };
        
        if (!isProductsLoaded){
            fetchProducts()
        }

        if (!isStoreLoaded){
            getStore()
        }



    return ()=>{
             console.log(storeid)
           
            if (storeid!==null){
               setIsProductsLoaded(true)
               setIsStoreLoaded(true)
            }
    }
     },[isProductsLoaded, isStoreLoaded, storeid, setStoreImage, store, query, tag])
    

  return (
    <div>
        { 
        store ? <Grid container justifyContent='space-between' marginBottom={2}>
          <Grid item xs={12} >
          <Grid container justifyContent='space-between' direction='column'>
            <Grid item >
                <Grid container justifyContent='flex-start' spacing={0.2} alignItems='center'>
                    <Grid item >
                {  store.image ? <img src={myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} /* onError={imgonLoadError} */  alt="" className="productInfoImg"/>: <img src={store_icon} /* onError={imgonLoadError} */  alt="" className="productInfoImg"/>}
                    </Grid>
                    <Grid item >
                    <Typography variant='h6'>{store.name}</Typography>

                    </Grid>
                </Grid>
            </Grid>
            
             <Grid item  xs={12} padding={0.5}>
             {<Typography variant='body1' color='darkgrey'>{store.description}</Typography>}
             </Grid>

             <Grid item >
             <Grid container justifyContent='flex-start'>
             <Grid item >
                 <Typography variant='body1' >Location : </Typography>
                 
              </Grid>
             
            <Grid item > 
            <Typography variant='body1' color='darkgrey'>{`${store.country.name}`}</Typography>
            
            </Grid>
            </Grid>
            
             </Grid>

             <Grid item >
             <Grid container justifyContent='flex-start'>
          
              <Grid item >
             <Grid container justifyContent='flex-start' direction={'column'}>
             <Grid item xs={3}>
                 <Typography variant='body1' >Category</Typography>
                 
              </Grid>
             
            <Grid item xs={9}> 
             <Grid container>
                  {
                    categories.length> 0 ? <>
                    {
                      categories.map((category,index)=>{
                       return ( <Typography key={category._id} variant='body1' color='darkgrey'>{`${category.name},`}</Typography>)
                      })
                    }
                    </>:''
                  }
             </Grid>
            
            </Grid>
            <Grid container justifyContent={'center'}>
              <Grid item >
              <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/>
              </Grid>
           </Grid>
              <Grid item >
                 {/*  <AdsTerraPopUnder /> */}
              </Grid>
            </Grid>
            
             </Grid>
            </Grid>
            
             </Grid>

             {/* <Grid item >
              <Grid container>
             <Grid item> <Typography variant='h6'>Categories :</Typography></Grid>
                           <Grid item>
                           <Grid container>
                           {
                            store.categories.map((category,index)=>{return <Grid item ><Typography variant='body1' >{`${category.name},`}</ Typography></Grid>})
                            }
                           </Grid>
                           </Grid>
              </Grid>

              </Grid> */}
        </Grid>
          </Grid>
        </Grid>
        :''
        }
      {products.length > 0 ? <Products products={products} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes} favorites={favorites} loadMore={()=>{}} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts}/>:''}
    </div>
  )
}

export default Storefront
